import axios from "axios";
import AuthHeader from "./AuthHeader";
const API_URL = process.env.REACT_APP_BASE_URL_API;
const API_URL_AUTH = process.env.REACT_APP_BASE_URL_API + "auth";

const login = async (email, password) => {
  return await axios.post(API_URL_AUTH + "/login", {
    email,
    password
  });
}

const logout = () => {
  axios.post(API_URL_AUTH + "/logout", null, { headers: AuthHeader() });

  localStorage.removeItem("token");
}

const register = (name, email, phone, cif, password, password_confirmation) => {
  return axios.post(API_URL_AUTH + "/register", {
    name,
    email,
    phone,
    cif,
    password,
    password_confirmation
  });
}

const isAuthenticated = () => {
  let token = JSON.parse(localStorage.getItem("token"));
  return token !== null && token !== "";
}

const me = () => {
  return axios.get(API_URL + "me", {
    headers: AuthHeader()
  });
}

const changePassword = (currentPassword, password, confirmPassword) => {
  return axios.post(API_URL_AUTH + "/change-password", {
    currentPassword: currentPassword,
    password: password,
    confirmPassword: confirmPassword
  }, { headers: AuthHeader() });
}

const desactivateAccount = () => {
  return axios.post(API_URL_AUTH + "/desactivate-account", null, { headers: AuthHeader() });
}

const forgotPassword = (email) => {
  return axios.post(API_URL + "password/forgot-password", {
    email: email
  });
}

const resetPassword = (email, token, password, confirmPassword) => {
  return axios.post(API_URL + "password/reset", {
    email: email,
    token: token,
    password: password,
    password_confirmation: confirmPassword
  })
}

export default { login, logout, register, isAuthenticated, me, changePassword, desactivateAccount, resetPassword, forgotPassword }

