import React from 'react'
import './PolicyHero.css'
import { BsFillFileEarmarkTextFill } from "react-icons/bs";

function PolicyHero(props) {
  return (
    <React.Fragment>
      <div className="policyHero">
        <div className="wrapper">
          <h1 className="policyHero__title">{props.title}</h1>
        </div>
        <div className="policyHero__absolute policyHero__absolute--left">
          <BsFillFileEarmarkTextFill />
        </div>
        <div className="policyHero__absolute policyHero__absolute--right">
          <BsFillFileEarmarkTextFill />
        </div>
      </div>
    </React.Fragment>
  )
}

export default PolicyHero