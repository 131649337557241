import React from 'react'
import PolicyHero from './../../components/Texts/PolicyHero'

function CookiesPolicyPage() {
  return (
    <React.Fragment>
      <PolicyHero title="Políticas de cookies"/>
      <section className="section">
        <div className="wrapper">
          <p className="policy__text">Utilizamos cookies propias para el correcto funcionamiento de la página web y de todos sus servicios, y de terceros para analizar el tráfico en nuestra página web. Si continua navegando, consideramos que acepta su uso. Puede cambiar la configuración u obtener más información “INFO”.</p>
          <p className="policy__text">En dicho aviso, deberá aparecer un botón para la aceptación del aviso. “ACEPTO”</p>
          <p className="policy__text">Haciendo clic en la palabra “INFO”, se abre nueva información al usuario, que es la siguiente:</p>
          <h4 className="policy__title">DEFINICIÓN DE COOKIES</h4>
          <p className="policy__text">Una cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.</p>
          <h4 className="policy__title">TIPOS DE COOKIES</h4>
          <h5 className="policy__title">1.- Tipos de cookies, según la entidad que las gestione:</h5>
          <p className="policy__text">a) Cookies Propias: Son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde que se presta el servicio solicitado por el usuario.</p>
          <p className="policy__text">b) Cookies de terceros: Son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos a través de las cookies.</p>
          <h5 className="policy__title">2.- Tipos de cookies, según su finalidad:</h5>
          <p className="policy__text">a) Cookies Técnicas: Son aquellas que permiten al usuario la navegación a través de la página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existen.</p>
          <p className="policy__text">b) Cookies de Análisis: Son aquellas que permiten al responsable de las mismas, el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas.</p>
          <h5 className="policy__title">2.- Tipos de cookies, según su finalidad:</h5>
          <p className="policy__text">3.-Tipos de cookies, según el plazo de tiempo de tiempo que permanecen activadas:</p>
          <p className="policy__text">a) Cookies de Sesión: Son las diseñadas para recabar y almacenar datos mientras el usuario accede a una página web. Se suelen emplear para la prestación del servicio solicitado por el usuario en una sola ocasión.</p>
          <p className="policy__text">b) Cookies Persistentes: Son las diseñadas para que los datos sigan almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios años.</p>
          <p className="policy__text">La web de EMPRESA utiliza estas cookies propias y de terceros, todas en el dominio reyraf.com</p>
          <h4 className="policy__title">BLOQUEO/ELIMINACION DE LAS COOKIES</h4>
          <p className="policy__text">Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador.</p>
          <p className="policy__text"><strong>Chrome</strong>, desde http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647</p>
          <p className="policy__text"><strong>Explorer</strong>, desde http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9</p>
          <p className="policy__text"><strong>Firefox</strong>, desde http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</p>
          <p className="policy__text"><strong>Safari</strong>, desde http://support.apple.com/kb/ph5042</p>
          <h4 className="policy__title">ACCESO A DATOS</h4>
          <p className="policy__text">Las cookies de nuestro sitio web son únicamente utilizadas por:</p>
          <ul>
            <li>El Titular de la página, REY RAF Cinco Coronas, S.L.U.- CIF.: B-04768123 y domicilio social en El Hornillo de los Martínez-Níjar-04116-Almería-España.</li>
            <li>GOOGLE.</li>
          </ul>
        </div>
      </section>
    </React.Fragment>
  )
}

export default CookiesPolicyPage