import React, {useEffect, useState} from 'react'
import OrderService from '../../services/Order/OrderService';
import { useParams, useNavigate } from 'react-router-dom'

export default function PagoCompraPage() {

  const {id, locator} = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    OrderService.pay(null, id, locator).then(
      response => {
        navigate("/compra-satisfactoria/"+ id+"/"+locator, { replace: true })
      }
    );
  }, [])

  return (
    <React.Fragment>
      <span><span className="spinner-border"></span> Procesando pago...</span>
    </React.Fragment>
  );
}