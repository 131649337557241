import React from 'react'
import './Social.css'
import { RiFacebookCircleFill, RiInstagramFill } from 'react-icons/ri'

function Social() {
  return (
    <div className="bannerSocial">
      <div className="bannerSocial__wrapper">
        <div className="bannerSocial__text">
          <p className="bannerSocial__title">Síguenos en redes sociales, mantente informado y comparte tus productos con nosotros</p>
        </div>
        <div className='bannerSocial__containerFigure'>
          <div className="bannerSocial__figure">
            <a href="https://www.facebook.com/reyrafsl/" target="_blank" title="Visite nuestro facebook" rel="noreferrer"><RiFacebookCircleFill/></a>
          </div>
          <div className="bannerSocial__figure">
            <a href="https://www.instagram.com/reyraf5coronas/?hl=es" target="_blank" title="Visite nuestro instagram" rel="noreferrer"><RiInstagramFill/></a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Social