import React, {useState} from 'react'
import './VideoSection.css'
import { FaPlayCircle } from 'react-icons/fa'
//import 'node_modules/react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video'

function VideoSection() {

  const [isOpen, setOpen] = useState(false)

  return (
    <React.Fragment>
      <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="6DUG7wj6g9M" onClose={() => setOpen(false)} />
      <section className="video">
        <div className="video__wrapper">
          <div className="video__link">
            <a href="#video" onClick={() => setOpen(true)}>
              <div className="videoLink__banner">
                <img src="./images/home/cover-video.jpg" alt="Fotografía Andrés Piedra en el programa Aquí La Tierra" className="video__image" />
                <div className="videoBanner__caption">
                  <div className="videoBanner__icon">
                    <span className="videoIcon">
                      <FaPlayCircle />
                    </span>
                  </div>
                  <div className="videoBanner__title">Ver programa Aquí La Tierra</div>
                </div>
              </div>
            </a>
          </div>
          <div className="video__text">
            <div className="videoText__wrapper">
              <figure className="videoText__blockquote">
                <blockquote className="videoBlockquote">“Este tomate necesita sufrir, es decir, estresarse por la cantidad de sal, lo cual hace que produzca por sí mismo ese característico dulzor”</blockquote>
                <blockquote className="videoBlockquote__footer">
                  Andrés Piedra
                  <span className="videoBlockquoteFooter__source">Rey Raf</span>
                </blockquote>
                <img src="./images/logo/logo.png" alt="Logo Rey Raf" className="videoText__avatar" />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default VideoSection