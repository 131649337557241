import React, { useState, useContext, useEffect } from 'react'
import './../../components/Shopping/Checkout.css'
import CartSummary from './../../components/Shopping/CartSummary'
import CartProfile from './../../components/Shopping/CartProfile'
import CartAddress from './../../components/Shopping/CartAddress'
import CartPay from './../../components/Shopping/CartPay'
import UserContext from './../../context/UserContext'

export default function CheckoutPage() {

  const { currentUser } = useContext(UserContext);

  // Estados para mostrar u ocultar secciones del carrito de compra
  const [showSectionAccount, setShowSectionAccount] = useState(true);
  const [showSectionAddress, setShowSectionAddress] = useState(false);
  const [showSectionPay, setShowSectionPay] = useState(false);

  // Usuario, dirección y método pago carrito
  const userInitial = { id: 0, name: "", email: "", phone: "", role: "", newUser: false, password: "", passwordConfirm: "", cif: "", isValid: false}
  const [userCart, setUserCart] = useState(userInitial);
  const [addressCart, setAddressCart] = useState("");
  const [addressBillingCart, setAddressBillingCart] = useState("");
  const [payMethodCart, setPayMethodCart] = useState("");
  const addressIndependentInitial = {street: "", zip: "", town: "", city: ""}
  const [addressIndependent, setAddressIndependent] = useState(addressIndependentInitial);

  useEffect(() => {
    setShowSectionAddress(!showSectionAccount);
    setShowSectionPay(false);
  }, [showSectionAccount])

  useEffect(() => {
    //setShowSectionAddress(showSectionAddress)
    setShowSectionPay(!showSectionAddress && !showSectionAccount)
  }, [showSectionAddress])

  return (
    <section className="checkout">
      <div className="checkout__wrapper">
        <div className="checkout__content">
          <CartSummary />
          <CartProfile
            showSectionAccount={showSectionAccount} setShowSectionAccount={setShowSectionAccount}
            userCart={userCart} setUserCart={setUserCart}
            setAddressCart={setAddressCart} setAddressBillingCart={setAddressBillingCart} setPayMethodCart={setPayMethodCart}/>
          <CartAddress
            showSectionAccount={showSectionAccount} showSectionAddress={showSectionAddress} setShowSectionAddress={setShowSectionAddress}
            userCart={userCart} addressCart={addressCart} setAddressCart={setAddressCart}
            addressBillingCart={addressBillingCart} setAddressBillingCart={setAddressBillingCart}
            setUserCart={setUserCart} addressIndependent={addressIndependent} setAddressIndependent={setAddressIndependent} setPayMethodCart={setPayMethodCart}/>
          <CartPay showSectionPay={showSectionPay} userCart={userCart} addressCart={addressCart} addressBillingCart={addressBillingCart}
            payMethodCart={payMethodCart} setPayMethodCart={setPayMethodCart} addressIndependent={addressIndependent}/>
        </div>
      </div>
      <div className="checkout__summary">
        Resumen
      </div>
    </section>
  )
}
