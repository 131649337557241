import React, {useContext} from 'react'
import { Navigate } from 'react-router-dom';
import AuthService from "../../services/Authentication/AuthService";

export const ProtectedRoute = ({route}) => {

  if (!AuthService.isAuthenticated())
    return <Navigate to="/" />

  return route;
}
