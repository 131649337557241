import React from 'react'
import Headline from '../../components/Texts/Headline'
import HeroImage from '../../components/Galleries/HeroImage'
import Brief from '../../components/Texts/Brief'
import InfoSection from '../../components/Texts/InfoSection'
import { FiChevronRight } from "react-icons/fi";
import { GiPlantRoots, GiFarmer } from "react-icons/gi";
import Blockquote from '../../components/Texts/Blockquote'
import TitleSection from '../../components/Texts/TitleSection'
import Feature from '../../components/Texts/Feature'
import { MdChecklist, MdOutlineStickyNote2 } from "react-icons/md";
import { AiOutlineBarChart } from "react-icons/ai";
import CardFeatureSection from '../../components/Cards/CardFeatureSection'
import HeroTextImage from '../../components/Galleries/HeroTextImage'
import Season from '../../components/Texts/Season'
import CallToActionSection from '../../components/Cards/CallToActionSection'
import { RiRoadMapLine } from "react-icons/ri";
import { BsBoxSeam } from "react-icons/bs";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './../../components/Galleries/AliceCarousel.css';
import './../../components/Galleries/AliceCarouselBig.css';
import HeroMosaic from '../../components/Galleries/HeroMosaic'

function ProductPage() {

  const cardsFeature = [
    { title: "Implantación", description: <React.Fragment>Comenzó a finales del siglo XX, cuando las infecciones por hongos tipo fusarium, impedían la adaptación de otras variedades a las altas humedades dentro de los invernaderos. <br /><br />Clause-Tezier, empresa francesa de semillas, desarrolló por genética tradicional de cruces entre razas un tipo de tomate de la variedad marmande que era Resistente A Fusarium, de ahí las siglas <strong>RAF</strong>.</React.Fragment>, img: "./images/product/nuestroProducto2.JPG", imgAlt: "Planta tomatera" },
    { title: "Tomate de raza", description: <React.Fragment>El tomate raf no está manipulado genéticamente, es una de las pocas variedades sin hibridar que aún perduran, por lo que sus semillas son fértiles.<br/><br/>Solo las semillas que proceden de la empresa francesa Clause-Tezier poseen la certificación de calidad que garantiza su óptimo rendimiento.</React.Fragment>, img: "./images/product/img4.jpg", imgAlt: "Tomate raf" },
    { title: "Variedades", description: <React.Fragment><strong>Delizia</strong> fruto asurcado con marcado de cuello verde caracterizado por excelente dureza, sabor y alto grado brix, su producción comienza antes que la de marmande, se puede comer verde, entreverado o pintón.<br/><br/><strong>Marmande</strong> es el más antiguo, el Raf de toda la vida. Se puede comer verde o entreverado y sus surcos son más profundos. De consistencia media, dulce y con alto grado brix. Maduración algo más rápida.</React.Fragment>, img: "./images/product/img3.jpg", imgAlt: "Tomate Raf" }
  ]

  const callToActions = [
    { title: "Rey Raf", description: "Conoce como la tercera generación de una familia de agricultoras ha conseguido hacer del tomate Raf, el rey de los tomates.", label: "Empresa", textLink: "Conócenos", link: "/empresa", figure: <GiFarmer /> },
    { title: "Envases", description: "Descubre nuestra caja ecológica, más confortable y resistente, además de ser bonita y personalizada, es positiva para el medio ambiente.", label: "Packaging", textLink: "Innovamos", link: "/envases", figure: <BsBoxSeam /> },
    { title: "Entorno", description: "Conoce nuestro entorno y descubre porque se dan las condiciones ideales para la crianza de este excepcional producto.", label: "Parque Natural y ruta etnográfica", textLink: "Explora", link: "/entorno", figure: <RiRoadMapLine /> }
  ]

  const handleDragStart = (e) => e.preventDefault();

  const carouselItems = [
    { img: <img src="./images/product/img1.jpg" alt="Tomate Raf" onDragStart={handleDragStart} role="presentation" />, width: 500 },
    { img: <img src="./images/product/tomate-raf-agua.jpg" alt = "Tomates Raf" onDragStart={handleDragStart} role="presentation" />, width: 500 },
    { img: <img src="./images/product/img3.jpg" alt="Envasando tomate Raf" onDragStart={handleDragStart} role="presentation" />, width: 500 },
    // { img: <img src="./images/product/producto3.jpeg" alt="Interior invernadero" onDragStart={handleDragStart} role="presentation" />, width: 500 },
    { img: <img src="./images/product/producto5.jpeg" alt="Planta tomatera" onDragStart={handleDragStart} role="presentation" />, width: 500 },
    { img: <img src="./images/product/img5.jpg" alt="Tomates Raf" onDragStart={handleDragStart} role="presentation" />, width: 500 },
    { img: <img src="./images/product/tomate-raf2.jpg" alt="Tomates Raf" onDragStart={handleDragStart} role="presentation" />, width: 500 },
    { img: <img src="./images/product/tomate-raf-playa.jpg" alt="Tomates Raf" onDragStart={handleDragStart} role="presentation" />, width: 500 },
    { img: <img src="./images/product/invernadero.jpg" alt="Interior invernadero" onDragStart={handleDragStart} role="presentation" />, width: 500 }
  ];

  const items = carouselItems.map((item, index) => {
    let style = { width: item.width };
    return (<div className="item" style={style}>{item.img}</div>);
  })

  return (
    <React.Fragment>
      <Headline title="Tomate Raf" subtitle="El rey de los tomates"/>
      <HeroImage src="./images/product/hero-product.jpg" alt="Tomates Raf" />
      <Brief title="Tomate Raf, un referente en la gastronomía española" description={["En pleno Parque Natural de Cabo de Gata, en el municipio almeriense de Níjar, se cultiva uno de los ingredientes más apreciados de la gastronomía española. Lugar idílico para este producto español.", "La familia Piedra decidió empezar a cultivar en esas tierras tan buenas para el cultivo, por el clima frío y de temperatura constante, las tierras minerales y el agua salina del mar. Reúne todas las condiciones climáticas idílicas para este cultivo tan delicado y difícil."]} />
      <section className="section section--inverse">
        <div className="wrapper">
          <TitleSection title="Tomate Raf" description={["El tomate raf existe tradicionalmente en estas tierras y, aunque su aspecto no es el prototipo de tomate que conocemos, su sabor le otorga la categoría de producto selecto."]}></TitleSection>
        </div>
        <div className="wrapper">
          <div className="section__content">
            <Feature icon={<MdChecklist />} title="Características" description="Profundos surcos, forma irregular y un dulzor imposible de encontrar en el resto de variedades. Se trata uno de los frutos más exquisitos, y por ende, más caros. Es comparado con el jamón de Jabugo." />
            <Feature icon={<MdOutlineStickyNote2 />} title="Origen" description="El raf deriva de una selección realizada en Francia durante años, de aquellos tomates que resistieron al hongo fusarium, de ahí su nombre (que viene de estas siglas)." />
            <Feature icon={<GiPlantRoots />} title="Planta" description="Las plantas tomateras de esta variedad se mantienen al límite de la vida. Requiere una concentración de sal 10 veces superior a la normal. Tiene una raíz endeble y necesita un trato especial, solo los agricultores más entregados se dedican a su cuidado."/>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="wrapper">
          <Season/>
        </div>
        <div className="season__icon">
          <GiFarmer />
        </div>
      </section>
      <HeroTextImage title="Su sabor le ha valido la gloria de ser considerado" titleStrong="un producto selecto." subtitle="Profundos surcos y gran dulzor" imgSrc="./images/product/tomates-raf-oro.jpg" imgAlt="Tomates raf" />
      <section className='section'>
        <div className="wrapper">
          <TitleSection title="Implantación del tomate raf en Almería y variedades" description={["Comercializamos las dos mejores variedades, las dos semillas líderes del mercado: delizia y marmande.", "El delizia se siembra antes que el marmande. Ambos son delicados al calor. Delizia se siembra de forma escalonada en diferentes fechas con frío de septiembre, octubre y noviembre. Marmande se siembra siempre en fechas más tardías, en noviembre aproximadamente."]} />
        </div>
        <div className="wrapper">
          <div className="section__content">
            <CardFeatureSection cardsFeature={cardsFeature} />
          </div>
        </div>
      </section>
      <section className="section">
        <AliceCarousel
          autoWidth
          // autoPlay
          // autoPlayStrategy="none"
          // autoPlayInterval={1000}
          disableDotsControls
          disableButtonsControls
          mouseTracking
          items={items}
          infinite
        //responsive={responsive}
        />
      </section>
      {/* <HeroMosaic
        image1="./images/product/img1.jpg" altImage1="Tomate Raf"
        image2="./images/product/tomate-raf-agua.jpg" altImage2="Tomates Raf"
        image3="./images/product/img3.jpg" altImage3="Envasando tomate Raf"
        image4="./images/product/producto3.jpeg" altImag4="Interior invernadero"
        image5="./images/product/producto5.jpeg" altImage5="Planta tomatera"
        image6="./images/product/img5.jpg" altImage6="Tomates Raf"
      /> */}
      <section className="section">
        <div className="wrapper">
          <Blockquote />
        </div>
      </section>
      <section className="section">
        <div className="wrapper">
          <TitleSection title="¿Quieres conocernos mejor?" description={["Explora y descubre porque en Rey Raf cultivamos el mejor tomate Raf."]} />
          <CallToActionSection callToActions={callToActions} />
        </div>
      </section>
    </React.Fragment>
  )
}

export default ProductPage