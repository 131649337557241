import React from 'react'
import './Season.css'
import { Link } from 'react-router-dom'
import CardBanner from '../../components/Cards/CardBanner'
import { RiShoppingBag3Fill } from "react-icons/ri";

function Season() {
  return (
    <div className="season">
      <div className="season__head">
        <h3 className="season__title">Temporada</h3>
        <p className="season__subtitle">Cultivan de forma intensiva, pero sin pesticidas ni abonos químicos, todo el proceso es biológico para lograr la máxima calidad en cada tomate.</p>
      </div>
      <div className="season__body">
        <div className="season__row">
          <div className="season__column">
            <span className="season__primaryText">Siembra</span>
            <p className="season__text">Empieza a finales de agosto y se escalona para que la recogida sea también así.</p>
          </div>
        </div>
        <div className="season__row">
          <div className="season__column">
            <span className="season__primaryText">Cosecha</span>
            <p className="season__text">La temporada dura desde octubre hasta junio aproximadamente.</p>
          </div>
          <div className="season__column">
            <span className="season__primaryText">Resto del año</span>
            <p className="season__text">No se planta nada porque cualquier otro producto estropearía la tierra.</p>
          </div>
        </div>
      </div>
      {/* <CardBanner figure={<RiShoppingBag3Fill />} text="Visite nuestra tienda y disfrute de todos nuestros productos." button={<React.Fragment><Link to="/tienda" title="Ver todos los productos">Ir a tienda</Link></React.Fragment>} /> */}
    </div>
  )
}

export default Season