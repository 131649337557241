import React, { useState, useEffect } from 'react'
import AddressService from "../../services/User/AddressService";
import { FiMapPin } from "react-icons/fi";

export default function CartAddressList({ listAddress, setListAddress, anotherAddress, setAnotherAddress, addressIndependent, setAddressIndependent }) {

  const [isLoading, setIsLoading] = useState(false);
  const addressIndependentInitial = { street: "", zip: "", town: "", city: "" }

  useEffect(() => {
    getAllAddress()
  }, [])

  const getAllAddress = async () => {
    setIsLoading(true);
    await AddressService.getAll().then(
      response => {
        let addresses = response.content.address;
        if (addresses === null || addresses.length === 0) {
          setListAddress([]);
          setAddressIndependent(addressIndependentInitial);
          setAnotherAddress(true);
          setIsLoading(false);
          return;
        }

        let objectAddress = [];
        addresses.map((address, key) => {
          objectAddress.push(createObjectAddress(address, key));
        });
        setListAddress(objectAddress);
        setIsLoading(false);
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  const createObjectAddress = (address, key) => {
    let selected = key === 0 ? true : false;
    if (key === 0) {
      setAddressIndependent({ street: address.street + " " + address.flat_and_gate, zip: address.zip, town: address.town, city: address.city })
    }
    return {
      key: key,
      selected: selected,
      alias: address.alias,
      street: address.street + " " + address.flat_and_gate,
      zip: address.zip,
      town: address.town,
      city: address.city,
      text: address.flat_and_gate ? address.street + " " + address.flat_and_gate + " " + address.zip + " " + address.town + " " + address.city : address.street + " " + address.zip + " " + address.town + " " + address.city
    };
  }

  const selectAddress = (key) => {
    let addresses = [...listAddress];
    addresses.map((a, keyAd) => {
      return a.selected = (keyAd === key) ? true : false;
    });
    setAddressIndependent({ street: addresses[key].street, zip: addresses[key].zip, town: addresses[key].town, city: addresses[key].city })
    setListAddress(addresses);
  }

  return (
    <div className="checkoutStep__keypad checkoutStep__keypad--column">
      {isLoading && "Cargando direcciones..."}
      {!isLoading && listAddress && listAddress.length === 0 &&
        <div >No dispone de direcciones</div>
      }
      {!isLoading && listAddress && listAddress.length > 0 && listAddress.map((address, key) => {
        return <div key={key} className="checkoutStep__button" title="Marcar como dirección de envío">
          <button data-key={key} className={address.selected ? "checkoutStepButton checkoutStepButton--large checkoutStepButton--selected" : "checkoutStepButton checkoutStepButton--large"} onClick={() => selectAddress(key)}>
            <div className="checkoutStepButton__icon"><FiMapPin /></div>
            <div className="checkoutStepButton__text"><strong>{address.alias}</strong><br />{address.text}</div>
          </button>
        </div>
      })}
    </div>
  )
}