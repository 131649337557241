import React, {useEffect, useRef} from 'react'
import CardFeature from './CardFeature';
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

function CardFeatureSection(props) {

  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  useEffect(() => {
    const section = ref.current;
    gsap.from(
      section.querySelectorAll(".cardFeature__item"),
      {
        opacity: 0,
        y: -30,
        duration: 1.2,
        stagger: .2,
        scrollTrigger: {
          trigger: section.querySelectorAll(".cardFeature__item"),
          //markers: true,
          start: "top 85%",
          end: "bottom 80px",
          scrub: false
        }
      }
    );
  }, [])

  return (
    <React.Fragment>
      <div className="section__content" ref={ref}>
      {
          props.cardsFeature !== undefined && props.cardsFeature.length > 0 &&
          props.cardsFeature.map((cardFeature, i) =>
            <span className='cardFeature__item' key={i}>
              <CardFeature title={cardFeature.title} description={cardFeature.description} img={cardFeature.img} imgAlt={cardFeature.imgAlt} />
            </span>
          )
      }
      </div>
    </React.Fragment>
  )
}

export default CardFeatureSection