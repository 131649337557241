import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import TpvService from '../../services/Order/TPVService';
import { Link, useNavigate } from 'react-router-dom'

export default function TpvKOPage() {
  const { locator } = useParams();
  let navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState("");

  useEffect(() => {
    TpvService.tpvko(locator).then(
      response => {
        setMessage("Compra cancelada correctamente.");
        setIsLoading(false);
        setAlert("danger");
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setIsLoading(false);
        setAlert("danger");
      }
    );
  }, [])

  return (
    <React.Fragment>
      {isLoading && <span className="spinner-border"></span>}
      {message && (
        <div className="cardAccount__formGroup">
          {alert && alert === "danger" && (
            <div className="cardAccount__alert cardAccount__alert--danger" role="alert">
              {message}
            </div>)}
        </div>
      )}
    </React.Fragment>
  )
}