import axios from "axios";
import AuthHeader, { AuthHeaderFile } from "../Authentication/AuthHeader";
const API_URL = process.env.REACT_APP_BASE_URL_API + "admin/product";
const API_URL_PUBLIC = process.env.REACT_APP_BASE_URL_API + "product";

const get = async id => {
  const { data } = await axios.get(API_URL + "/" + id, { headers: AuthHeader() })
  return data;
}

const getPublic = async slug => {
  const { data } = await axios.get(API_URL_PUBLIC + "/" + slug)
  return data;
}

const getAll = async () => {
  const { data } = await axios.get(API_URL, { headers: AuthHeader() })
  return data;
}

const getAllPublic = async () => {
  const { data } = await axios.get(API_URL_PUBLIC)
  return data;
}

const create = async (product) => {
  const { data } = await axios.post(API_URL, product, { headers: AuthHeaderFile() })
  return data;
}

const update = async (product, id) => {
  const { data } = await axios.put(API_URL + "/" + id, product, { headers: AuthHeader() })
  return data;
}

const destroy = async (id) => {
  const { data } = await axios.delete(API_URL + "/" + id, { headers: AuthHeader() })
  return data;
}

const blocked = async (id, blocked) => {
  const { data } = await axios.put(API_URL + "/" + id + "/" + blocked, null, { headers: AuthHeader() })
  return data;
}

export default { create, get, getAll, destroy, update, getPublic, getAllPublic, blocked }