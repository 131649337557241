import React from 'react'
import { Link } from 'react-router-dom'
import AuthService from "../../services/Authentication/AuthService";
import AddressService from "../../services/User/AddressService";
import { RiRoadMapLine } from "react-icons/ri"
import './CardInline.css'

export default class AddressInline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      message: "",
      address: []
    }
  }
  async getAllAddress() {
    this.setState({
      loading: true
    });
    await AddressService.getAll().then(
      response => {
        this.setState({
          loading: false,
          message: "",
          address: response.content.address
        })
      }
    );
  }
  async destroyAddress(id) {
    await AddressService.destroy(id).then(
      response => {
        this.setState({
          address: response.content.address
        })
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.setState({
          loading: false,
          message: resMessage,
          alert: "danger"
        });
      }
    );
  }
  componentDidMount() {
    this.getAllAddress();
  }
  render() {
    return (
      <React.Fragment>
        <div className="cardAccountInline">
          <div className="cardAccountInline__wrapper">
            <div className="cardAccountInline__header">
              <div className="cardAccountInline__col">
                <div className="cardAccountInline__icon">
                  <RiRoadMapLine />
                </div>
                <div className="cardAccountInline__info">
                  <h5 className="cardAccountInline__title">Direcciones</h5>
                  <span className="cardAccountInline__text">Listado</span>
                </div>
              </div>
              <div className="cardAccountInline__colActions">
                <Link to="/dashboard/direccion" title='Dar de alta una nueva dirección' className="cardAccountInline__button">Nueva</Link>
              </div>
            </div>
            <hr className="cardAccountInline__hr" />
            {this.state.message && (
              <div className="cardAccount__formGroup">
                {this.state.alert && this.state.alert === "danger" && (
                  <div className="cardAccount__alert cardAccount__alert--danger" role="alert">
                    {this.state.message}
                  </div>)}
                {this.state.alert && this.state.alert === "success" && (
                  <div className="cardAccount__alert cardAccount__alert--success" role="alert">
                    {this.state.message}
                  </div>)}
              </div>
            )}
            <div className="cardAccountInline__body">
              {this.state.loading && (<span className="spinner-border"></span>)}
              {!this.state.loading && this.state.address && this.state.address.length === 0 &&
                <div className="cardAccountInline__row">No dispone de direcciones</div>
              }
              {!this.state.loading && this.state.address && this.state.address.map((address, key) =>
                <div key={key} className="cardAccountInline__row">
                  <div className="cardAccountInline__col">
                    <div className="cardAccountInline__info">
                      <h5 className="cardAccountInline__title">{address.alias}</h5>
                      <span className="cardAccountInline__text">{address.street} {address.flat_and_gate} {address.zip} {address.town} {address.city}</span>
                    </div>
                  </div>
                  <div className="cardAccountInline__colActions">
                    <Link
                        to={"/dashboard/direccion/" + address.id}
                        title='Ver/Editar dirección'
                        className="cardAccountInline__button cardAccountInline__button--mini cardAccountInline__button--info">Ver/Editar</Link>
                    <button
                        onClick={() => this.destroyAddress(address.id)}
                        title='Eliminar dirección'
                        className="cardAccountInline__button cardAccountInline__button--mini cardAccountInline__button--danger">Eliminar</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}