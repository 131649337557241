import React, { useEffect } from 'react'
import './Headline.css'
import { gsap } from 'gsap'

function Headline(props) {

  const timeline = gsap.timeline();

  useEffect(() => {
    const subtitle = document.querySelectorAll('.subtitle');
    const title = document.querySelectorAll('.maintitle');
    timeline
      .from(subtitle, { opacity: 0, y: 50, duration: 1, stagger: .2 })
      .from(title, { opacity: 0, y: 50, duration: 1.2, stagger: .2 }, "-=.75")
  }, []);

  return (
    <div className="headline">
      <div className="wrapper">
        <div className="headline__content">
          {
          props.subtitle !== undefined && props.subtitle.length > 0 &&
          <div className="headline__subtitle">
            <h2 className="subtitle">{props.subtitle}</h2>
          </div>
          }
          <div className="headline__maintitle">
            <h1 className="maintitle">{props.title}</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Headline