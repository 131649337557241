import React, { useState } from 'react'
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { Link, useNavigate } from 'react-router-dom';
import AuthService from '../../services/Authentication/AuthService';

const emailValid = value => {
  if (!value || !isEmail(value)) {
    return (
      <span className="cardLogin__invalid">Por favor, introduzca un email válido.</span>
    );
  }
}

export default function RecoverPassword() {

  const [email, setEmail] = useState("")
  const [form, setForm] = useState("")
  const [checkButton, setCheckButton] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [alert, setAlert] = useState("danger");
  const [hideForm, setHideForm] = useState(false);

  const onChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const handleResetPassword = (e) => {
    e.preventDefault();
    setMessage("")
    setIsLoading(true)
    form.validateAll();
    if (checkButton.context._errors.length === 0) {
      AuthService.forgotPassword(email).then(
        response => {
          setAlert("success")
          setIsLoading(false)
          setMessage("Revise su email para restablecer su contraseña.")
          setHideForm(true);
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setIsLoading(false)
          setMessage(resMessage)
          setAlert("danger")
        }
      )
    }
    else {
      setIsLoading(false)
    }
  }

  return (
    <React.Fragment>
      <section className="section__top section--green">
        <div className="wrapper__form">
          {/* {this.state.user && (<Navigate to="/dashboard" replace={false} />)} */}
          <div className="login">
            <div className="cardLogin">
              <div className="cardLogin__header">
                <h4 className="cardLogin__title">Restablecer contraseña</h4>
                <p className="cardLogin__text">¿Ya tienes cuenta? <Link to="/login" className="cardLogin__link" title="Iniciar sesión">Inicia sesión aquí</Link></p>
                {message && (
                  <div className="cardLogin__formGroup" style={{ marginTop: '2rem' }}>
                    {alert && alert === "danger" &&
                      <div className="cardLogin__invalid cardLogin__invalidAlert" role="alert">
                        {message}
                      </div>}
                    {alert && alert === "success" &&
                      <div className="cardLogin__invalid cardLogin__invalidSuccess" role="alert">
                        {message}
                      </div>}
                  </div>
                )}
              </div>
              {!hideForm && <Form onSubmit={handleResetPassword}
                ref={c => {
                  setForm(c);
                }}>
                <div className="cardLogin__body">
                  <div className="cardLogin__formGroup">
                    <label htmlFor="email" className="cardLogin__label">Email</label>
                    <Input id="email"
                      type="email"
                      className="cardLogin__input"
                      name="email"
                      placeholder="Indique su email"
                      value={email}
                      onChange={onChangeEmail}
                      validations={[emailValid]} />
                  </div>
                  <div className="cardLogin__formGroup">
                    <button type="submit" className="cardLogin__button">
                      {isLoading && <span className="spinner-border"></span>}
                      Recuperar contraseña
                    </button>
                  </div>
                </div>
                <CheckButton style={{ display: "none" }}
                  ref={c => {
                    setCheckButton(c);
                  }}
                />
              </Form>}
            </div>
          </div>
        </div>
      </section>
      <div className="cardLogin__shape">
        <div className="cardLogin__shapeBotton">
          <svg viewBox="0 0 3000 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 1000V583.723L3000 0V1000H0Z" fill="#fff"></path>
          </svg>
        </div>
      </div>
    </React.Fragment>
  );
}