import axios from "axios"
const API_URL = process.env.REACT_APP_BASE_URL_API + "tpv";
const API_URL_OK = process.env.REACT_APP_BASE_URL_API + "tpvok";
const API_URL_KO = process.env.REACT_APP_BASE_URL_API + "tpvko";

const generateParametersTPV = async(creditNumber, creditName, creditExpiry, creditCVC, order, amount) => {
  const {data} = await axios.post(API_URL, {
    creditNumber: creditNumber,
    creditName: creditName,
    creditExpiry: creditExpiry,
    creditCVC: creditCVC,
    order: order,
    amount: amount
  })

  return data;
}

const pay = async (parameter) => {
  const data = await axios.post("https://sis.redsys.es/sis/realizarPago",
    parameter,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json;charset=UTF-8'
      },
    }
    )
  let promise = data.then(response => response);
  return data;
}

const tpvok = async (locator) => {
  const { data } = await axios.put(API_URL_OK + '/' + locator);
  return data;
}

const tpvko = async (locator) => {
  const { data } = await axios.delete(API_URL_KO + '/' + locator)
  return data;
}

export default { pay, generateParametersTPV, tpvok, tpvko }