import React from 'react'
import Headline from '../../components/Texts/Headline'
import HeroImage from '../../components/Galleries/HeroImage'
import Brief from '../../components/Texts/Brief'
import TitleSection from '../../components/Texts/TitleSection'
import CardFeatureSection from '../../components/Cards/CardFeatureSection'
import CardBanner from '../../components/Cards/CardBanner'
import CallToActionSection from '../../components/Cards/CallToActionSection'
import { RiSeedlingLine, RiRoadMapLine } from "react-icons/ri";
import { GiFarmer } from "react-icons/gi";
import { BsBoxSeam } from "react-icons/bs";
import VideoSectionRuta from '../../components/Video/VideoSectionRuta'

function EnvironmentPage() {

  const cardsFeature = [
    { title: "Producción", description: <React.Fragment>Cada temporada, se producen entre 3 y 4 kilos de tomate raf por metro cuadrado. <br /><br />El rendimiento de cualquier otra variedad del rojo fruto superaría los 15 kilos por m2. <br /><br />De esos tres kilos, tan sólo uno cumple los criterios para comercializarse bajo la marca, el resto pasa a las subastas.</React.Fragment>, img: "./images/environment/producto4.jpeg", imgAlt: "Fotografía cultivo"},
    { title: "Mantenimiento", description: <React.Fragment>La salinidad del agua subterránea, el frío invierno y la humedad hacen que sea el lugar elegido. Pero no todo son facilidades, las lluvias (el agua dulce) pueden estropear una cosecha.<br/><br/>Por eso es necesaria una fuerte inversión para el mantenimiento del invernadero: calefacción, cuidado del plástico... y, además, el elevado precio de las semillas.</React.Fragment>, img: "./images/environment/img10.JPG", imgAlt: "Cultivos" },
    { title: "Innovación", description: <React.Fragment>Continuamos trabajando para hacer de nuestro tomate Raf el producto más exclusivo y especial. <br /><br />Para ello, cada año incorporamos innovaciones y mejoras en nuestras instalaciones que eviten los posibles daños.</React.Fragment>, img: "./images/product/invernadero.jpg", imgAlt: "Cultivos" }
  ]

  const callToActions = [
    { title: "Rey Raf", description: "Conoce como la tercera generación de una familia de agricultoras ha conseguido hacer del tomate Raf, el rey de los tomates.", label: "Empresa", textLink: "Conócenos", link: "/empresa", figure: <GiFarmer /> },
    { title: "Tomate Raf", description: "Historia, características, variedades, temporada y todo lo que necesitas saber sobre el tomate Raf.", label: "Nuestro Producto", textLink: "Descúbrelo", link: "/productos", figure: <RiSeedlingLine /> },
    { title: "Envases", description: "Descubre nuestra caja ecológica, más confortable y resistente, además de ser bonita y personalizada, es positiva para el medio ambiente.", label: "Packaging", textLink: "Innovamos", link: "/envases", figure: <BsBoxSeam /> }
  ]

  return (
    <React.Fragment>
      <Headline title="Entorno" subtitle="Parque Natural y Ruta Etnográfica" />
      <HeroImage src="./images/environment/hero-entorno.jpg" alt="Fotografía del Parque Natural Cabo de Gata-Níjar" />
      <Brief title="El entorno ideal para el cultivo del tomate Raf" description={["La producción se concentra en el Hornillo de los Martínez ,en pleno parque Natural Cabo de Gata - Níjar, entre montañas y muy próxima a la playa, lo que conforma un microclima que junto con la calidad de las tierras y su agua hace que durante los inviernos, y en general el resto del año, las temperaturas sean mas bajas. Nuestro tomate \"quiere frío\".", "Unas condiciones ideales para la crianza de este excepcional producto que permite su recolecta aproximadamente entre los meses de octubre a junio, en función de las temperaturas."]} />
      <section className='section section--inverse'>
        <div className="wrapper">
          <TitleSection title="Localización, origen y descripción" description={["En pleno Parque Natural Cabo de Gata-Níjar, en el municipio almeriense de Níjar Los Martínez, se cultiva uno de los ingredientes más apreciados de la gastronomía española.", "La familia Piedra decidió empezar a cultivar en esas tierras tan buenas para el cultivo, por el clima frío y de temperatura constante, las tierras minerales y el agua salina del mar. Reúne todas las condiciones climáticas idílicas para este cultivo tan delicado y difícil.", "El beneficio que da producir tomates raf no es alto ya que es un producto muy costoso que requiere demasiados cuidados."]} />
        </div>
        <div className="wrapper">
          <div className="section__content">
            <CardFeatureSection cardsFeature={cardsFeature} />
          </div>
        </div>
      </section>
      <section className="section">
        <VideoSectionRuta />
      </section>
      <section className="section">
        <div className="wrapper">
          <CardBanner figure={<img alt="Imagen folleto" src="./images/environment/iconoRutaEtnografica.jpg"/>} text="Descarga el folleto de la Ruta Etnográfica que incluye la visita a la Finca del Rey Raf." button={<React.Fragment><a href="./files/folleto-ruta-etnografica_es.pdf" target="_blank" title="Descargar folleto en español">Español</a><a href="./files/folleto-ruta-etnografica_en.pdf" target="_blank" title="Descargar folleto en inglés">English</a></React.Fragment>}/>
        </div>
      </section>
      <section className="section">
        <div className="wrapper">
          <TitleSection title="¿Quieres conocernos mejor?" description={["Explora y descubre porque en Rey Raf cultivamos el mejor tomate Raf."]} />
          <CallToActionSection callToActions={callToActions} />
        </div>
      </section>
    </React.Fragment>
  )
}

export default EnvironmentPage