import React, { useEffect, useRef } from 'react'
import CallToAction from '../../components/Cards/CallToAction';
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

function CallToActionSection(props) {

  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  useEffect(() => {
    const section = ref.current;
    gsap.from(
      section.querySelectorAll(".callToAction__item"),
      {
        opacity: 0,
        y: -30,
        duration: 1.2,
        stagger: .2,
        scrollTrigger: {
          trigger: section.querySelectorAll(".callToAction__item"),
          //markers: true,
          start: "top 85%",
          end: "bottom 80px",
          scrub: false
        }
      }
    );
  }, [])

  return (
    <React.Fragment>
      <div className="section__content" ref={ref}>
      {
        props.callToActions !== undefined && props.callToActions.length > 0 &&
        props.callToActions.map((callToAction, i) =>
          <span className='callToAction__item' key={i}>
            <CallToAction title={callToAction.title} description={callToAction.description} label={callToAction.label} link={callToAction.link} textLink={callToAction.textLink} figure={callToAction.figure} />
          </span>
        )
      }
      </div>
    </React.Fragment>
  )
}

export default CallToActionSection