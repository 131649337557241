import React, {useState, useEffect} from 'react'

export default function CartInfoUserForm({ handleValidUserForm, userCart, setUserCart }) {

  const [profileName, setProfileName] = useState("");
  const [profileEmail, setProfileEmail] = useState("");
  const [profilePhone, setProfilePhone] = useState("");
  const [profileCif, setProfileCif] = useState("");
  const [checkCreateAccount, setCheckCreateAccount] = useState(true);
  const [profilePassword, setProfilePassword] = useState("")
  const [profileConfirmPassword, setProfileConfirmPassword] = useState("")

  useEffect(() => {
    handleValidForm()
  }, [handleValidUserForm])

  const onChangeProfileName = (e) => {
    let input = document.querySelector(`input[name="profileName"]`);
    if (e.target.value === "") {
      input.style.border = "1px solid red";
    }
    else {
      input.removeAttribute("style");
    }

    setProfileName(e.target.value);
  }

  const onChangeProfileEmail = (e) => {
    let input = document.querySelector(`input[name="profileEmail"]`);
    if (e.target.value === "" || !isValidEmail(e.target.value)) {
      input.style.border = "1px solid red";
    }
    else {
      input.removeAttribute("style");
    }

    setProfileEmail(e.target.value);
  }

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  }

  const onChangeProfilePhone = (e) => {
    let input = document.querySelector(`input[name="profilePhone"]`);
    if (e.target.value === "") {
      input.style.border = "1px solid red";
    }
    else {
      input.removeAttribute("style");
    }

    setProfilePhone(e.target.value);
  }

  const onChangeProfileCif = (e) => {
    let input = document.querySelector(`input[name="profileCif"]`);
    if (e.target.value === "") {
      input.style.border = "1px solid red";
    }
    else {
      input.removeAttribute("style");
    }

    setProfileCif(e.target.value);
  }

  const onChangeProfilePassword = (e) => {
    let input = document.querySelector(`input[name="profilePassword"]`);
    if (e.target.value === "") {
      input.style.border = "1px solid red";
    }
    else {
      input.removeAttribute("style");
    }

    setProfilePassword(e.target.value);
  }

  const onChangeProfileConfirmPassword = (e) => {
    let input = document.querySelector(`input[name="profileConfirmPassword"]`);
    if (e.target.value === "") {
      input.style.border = "1px solid red";
    }
    else {
      input.removeAttribute("style");
    }

    setProfileConfirmPassword(e.target.value);
  }

  const createAccount = () => {
    setCheckCreateAccount(!checkCreateAccount);
  }

  const handleValidForm = () => {

    let isValid = true

    if (profileName === "") {
      let input = document.querySelector(`input[name="profileName"]`);
      input.style.border = "1px solid red";
      isValid = false
    }

    if (profileEmail === "") {
      let input = document.querySelector(`input[name="profileEmail"]`);
      input.style.border = "1px solid red";
      isValid = false
    }

    if (profilePhone === "") {
      let input = document.querySelector(`input[name="profilePhone"]`);
      input.style.border = "1px solid red";
      isValid = false
    }

    if (profileCif === "") {
      let input = document.querySelector(`input[name="profileCif"]`);
      input.style.border = "1px solid red";
      isValid = false
    }

    if (checkCreateAccount) {
      if (profilePassword === "" || profileConfirmPassword === "" || profilePassword !== profileConfirmPassword) {
        let inputPasspord = document.querySelector(`input[name="profilePassword"]`);
        inputPasspord.style.border = "1px solid red";
        let inputConfirmPasspord = document.querySelector(`input[name="profileConfirmPassword"]`);
        inputConfirmPasspord.style.border = "1px solid red";
        isValid = false
      }
    }

    let user = {
      id: 0,
      name: profileName,
      email: profileEmail,
      phone: profilePhone,
      role: "ROLE_USER",
      cif: profileCif,
      newUser: checkCreateAccount,
      password: checkCreateAccount ? profilePassword : "",
      passwordConfirm: checkCreateAccount ? profileConfirmPassword : "",
      isValid: isValid
    };
    setUserCart(user)
  }

  return (
    <React.Fragment>
      <div className="cardAccount__header">
        <h4 className="cardAccount__title">Información de perfil</h4>
      </div>
      <div className="cardAccount__body">
        <div className="cardAccount__formGroup">
          <label htmlFor="profileName" className="cardAccount__label">Nombre</label>
          <input id="profileName"
            type="text"
            className="cardAccount__input"
            name="profileName"
            placeholder="Indique su nombre y apellidos"
            value={profileName}
            onChange={onChangeProfileName}
          />
        </div>
        <div className="cardAccount__formGroup">
          <label htmlFor="profileEmail" className="cardAccount__label">Email</label>
          <input id="profileEmail"
            type="text"
            className="cardAccount__input"
            name="profileEmail"
            placeholder="Indique su email"
            value={profileEmail}
            onChange={onChangeProfileEmail} />
        </div>
        <div className="cardAccount__formGroup">
          <label htmlFor="profilePhone" className="cardAccount__label">Teléfono</label>
          <input id="profilePhone"
            type="text"
            className="cardAccount__input"
            name="profilePhone"
            placeholder="Indique su teléfono"
            value={profilePhone}
            onChange={onChangeProfilePhone} />
        </div>
        <div className="cardAccount__formGroup">
          <label htmlFor="profileCif" className="cardAccount__label">Documento de identificación</label>
          <input id="profileCif"
            type="text"
            className="cardAccount__input"
            name="profileCif"
            placeholder="Indique su dni, cif..."
            value={profileCif}
            onChange={onChangeProfileCif} />
        </div>
      </div>
      <input type="checkbox" name="createAccount" value="checkCreateAccount" onChange={createAccount} checked={checkCreateAccount} /><label for="createAccount">Cree su cuenta indicando una contraseña</label>
      {
        checkCreateAccount &&
        <div>
          <div className="cardAccount__body">
            <div className="cardAccount__formGroup">
              <label htmlFor="profilePassword" className="cardAccount__label">Contraseña</label>
              <input id="profilePassword"
                type="password"
                className="cardAccount__input"
                name="profilePassword"
                placeholder="Indique su contraseña"
                value={profilePassword}
                onChange={onChangeProfilePassword}
              />
            </div>
            <div className="cardAccount__formGroup">
              <label htmlFor="profileConfirmPassword" className="cardAccount__label">Confirmar contraseña</label>
              <input id="profileConfirmPassword"
                type="password"
                className="cardAccount__input"
                name="profileConfirmPassword"
                placeholder="Confirme su contraseña"
                value={profileConfirmPassword}
                onChange={onChangeProfileConfirmPassword} />
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  )
}