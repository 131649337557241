import React from 'react'
import HeaderAccount from '../../components/Account/HeaderAccount'
import OrderAdminInline from '../../components/AdminAccount/OrderAdminInline'
import ProductInline from '../../components/AdminAccount/ProductInline'
import StoreInline from '../../components/AdminAccount/StoreInline'
import './../../components/Account/Badge.css'

function DashboardAdminPage() {
  return (
    <React.Fragment>
      <section className="sectionAdmin">
        <div className="wrapper">
          <HeaderAccount />
          <StoreInline />
          <ProductInline />
          <OrderAdminInline />
        </div>
      </section>
    </React.Fragment>
  )
}

export default DashboardAdminPage