import React from "react"
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import logo from "../../assets/logo.png";

const borderColor = '#008060';//'#008060'
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    flexDirection: 'column',
    //fontFamily: 'Arial',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 50,
    paddingRight: 50,
    lineHeight: 1.5
  },
  // section: {
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1
  // },
  // logo
  logo: {
    width: 150,
    height: 50,
    marginLeft: 20,
    //marginRight: 'auto'
  },
  // Title
  titleContainer: {
    marginTop: 24,
  },
  reportTitle: {
    color: '#008060',
    fontSize: 11,
    textAlign: 'right',
    textTransform: 'uppercase',
    marginRight: 100
  },
  // Text footer
  footerText: {
    color: '#000',
    fontSize: 9,
    textAlign: 'left'
  },
  section: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 25
  },
  // Address
  sectionAddressUser: {
    width: '100px',
    marginRight: 100
  },
  titleAddress: {
    fontStyle: 'bold'
  },
  textAddress: {
    fontSize: 8
  },
  // Table
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#008060',
  },
  container: {
    flexDirection: 'row',
    borderBottomColor: '#008060',
    backgroundColor: '#fff',
    color: '#000',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 16,
    textAlign: 'left',
    fontStyle: 'bold',
    flexGrow: 1,
    fontSize: 8,
    padding: 0
  },
  amount: {
    width: '10%',
    paddingLeft: 3,
    paddingTop: 2
  },
  code: {
    width: '10%',
    paddingLeft: 3,
    paddingTop: 2
  },
  article: {
    width: '55%',
    paddintTop: 3,
    paddingLeft: 3,
    paddingTop: 2
  },
  price: {
    width: '10%',
    paddintTop: 3,
    paddingLeft: 3,
    paddingTop: 2
  },
  iva: {
    width: '5%',
    paddintTop: 3,
    paddingLeft: 3
  },
  subtotal: {
    width: '10%',
    paddingLeft: 3,
    paddingTop: 2
  },
  row: {
    flexDirection: 'row',
    // borderBottomColor: '#008060',
    // borderBottomWidth: 1,
    alignItems: 'center',
    height: 20,
    fontStyle: 'bold',
    //padding: 0
  },
  amountRow: {
    width: '10%',
    textAlign: 'right',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 3,
    height: 20,
    fontSize: 8,
    paddingTop: 2,
  },
  codeRow: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 5,
    paddingTop: 2,
    height: 20,
    fontSize: 8
  },
  articleRow: {
    width: '55%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'left',
    paddingLeft: 5,
    height: 20,
    fontSize: 8,
    paddingTop: 2,
  },
  priceRow: {
    width: '10%',
    textAlign: 'right',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 5,
    height: 20,
    fontSize: 8,
    paddingTop: 2,
  },
  ivaRow: {
    width: '5%',
    textAlign: 'right',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 3,
    height: 20,
    fontSize: 8,
    paddingTop: 2,
  },
  subtotalRow: {
    width: '10%',
    textAlign: 'right',
    paddingRight: 5,
    height: 20,
    fontSize: 8,
    paddingTop: 2,
  },
  rowFooter: {
    flexDirection: 'row',
    borderTopColor: '#008060',
    borderTopWidth: 1,
    alignItems: 'center',
    height: 24,
    fontSize: 12,
    fontStyle: 'bold',
  },
  amountFooter: {
    width: '10%',
    textAlign: 'right',
    paddingRight: 8,
    fontSize: 8
  },
  descriptionFooter: {
    width: '75%',
    textAlign: 'right',
    paddingRight: 8,
  },
  totalFooter: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 8,
    fontSize: 8
  },
  total: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 8,
  },
  // Main Zone
  sectionMainData: {
    display: 'flex',
    flexDirection: 'row'
  },
  elementMainDataOne: {
    backgroundColor: 'rgba(0, 128, 96, .1)',
    marginRight: 3,
    padding: 5,
    width: 130
  },
  elementMainData: {
    backgroundColor: 'rgba(0, 128, 96, .1)',
    marginRight: 3,
    padding: 5,
    width: 90
  },
  titleMainData: {
    textTransform: 'uppercase',
    fontStyle: 'bold',
    fontWeight: 'bolder',
    fontSize: 8,
    textAlign: 'left'
  },
  textMainData: {
    fontSize: 9,
    textAlign: 'right'
  },
  // Final row
  finalRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textMethodPay: {
    fontSize: 8,
    borderBottomColor: '#008060',
    borderBottomWidth: 1,
  },
  text: {
    fontSize: 9
  },
  // Summary
  rowSummary: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 20,
    borderWidth: 1,
    borderColor: '#008060',
    height: 50  },
  emptyColumnSummary: {
    width: '5%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  discountColumnSummary: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    padding: 5
  },
  discountPayColumnSummary: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    padding: 5
  },
  ivaColumnSummary: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    padding: 5
  },
  baseColumnSummary: {
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    padding: 5
  },
  importIvaColumnSummary: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    padding: 5
  },
  importREColumnSummary: {
    width: '15%',
    padding: 5
  },
  textRight: {
    fontSize: 9,
    textAlign: 'right',
    paddingRight: 10,
    paddingTop: 15
  }
});

const InvoiceHeader = ({title}) => (
  <View style={styles.section}>
    <Image style={styles.logo} src={logo} />
    <InvoiceTitle title={title}/>
  </View>
)

const InvoiceTitle = ({ title }) => (
  <View style={styles.titleContainer}>
    <Text style={styles.reportTitle}>{title}</Text>
  </View>
);

const InvoiceFooter = ({ text }) => (
  <View style={styles.titleContainer}>
    <Text style={styles.footerText}>{text}</Text>
  </View>
);

const InvoiceAddress = ({name, address, cif}) => (
  <View style={styles.section}>
    <View>
      <Text style={styles.titleAddress}>REY RAF 5 CORONAS S.L.U.</Text>
      <Text style={styles.textAddress}>HORNILLO DE LOS MARTÍNEZ, S/N</Text>
      <Text style={styles.textAddress}>04116 NÍJAR - ALMERÍA</Text>
      <Text style={styles.textAddress}>Tel.: 950 60 03 54</Text>
      <Text style={styles.textAddress}>Email: info@reyraf.es</Text>
      <Text style={styles.textAddress}>C.I.F. B04768123</Text>
    </View>
    <View style={styles.sectionAddressUser}>
      <Text style={styles.titleAddress}>{name}</Text>
      <Text style={styles.textAddress}>{address}</Text>
      <Text style={styles.textAddress}>{cif}</Text>
    </View>
  </View>
);

const InvoiceZoneMainData = ({serie, number, date, customerCode}) => (
  <View style={styles.sectionMainData}>
    <View style={styles.elementMainDataOne}>
      <Text style={styles.titleMainData}>Nº Pedido</Text>
      <Text style={styles.textMainData}>{serie}{number}</Text>
    </View>
    <View style={styles.elementMainData}>
      <Text style={styles.titleMainData}>Fecha</Text>
      <Text style={styles.textMainData}>{date}</Text>
    </View>
    <View style={styles.elementMainData}>
      <Text style={styles.titleMainData}>Fecha Valor</Text>
      <Text style={styles.textMainData}>{date}</Text>
    </View>
    <View style={styles.elementMainData}>
      <Text style={styles.titleMainData}>Cod. Cliente</Text>
      <Text style={styles.textMainData}>{customerCode}</Text>
    </View>
  </View>
);

const InvoiceItemsTable = ({ lines, total }) => (
  <View style={styles.tableContainer}>
    <InvoiceTableHeader />
    <InvoiceTableRow lines={lines} />
    <InvoiceTableRowEmpty numLines={lines.length} />
    <InvoiceTableFooter lines={lines} total={total} />
  </View>
);

const InvoiceTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.amount}>Cantidad</Text>
    <Text style={styles.code}>Código</Text>
    <Text style={styles.article}>Artículo</Text>
    <Text style={styles.price}>Precio</Text>
    <Text style={styles.iva}>IVA</Text>
    <Text style={styles.subtotal}>Subtotal</Text>
  </View>
);

const InvoiceTableRow = ({ lines }) => {
  const rows = lines.map((item, key) =>
    <View style={styles.row} key={key}>
      <Text style={styles.amountRow}>{item.amount_line}</Text>
      <Text style={styles.codeRow}>{item.product_code}</Text>
      <Text style={styles.articleRow}>{item.product_name}</Text>
      <Text style={styles.priceRow}>{isNumber(item.product_price - (item.product_price * 0.04))}</Text>
      <Text style={styles.ivaRow}>4,00</Text>
      <Text style={styles.subtotalRow}>{isNumber((item.product_price - (item.product_price * 0.04)) * item.amount_line)}</Text>
    </View>
  );

  return (<React.Fragment>{rows}</React.Fragment>)
};

const InvoiceTableRowEmpty = ({ numLines }) => {
  const emptyLines = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14']
  for(let i = 0; i < numLines; i++) {
    emptyLines.pop();
  }
  const rows = emptyLines.map((key, item) =>
    <View style={styles.row} key={key}>
      <Text style={styles.amountRow}></Text>
      <Text style={styles.codeRow}></Text>
      <Text style={styles.articleRow}></Text>
      <Text style={styles.priceRow}></Text>
      <Text style={styles.ivaRow}></Text>
      <Text style={styles.subtotalRow}></Text>
    </View>
  );

  return (<React.Fragment>{rows}</React.Fragment>)
};

const InvoiceTableFooter = ({ lines, total }) => {
  let totalAmount = 0;
  for(let i = 0; i < lines.length; i++) {
    totalAmount += lines[i].amount_line;
  }
  return (
    <View style={styles.rowFooter}>
      <Text style={styles.amountFooter}>{totalAmount}</Text>
      <Text style={styles.descriptionFooter}></Text>
      <Text style={styles.totalFooter}>Subtotal {isNumber(total - (total * 0.04))}</Text>
    </View>
  )
};

const InvoiceSummary = ({total}) => {
  let importeIVA = total * 0.04;
  let base = total - importeIVA;
  return (
    <View style={styles.rowSummary}>
      <View style={styles.emptyColumnSummary}></View>
      <View style={styles.discountColumnSummary}>
        <Text style={styles.titleMainData}>Descuento</Text>
        <Text style={styles.textRight}>%</Text>
      </View>
      <View style={styles.discountPayColumnSummary}>
        <Text style={styles.titleMainData}>Dto. P.Pago</Text>
        <Text style={styles.textRight}>%</Text>
      </View>
      <View style={styles.ivaColumnSummary}>
        <Text style={styles.titleMainData}>IVA</Text>
        <Text style={styles.textRight}>4,00%</Text>
      </View>
      <View style={styles.baseColumnSummary}>
        <Text style={styles.titleMainData}>Base Imponible</Text>
        <Text style={styles.textRight}>{isNumber(base)}</Text>
      </View>
      <View style={styles.importIvaColumnSummary}>
        <Text style={styles.titleMainData}>Importe IVA</Text>
        <Text style={styles.textRight}>{isNumber(importeIVA)}</Text>
      </View>
      <View style={styles.importREColumnSummary}>
        <Text style={styles.titleMainData}>Importe R.E.</Text>
        <Text style={styles.textRight}></Text>
      </View>
    </View>
  )
}

const InvoiceMethodPayAndTotal = ({methodPay, total}) => {
  return (
    <View style={styles.finalRow}>
      <View>
        <Text style={styles.textMethodPay}>Forma de Pago</Text>
        <Text style={styles.text}>{methodPay}</Text>
      </View>
      <View style={styles.elementMainData}>
        <Text style={styles.titleMainData}>TOTAL PEDIDO</Text>
        <Text style={styles.textMainData}>{isNumber(total)} €</Text>
      </View>
    </View>
  )
}

const formatter = new Intl.DateTimeFormat("en-GB", {
  year: "numeric",
  month: "numeric",
  day: "2-digit"
});

function isNumber(x) {
  return (
    Number(x)
      .toFixed(2) // always two decimal digits
      .replace('.', ',') // replace decimal point character with ,
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') //+ ' €'
  )
}
export default function InvoicePDF({order}) {
  let date = formatter.format(Date.parse(order.created_at));
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <InvoiceHeader title={'Pedido'} />
        <InvoiceAddress name={order.user_name} address={order.user_address} cif={order.user_cif}/>
        <InvoiceZoneMainData serie={order.serie} number={order.number} date={date} customerCode={order.user_code}/>
        <InvoiceItemsTable lines={order.lines} total={order.total_price} />
        <InvoiceSummary total={order.total_price}/>
        <InvoiceMethodPayAndTotal methodPay={order.pay_method} total={order.total_price}/>
        <InvoiceFooter text={"REY RAF 5 CORONAS SLU es Responsable del tratamiento de conformidad con el GDPR con la finalidad de mantener una relación comercial y conservarlos mientras exista un interés mutuo para ello. No se comunicarán los datos a terceros. Puede ejercer los derechos de acceso, rectificación portabilidad, supresión, limitación y oposición en PJE. LOS MARTINEZ, SN 04116 LOS MARTINEZ, (NIJAR) (ALMERIA). Email: info@reyraf.es y el de reclamación a wwwagdp.es."}/>
      </Page>
    </Document>
  );
}