import React, { useState, useEffect, useRef } from 'react'
import Headline from '../../components/Texts/Headline'
import HeroImage from '../../components/Galleries/HeroImage'
import Brief from '../../components/Texts/Brief'
import TitleSection from '../../components/Texts/TitleSection'
import Feature from '../../components/Texts/Feature'
import { BsBoxSeam } from "react-icons/bs";
import { MdOutlineCases } from "react-icons/md";
import { RiHandHeartLine } from "react-icons/ri";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './../../components/Galleries/AliceCarousel.css';
import { RiSeedlingLine, RiRoadMapLine } from "react-icons/ri";
import { GiFarmer } from "react-icons/gi";
import CallToActionSection from '../../components/Cards/CallToActionSection';

function PackagingPage() {

  const handleDragStart = (e) => e.preventDefault();

  const carouselItems = [
    {img: <img src="./images/envases/caja.jpg" alt="Caja estándar" onDragStart={handleDragStart} role="presentation"/>, width: 'auto'},
    { img: <img src="./images/envases/caja-abierta.jpg" alt="Interior caja Rey Raf" onDragStart={handleDragStart} role="presentation" />, width: 'auto' },
    { img: <img src="./images/envases/caja-cuadrada.jpg" alt="Estuche de madera y contenido: tomate raf, AOVE y sal de las salinas" onDragStart={handleDragStart} role="presentation" />, width: 'auto' },
    {img: <img src="./images/envases/caja-triangulo.jpg" alt="Estuche de madera con forma de triángulo" onDragStart={handleDragStart} role="presentation"/>, width: 'auto'},
    { img: <img src="./images/envases/amigos01.jpg" alt="Mónica Marchante y Andrés con nuestro envase rectangular" onDragStart={handleDragStart} role="presentation" />, width: 'auto' },
    { img: <img src="./images/envases/amigos02.JPG" alt="Chendo y Andrés con nuestro envase rectangular" onDragStart={handleDragStart} role="presentation" />, width: 'auto' },
    { img: <img src="./images/envases/amigos03.JPG" alt="Michael Robinson y Andrés con nuestro envase rectangular" onDragStart={handleDragStart} role="presentation" />, width: 'auto' },
    { img: <img src="./images/envases/amigos04.JPG" alt="Carlos Martínez y Andrés con nuestro envase rectangular" onDragStart={handleDragStart} role="presentation" />, width: 'auto' },
    { img: <img src="./images/envases/amigos06.jpg" alt="Juan Carlos y Andrés con nuestro envase triangular" onDragStart={handleDragStart} role="presentation" />, width: 'auto' },
    // { img: <img src="./images/envases/img00a.jpg" alt="Patrizia Rosales y Andrés con nuestro envase cuadrado" onDragStart={handleDragStart} role="presentation" />, width: 'auto' },
    { img: <img src="./images/envases/img2.jpg" alt="Raúl Gonzáles y Andrés con nuestro envase rectangular" onDragStart={handleDragStart} role="presentation" />, width: 'auto' },
    { img: <img src="./images/envases/img3.jpg" alt="Envase triangular" onDragStart={handleDragStart} role="presentation" />, width: 'auto' },
    { img: <img src="./images/envases/img5.jpg" alt="Envase rectangular" onDragStart={handleDragStart} role="presentation" />, width: 'auto' },
    { img: <img src="./images/envases/img6.jpg" alt="Envase ecológico" onDragStart={handleDragStart} role="presentation" />, width: 'auto' },
    { img: <img src="./images/envases/tipoEnvase1.JPG" alt="Envases con tomate" onDragStart={handleDragStart} role="presentation" />, width: 'auto' },
    { img: <img src="./images/envases/tipoEnvase2.JPG" alt="Pilas de envases" onDragStart={handleDragStart} role="presentation" />, width: 'auto' }
  ];

  const items = carouselItems.map((item, index) => {
    let style = {width: item.width};
    return (<div className="item" style={style}>{item.img}</div>);
  })

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  const callToActions = [
    {title: "Rey Raf", description: "Conoce como la tercera generación de una familia de agricultoras ha conseguido hacer del tomate Raf, el rey de los tomates.", label: "Empresa", textLink: "Conócenos", link: "/empresa", figure: <GiFarmer/>},
    { title: "Tomate Raf", description: "Historia, características, variedades, temporada y todo lo que necesitas saber sobre el tomate Raf.", label: "Nuestro Producto", textLink: "Descúbrelo", link: "/productos",  figure: <RiSeedlingLine/>},
    { title: "Entorno", description: "Conoce nuestro entorno y descubre porque se dan las condiciones ideales para la crianza de este excepcional producto.", label: "Parque Natural y ruta etnográfica", textLink: "Explora", link: "/entorno", figure: <RiRoadMapLine />}
  ]

  return (
    <React.Fragment>
      <Headline title="Packaging" subtitle="Envases y otros productos"/>
      <HeroImage src="./images/envases/hero-envase.jpg" alt="Caja estándar 3-5 kg"/>
      <Brief title="Diseño exclusivo y patentado por Rey Raf" description={["Disponemos de distintos formatos en los que ofrecemos nuestro producto: la caja estándar y los estuches de madera.", "Sorprenda regalando un producto original y exclusivo, con un sabor que será un recuerdo inolvidable para los sentidos."]}/>
      <section className='section section--inverse'>
        <div className="wrapper">
          <TitleSection title="Envases" description={["Envío 100% seguro con nuestras cajas. Mantienen el producto fresco para que llegue en perfecto estado de la huerta a su mesa.", "De la huerta a su casa en 14 horas."]} />
        </div>
        <div className="wrapper">
          <div className="section__content">
            <Feature icon={<BsBoxSeam />} title="Caja Estándar" description="Disponemos de cajas de 3 y 5 kg. de tomate Raf. Para adaptarnos a cualquier cliente no disponemos de pedido mínimo. Desde consumo individual hasta regalos de empresa."/>
            <Feature icon={<MdOutlineCases />} title="Estuche de Madera" description=" El producto más especial consiste en un estuche de madera de pino, serigrafiado en letras doradas, que contiene tomate Raf, aceite ecológico (muy bajo en acidez) y sal de las salinas de Cabo de Gata, conocida por su bajo nivel en arcilla. Una de las más cotizadas de Europa." />
            <Feature icon={<RiHandHeartLine />} title="Regale Rey Raf" description="Para un detalle especial y diferente, los estuches Rey Raf pueden conservarse de forma decorativa tras el consumo de los productos por su resistencia y belleza. Se incluyen notas o cartas personales junto al pedido para el destinatario."/>
          </div>
        </div>
        <div className="wrapper">
          <AliceCarousel
            autoWidth
            mouseTracking
            items={items}
            infinite
            //responsive={responsive}
          />
        </div>
      </section>
      <section className="section">
        <div className="wrapper">
          <TitleSection title="¿Quieres conocernos mejor?" description={["Explora y descubre porque en Rey Raf cultivamos el mejor tomate Raf."]}/>
          <CallToActionSection callToActions={callToActions} />
        </div>
      </section>
    </React.Fragment>
  )
}

export default PackagingPage