import React, {useContext} from 'react'
import './CartTotal.css'
import CartContext from './../../context/CartContext'
import {Link} from 'react-router-dom'

function CartTotal() {

  const { totalPrice } = useContext(CartContext);

  return (
    <section className="cartTotal">
      <div className="cartTotal__wrapper">
        <h2 className="cartTotal__text">Total: <span>{totalPrice} €</span></h2>
        <div className="cartTotal__legal"></div>
        <div className="cartTotal__checkout">
          <Link to="/checkout" title="Finalizar compra" className="cartTotal__button">Finalizar Compra</Link>
          <Link to="/tienda" title="Volver a la tienda" className="cartTotal__continue">Continuar Comprando</Link>
        </div>
      </div>
    </section>
  )
}

export default CartTotal