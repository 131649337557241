import React from 'react'
import HeaderAccount from '../../components/Account/HeaderAccount'
import ProfileInline from '../../components/Account/ProfileInline'
import AddressInline from '../../components/Account/AddressInline'
import OrderInline from '../../components/Account/OrderInline'

function DashboardPage() {
  return (
    <React.Fragment>
      <section className="sectionAdmin">
        <div className="wrapper">
          <HeaderAccount />
          <ProfileInline />
          <AddressInline />
          <OrderInline />
        </div>
      </section>
    </React.Fragment>
  )
}

export default DashboardPage