import React, { useState, useEffect, useMemo } from 'react'
import DataTable from 'react-data-table-component';
import { RiFileListLine } from "react-icons/ri";
import OrderService from '../../services/Order/OrderService';
import styled from 'styled-components';
import BillService from '../../services/Bill/BillService';
import ReactPDF, { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer/lib/react-pdf.browser.es.js'
import InvoicePDF from './InvoicePDF';
import ReactDOM from 'react-dom';
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'

const BASE_URL_IMG = process.env.REACT_APP_BASE_URL_IMAGE;

//const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
const ExpandedComponent = ({ data }) => {
  return (
    <React.Fragment>
      <div>
        <div className="cardAccountInline__wrapper">
          <div className="cardAccountInline__header">
            <div className="cardAccountInline__col">
              <div className="cardAccountInline__info">
                <h5 style={{ fontSize: '.825rem' }} className="cardAccountInline__title">Pedido: {data.order}</h5>
                <span className="cardAccountInline__text">Localizador: {data.locator}</span>
                <span className="cardAccountInline__text">Cliente: {data.customerFormatted}</span>
                <span className="cardAccountInline__text">Observaciones: {data.observations}</span>
              </div>
            </div>
            <div className="cardAccountInline__colActions">
              <strong>Total: {data.total}</strong>
            </div>
          </div>
          <hr className="cardAccountInline__hr" />
          <div style={{ marginTop: '1.25rem' }} className="cardAccountInline__info">
            <strong style={{ fontSize: '.825rem' }} className="cardAccountInline__title">Dirección envío: </strong>
            <span className="cardAccountInline__text">{data.address_send}</span>
          </div>
          <div className="cardAccountInline__info">
            <strong style={{ fontSize: '.825rem' }} className="cardAccountInline__title">Dirección facturación:</strong>
            <span className="cardAccountInline__text">{data.address_billing}</span>
          </div>
          <hr className="cardAccountInline__hr" />
          <div className="cardAccountInline__body">
            {data.lines.map((line, key) =>
              <div key={key} className="cardAccountInline__row">
                <div className="cardAccountInline__col">
                  <div className="cardAccountInline__img">
                    <img src={BASE_URL_IMG + line.product_image} alt={line.product_name} />
                  </div>
                  <div className="cardAccountInline__info">
                    <h5 className="cardAccountInline__title">{line.product_name}</h5>
                    <span className="cardAccountInline__text">{line.product_description}</span>
                    <span className="cardAccountInline__text">{line.product_price} € x {line.amount_line}</span>
                  </div>
                </div>
              </div>)}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const noDataComponent = "No hay compras para mostrar";

const paginationComponentOptions = {
  rowsPerPageText: 'Filas por página',
  rangeSeparatorText: 'de',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Todos',
};

const customStyles = {
  rows: {
    style: {
      '&:hover': {
        backgroundColor: 'rgba(0,189,142,.1) !important',
      },
    }
  }
}

const columns = [
  {
    name: 'Pedido',
    selector: row => row.order,
    sortable: true,
    maxWidth: '120px'
  },
  {
    name: 'Cliente',
    selector: row => row.customer,
    omit: true
  },
  {
    name: 'Cliente',
    selector: row => row.customerFormatted,
    sortable: true
  },
  {
    name: 'Localizador',
    selector: row => row.locator,
    omit: true
  },
  {
    name: 'Localizador',
    selector: row => row.locatorFormatted,
    sortable: true,
    maxWidth: '120px'
  },
  {
    name: 'Estado',
    selector: row => row.status,
    omit: true
  },
  {
    name: 'Estado',
    selector: row => row.statusFormatted,
    sortable: true,
    omit: true
  },
  {
    name: 'Enviado',
    selector: row => row.send,
    omit: true
  },
  {
    name: 'Enviado',
    selector: row => row.sendFormatted,
    sortable: true,
    maxWidth: '100px'
  },
  {
    name: 'Fecha',
    selector: row => row.date,
    sortable: true,
    maxWidth: '120px'
  },
  {
    name: 'Método pago',
    selector: row => row.pay_method,
    sortable: true
  },
  {
    name: 'Total',
    selector: row => row.total,
    sortable: true,
    maxWidth: '100px'
  },
  {
    name: 'Acciones',
    selector: row => row.actions,
    sortable: false
  }
];

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const SelectField = styled.select`
  height: 36px;
  width: 150px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
  margin-inline-start: .5rem;
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #008060;
  color: white;
  border: 0;
`;


const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <React.Fragment>
    <TextField
      id="search"
      type="text"
      placeholder="Filtrar por localizador"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter} />
    <ClearButton ClearButton type="button" onClick={onClear} title="Limpiar filtro">
      X
    </ClearButton >
  </React.Fragment>
);

export default function OrderAdminInline() {
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [filterText, setFilterText] = useState('');
  const [filterSend, setFilterSend] = useState(0);
  const [filterStatus, setFilterStatus] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const filterTable = () => {
    setFilteredItems(data.filter(
      item => ((item.locator && item.locator.toLowerCase().includes(filterText.toLowerCase()))
        && (item.send == filterSend || filterSend === "todos")
        && (item.status === filterStatus || filterStatus === "")
      )
    ))
  }

  useEffect(() => {
    filterTable();
  }, [filterSend, filterText, filterStatus,data])

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

  const onChangeSend = (e) => {
    setFilterSend(e.target.value);
  }

  const onChangeStatus = (e) => {
    setFilterStatus(e.target.value)
  }

  return (
      <React.Fragment>
        <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        <SelectField id="send" name="send" onChange={onChangeSend} value={filterSend}>
          <option value="todos">Todos (enviados y sin enviar)</option>
          <option value="1">Enviados</option>
          <option value="0">Sin enviar</option>
        </SelectField>
        <SelectField id="status" name="status" onChange={onChangeStatus} value={filterStatus}>
          <option value="">Todos</option>
          <option value="PAID">Pagados</option>
          <option value="PENDING">Pendientes</option>
        </SelectField>
      </React.Fragment>
    );
  }, [filterText, resetPaginationToggle, filterSend, filterStatus]);

  useEffect(() => {
    getOrders();
  }, [])

  const getOrders = async () => {
    await OrderService.getAll().then(
      response => {
        let orders = [];
        response.content.orders.map((order, k) => {
          let orderObject = prepareOrder(order, k);
          orders.push(orderObject);
        });
        setData(orders);
        setTotalOrders(orders.length)
        setIsLoading(false);
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setIsLoading(false);
        setMessage(resMessage);
      }
    );
  }

  const prepareOrder = (order, k) => {

    if (order === null)
      return {};

    return {
      "id": order.id,
      "order": <React.Fragment><strong>{order.serie + order.number}</strong>{translateStatus(order.status)}</React.Fragment>,
      "customer": order.user_name+";"+order.user_email+";"+order.user_phone,
      "customerFormatted": <React.Fragment><strong>{order.user_name}</strong><br />{order.user_email}<br/>{order.user_phone}</React.Fragment>,
      "locator": order.locator,
      "locatorFormatted": <strong>{order.locator}</strong>,
      "status": order.status,
      "statusFormatted": translateStatus(order.status),
      "send": order.send,
      "sendFormatted": translateSend(order.send_text),
      "address_send": order.user_address,
      "address_billing": order.user_billing_address,
      "date": formatter.format(Date.parse(order.created_at)),
      "pay_method": translatePayMethod(order.pay_method),
      "total": <strong>{order.total_price + '€'}</strong>,
      "lines": order.lines,
      "actions": getAction(order),
      "name": order.user_name,
      "phone": order.user_phone,
      "email": order.user_email,
      "street": order.address_street,
      "town": order.address_town,
      "zip": order.address_zip,
      "city": order.address_city,
      "cif": order.user_cif,
      "order_serie_number": order.serie + order.number,
      "observations": order.observations
    }
  }

  const translatePayMethod = (pay_method) => {
    switch (pay_method) {
      case "PAYPAL":
        return "Paypal";
      case "CREDIT_CARD":
        return "Tarjeta de crédito";
      default:
        return "Contrareembolso"
    }
  }

  const translateStatus = (status) => {
    switch (status) {
      case "PAID":
        return <span className="badge badge--success">{"Pagado"}</span>;
      default:
        return <span className="badge badge--pending">{"Pendiente"}</span>;
    }
  }

  const translateSend = (send) => {
    if (send === "send")
      return <span className="badge badge--success">{"Sí"}</span>;

    return <span className="badge badge--alert">{"No"}</span>;
  }

  const getAction = (order) => {
    if (order.status !== "PAID") {
      return <button
        onClick={() => markAsPaid(order.id)}
        title='Marcar como pagado'
        className="cardAccountInline__button cardAccountInline__button--mini cardAccountInline__button--danger">Marcar como pagado</button>
    }

    return <PDFDownloadLink
      document={<InvoicePDF order={order}/>}
      fileName={"Pedido " + order.serie + order.number + ".pdf"}
      className="cardAccountInline__button cardAccountInline__button--mini cardAccountInline__button--info"
    >
      {({ blob, url, loading, error }) =>
        loading ? "Cargando..." : "Generar pedido"
      }
    </PDFDownloadLink>;
  }

  const markAsPaid = async (orderId) => {
    setIsLoading(true);
    setMessage("");
    await OrderService.pay(null, orderId, 0).then(
      responnse => {
        getOrders();
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setIsLoading(false);
        setMessage(resMessage);
      }
    )
  }

  const markAsSend = async () => {
    setIsLoading(true);
    setMessage("");

    let ids = [];
    selectedRows.forEach((order, key) => {
      ids.push(order.id);
    })
    await OrderService.send(ids).then(
      responnse => {
        getOrders();
        setToggleCleared(true);
        setSelectedRows([]);
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setIsLoading(false);
        setMessage(resMessage);
      }
    )
  }

  const formatter = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "2-digit"
  });

  const handleSelected = React.useCallback(state => {
    setSelectedRows(state.selectedRows);
    //console.log('Selected Rows: ', selectedRows);
  }, []);

  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UT-8";
  const fileExtension = ".xlsx";

  const exportToExcel = async () => {
    let dataExcel = prepareDataExcel(selectedRows);
    const ws = XLSX.utils.json_to_sheet(dataExcel);
    const wb = {Sheets: {'data': ws}, SheetNames: ['data']}
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
    const data = new Blob([excelBuffer], {type: fileType})
    FileSaver.saveAs(data, "Excel pedidos" + fileExtension)
    markAsSend();
  }

  const prepareDataExcel = (orders) => {
    let dataExcel = [];
    if (orders.length === 0)
      return dataExcel;

    orders.forEach((order, key) => {
      order.lines.forEach((line, key) => {
        let orderExcel = {
          "Fecha": order.date,
          "Nombre": order.name,
          "Direccion": order.street,
          "Localidad": order.town,
          "C. Postal": order.zip,
          "Provincia": order.city,
          "Teléfono": order.phone,
          "Email": order.email,
          "CIF": order.cif,
          "Dirección Facturación": order.address_billing,
          "Localizador": order.locator,
          "Pedido": order.order_serie_number,
          "Código": line.product_code,
          "Artículo": line.product_name,
          "Cantidad": line.amount_line,
          "Precio Artículo": line.product_price,
          "Descripción Artículo": line.product_description,
          "Forma Pago": order.pay_method,
          "Importe": line.product_price * line.amount_line,
          "Observaciones": order.observations,
        }
        dataExcel.push(orderExcel);
      })
    })

    return dataExcel;
  }

  return (
    <React.Fragment>
      <div className="cardAccountInline">
        <div className="cardAccountInline__wrapper">
          <div className="cardAccountInline__header">
            <div className="cardAccountInline__col">
              <div className="cardAccountInline__icon">
                <RiFileListLine />
              </div>
              <div className="cardAccountInline__info">
                <h5 className="cardAccountInline__title">Pedidos</h5>
                <span className="cardAccountInline__text">Total: {totalOrders} compras</span>
              </div>
            </div>
            <div className="cardAccountInline__colActions">
              {selectedRows && selectedRows.length > 0
                &&
                  <React.Fragment>
                  <button className="cardAccountInline__button" onClick={e => exportToExcel()}>Exportar</button>
                    {/* <ExportExcel excelData={""} fileName={"Excel mensajería"} title='Dar de alta una nueva dirección' className="cardAccountInline__button">Exportar excel mensajería</ExportExcel> */}
                  </React.Fragment>}
            </div>
          </div>
          <hr className="cardAccountInline__hr" />
          {message && (
            <div className="cardAccount__formGroup">
              <div className="cardAccount__alert cardAccount__alert--danger" role="alert">
                {message}
              </div>
            </div>
          )}
          <div className="cardAccountInline__body">
            {isLoading && (<span className="spinner-border"></span>)}
            {!isLoading &&
              <DataTable
                columns={columns}
                data={filteredItems}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                pagination
                highlightOnHover
                striped
                paginationComponentOptions={paginationComponentOptions}
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                customStyles={customStyles}
                selectableRows
                onSelectedRowsChange={handleSelected}
                clearSelectedRows={toggleCleared}
                noDataComponent={noDataComponent }/>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}