import React from 'react';
import Card from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import TPVService from '../../services/Order/TPVService';
import OrderService from '../../services/Order/OrderService';

import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData
} from "./PaymentFormUtils";


export default class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number: "",
      name: "",
      expiry: "",
      cvc: "",
      issuer: "",
      focused: "",
      formData: null,
      cart: props.cart,
      message: "",
      alert: "",
      isLoading: false,
      removeCart: props.removeCart
    };
  }
  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };

  handleInputFocus = e => {
    this.setState({
      focused: e.target.name
    });

    this.activeLabel(e)
  };

  handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }

    this.setState({ [target.name]: target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { issuer } = this.state;
    const formData = [...e.target.elements]
      .filter(d => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value;
        return acc;
      }, {});

    this.setState({ formData });
    this.createOrder(this.state.cart);
    // this.form.reset();
  };

  createOrder = async (cart) => {
    this.setState({isLoading: true});
    this.setState({ alert: true });
    await OrderService.create(cart).then(
      response => {
        this.setState({ message: "" });
        this.setState({ isLoading: false });
        this.setState({ alert: "success" });
        let order = response.content.order
        TPVService.generateParametersTPV(this.state.number, this.state.name, this.state.expiry, this.state.cvc, order.locator, order.total_price).then(
          response => {
            let parameter = response.content.parameter;
            // TPVService.pay(parameter).then(
            //   response => {
            //     console.log(response);
            //     //this.state.removeCart();
            //   }
            // )
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.setState({ message: resMessage });
            this.setState({ isLoading: false });
            this.setState({ alert: "danger" });
          }
        )

        //if (order.status === "PAID") {
        //navigate("/compra-satisfactoria/" + order.id + "/" + order.locator, { replace: true })
        //}
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.setState({ message: resMessage });
        this.setState({ isLoading: false });
        this.setState({ alert: "danger" });
      }
    );
  }

  inactiveLabel = e => {
    let listLabel = document.querySelectorAll("label");

    listLabel.forEach(e => {
      if (e.nextSibling.value === "")
        e.classList.remove("activeLabel");
    })
  }

  activeLabel = e => {
    let listLabel = document.querySelectorAll("label");

    listLabel.forEach(e => {
      if (e.nextSibling.value === "")
        e.classList.remove("activeLabel");
    })

    const currentLabel = e.currentTarget.previousSibling;
    currentLabel.classList.add("activeLabel");
  }

  render() {
    const { name, number, expiry, cvc, focused, issuer, formData } = this.state;

    return (
      <div key="Payment">
        <div className="App-payment">
          <Card
            number={number}
            name={name}
            expiry={expiry}
            cvc={cvc}
            focused={focused}
            callback={this.handleCallback}
          />
          <form ref={c => (this.form = c)} onSubmit={this.handleSubmit} style={{marginTop: "1rem"}}>
            <div className="checkoutStep__targets">
              <div>
                <div className="checkoutStepTarget__form">
                  <div className="checkoutStepTarget__formColumn">
                    <div className="cardAccount__formGroup">
                      <label htmlFor="number" className="cardAccount__label">Número tarjeta</label>
                      <input
                        type="tel"
                        name="number"
                        className="cardAccount__input"
                        pattern="[\d| ]{16,22}"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                        onBlur={this.inactiveLabel}
                      />
                    </div>
                    {/* <small>E.g.: 49..., 51..., 36..., 37...</small> */}
                  </div>
                  <div className="checkoutStepTarget__formColumn">
                    <div className="cardAccount__formGroup">
                      <label htmlFor="name" className="cardAccount__label">Nombre titular</label>
                      <input
                        type="text"
                        name="name"
                        className="cardAccount__input"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                        onBlur={this.inactiveLabel}
                      />
                    </div>
                  </div>
                  <div className="checkoutStepTarget__formColumn">
                    <div className="cardAccount__formGroup">
                      <label htmlFor="name" className="cardAccount__label">Fecha validez</label>
                      <input
                        type="tel"
                        name="expiry"
                        className="cardAccount__input"
                        pattern="\d\d/\d\d"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                        onBlur={this.inactiveLabel}
                      />
                    </div>
                  </div>
                  <div className="checkoutStepTarget__formColumn">
                    <div className="cardAccount__formGroup">
                      <label htmlFor="name" className="cardAccount__label">CVC</label>
                      <input
                        type="tel"
                        name="cvc"
                        className="cardAccount__input"
                        pattern="\d{3,4}"
                        required
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                        onBlur={this.inactiveLabel}
                      />
                    </div>
                  </div>
                  <input type="hidden" name="issuer" value={issuer} />
                  {this.state.message && (
                    <div className="cardAccount__formGroup">
                      {this.state.alert && this.state.alert === "danger" && (
                        <div className="cardAccount__alert cardAccount__alert--danger" role="alert">
                          {this.state.message}
                        </div>)}
                    </div>
                  )}
                  <div className="checkoutStepTarget__formColumn">
                    <button className="checkoutStepTarget__formSubmit">Pagar</button>
                  </div>
                </div>
            </div>
            </div>
          </form>
          {/* {formData && (
            <div className="App-highlight">
              {formatFormData(formData).map((d, i) => (
                <div key={i}>{d}</div>
              ))}
            </div>
          )} */}
        </div>
      </div>
    );
  }
}