export default function AuthHeader() {
  const token = JSON.parse(localStorage.getItem("token"));

  if (!token) {
    return {};
  }

  return {Authorization: "Bearer " + token};
}

export function AuthHeaderFile() {
  const token = JSON.parse(localStorage.getItem("token"));

  if (!token) {
    return {};
  }

  return { Authorization: "Bearer " + token, "Content-Type": "multipart/form-data" };
}
