import React, {useState} from 'react'
import './VideoSection.css'
import { FaPlayCircle } from 'react-icons/fa'
//import 'node_modules/react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video'

function VideoSectionRuta() {

  const [isOpen, setOpen] = useState(false)

  return (
    <React.Fragment>
      <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="8r5PB8Cffk0" onClose={() => setOpen(false)} />
      <section className="video">
        <div className="video__wrapper">
          <div className="video__link">
            <a href="#video" onClick={() => setOpen(true)}>
              <div className="videoLink__banner">
                <img src="./images/environment/ruta-etnografica.jpg" alt="Ruta etnográfica" className="video__image" />
                <div className="videoBanner__caption">
                  <div className="videoBanner__icon">
                    <span className="videoIcon">
                      <FaPlayCircle />
                    </span>
                  </div>
                  <div className="videoBanner__title">Ver vídeo sobre ruta etnográfica</div>
                </div>
              </div>
            </a>
          </div>
          <div className="video__text">
            <div className="videoText__wrapper">
              <figure className="videoText__blockquote">
                <blockquote className="videoBlockquote">"Recomendamos hacer la visita guiada a la finca Rey Raf dónde conoceremos el origen, cultivo, recolección y venta del tomate Raf."</blockquote>
                <blockquote className="videoBlockquote__footer">
                  <span className="videoBlockquoteFooter__source">Ayuntamiento de Níjar</span>
                </blockquote>
                <img src="./images/product/nijar.png" alt="Logo Ayuntamiento de Níjar" className="videoText__avatar" />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default VideoSectionRuta