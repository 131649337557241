import React from "react";
import Login from './../../components/Account/Login'
import {Link} from 'react-router-dom'
import '../../components/Account/Login.css'

export default function LoginPage() {
  return (
    <React.Fragment>
      <section className="section__top section--green">
        <div className="wrapper__form">
          {/* {this.state.user && (<Navigate to="/dashboard" replace={false} />)} */}
          <div className="login">
            <div className="cardLogin">
              <div className="cardLogin__header">
                <h4 className="cardLogin__title">Iniciar Sesión</h4>
                <p className="cardLogin__text">¿Aún no tienes una cuenta? <Link to="/registro" className="cardLogin__link" title="Crear una cuenta de usuario aquí">Créala aquí</Link></p>
              </div>
              <Login />
            </div>
          </div>
        </div>
      </section>
      <div className="cardLogin__shape">
        <div className="cardLogin__shapeBotton">
          <svg viewBox="0 0 3000 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 1000V583.723L3000 0V1000H0Z" fill="#fff"></path>
          </svg>
        </div>
      </div>
    </React.Fragment>
  );
}