import React, { useState, useEffect } from 'react'
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Link, Navigate, useParams } from 'react-router-dom';
import { isNumeric } from "validator";
import './../Account/Account.css'
import ProductService from "../../services/Product/ProductService";

const BASE_URL_IMG = process.env.REACT_APP_BASE_URL_IMAGE;

const requireField = value => {
  if (!value) {
    return (
      <span className="cardAccount__invalid">Campo obligatorio.</span>
    );
  }
};

// const numericValue = value => {
//   if (!isNumeric(value)) {
//     <span className="cardAccount__invalid">Debe indicar un valor numérico.</span>
//   }
// }

const limitValid = value => {
  if (!value || value === 0) {
    return (
      <span className="cardAccount__invalid">Campo obligatorio o valor superior a 0.</span>
    );
  }
}

export default function Product(props) {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [amount, setAmount] = useState("");
  const [measure, setMeasure] = useState("");
  const [limit, setLimit] = useState(1);
  const [alert, setAlert] = useState("");
  const [form, setForm] = useState(null);
  const [checkButton, setCheckButton] = useState(null);
  const [mainFile, setMainFile] = useState(null);
  const [files, setFiles] = useState(null);
  const [images, setImages] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    if (!id)
      return;

    ProductService.get(id).then(response => {
      let product = response.content.product;

      setMessage("");
      setName(product.name);
      setDescription(product.description);
      setPrice(product.price);
      setAmount(product.amount);
      setMeasure(product.measure);
      setLimit(product.limit);
      setMainFile(product.mainImage);
      setImages(product.images);
    },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setLoading(false);
        setAlert("danger");
      });
  }, []);

  const onChangeName = (e) => {
    setName(e.target.value);
  }
  const onChangeDescription = (e) => {
    setDescription(e.target.value);
  }
  const onChangePrice = (e) => {
    setPrice(e.target.value);
  }
  const onChangeAmount = (e) => {
    setAmount(e.target.value);
  }
  const onChangeMeasure = (e) => {
    setMeasure(e.target.value);
  }
  const onChangeLimit = (e) => {
    setLimit(e.target.value);
  }
  const onChangeMainFile = e => {
    setMainFile(e.target.files[0]);
  }
  const onChangeFiles = e => {
    setFiles(e.target.files);
  }

  const handleProduct = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);
    form.validateAll();

    let images = [];
    images.push(mainFile);
    if (files !== null) {
      for (let i = 0; i < files.length; i++) {
        images.push(files[i]);
      }
    }

    if (checkButton.context._errors.length === 0) {
      setMessage("");
      ProductService.create({
        name: name,
        description: description,
        price: price,
        amount: amount,
        measure: measure,
        limit: limit,
        files: images
      }).then(
        (response) => {
          setMessage(response.message);
          setLoading(false);
          setAlert("success");
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(resMessage);
          setLoading(false);
          setAlert("danger");
        }
      );
    }
    else {
      setLoading(false);
    }
  }
  const handleUpdateProduct = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    form.validateAll();
    if (checkButton.context._errors.length === 0) {
      setMessage("");
      ProductService.update({
        name: name,
        description: description,
        price: price,
        amount: amount,
        measure: measure,
        limit: limit,
      }, id).then(
        (response) => {
          setMessage(response.message);
          setLoading(false);
          setAlert("success");
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(resMessage);
          setLoading(false);
          setAlert("danger");
        }
      );
    }
    else {
      setLoading(false);
    }
  }
  return (
    <React.Fragment>
      <Form onSubmit={id ? handleUpdateProduct : handleProduct}
        ref={c => {
          setForm(c);
        }}>
        <div className="account">
          <div className="cardAccount">
            <div className="cardAccount__header">
              <h4 className="cardAccount__title">Producto</h4>
            </div>
            <div className="cardAccount__body">
              {message && (
                <div className="cardAccount__formGroup">
                  {alert && alert === "danger" && (
                    <div className="cardAccount__alert cardAccount__alert--danger" role="alert">
                      {message}
                    </div>)}
                  {alert && alert === "success" && (
                    <div className="cardAccount__alert cardAccount__alert--success" role="alert">
                      {message}
                    </div>)}
                </div>
              )}
              <div className="cardAccount__formGroup">
                <label htmlFor="name" className="cardAccount__label">Nombre</label>
                <Input id="name"
                  type="text"
                  className="cardAccount__input"
                  name="name"
                  placeholder="Indique el nombre del producto"
                  value={name}
                  onChange={onChangeName}
                  validations={[requireField]} />
              </div>
              <div className="cardAccount__formGroup">
                <label htmlFor="description" className="cardAccount__label">Descripción</label>
                <Input id="description"
                  type="text"
                  className="cardAccount__input"
                  name="description"
                  placeholder="Indique la descripción del producto"
                  value={description}
                  onChange={onChangeDescription}
                  validations={[requireField]} />
              </div>
              <div className="cardAccount__formGroup">
                <label htmlFor="price" className="cardAccount__label">Precio</label>
                <Input id="price"
                  type="text"
                  className="cardAccount__input"
                  name="price"
                  placeholder="Indique el precio del producto"
                  value={price}
                  onChange={onChangePrice}
                  validations={[requireField]} />
              </div>
              <div className="cardAccount__formGroup">
                <label htmlFor="amount" className="cardAccount__label">Cantidad</label>
                <Input id="amount"
                  type="text"
                  className="cardAccount__input"
                  name="amount"
                  placeholder="Indique la cantidad del producto"
                  value={amount}
                  onChange={onChangeAmount}/>
              </div>
              <div className="cardAccount__formGroup">
                <label htmlFor="measure" className="cardAccount__label">Medidas</label>
                <Input id="measure"
                  type="text"
                  className="cardAccount__input"
                  name="measure"
                  placeholder="Indique las medidas del producto"
                  value={measure}
                  onChange={onChangeMeasure} />
              </div>
              <div className="cardAccount__formGroup">
                <label htmlFor="limit" className="cardAccount__label">Límite</label>
                <Input id="limit"
                  type="number"
                  className="cardAccount__input"
                  name="limit"
                  placeholder="Indique el límite de producto por compra"
                  value={limit}
                  onChange={onChangeLimit}
                  validations={[limitValid]} />
              </div>
              {!id &&
              <React.Fragment>
                <div className="cardAccount__formGroup">
                  <label htmlFor="mainFile" className="cardAccount__label">Imagen principal</label>
                  <Input id="mainFile"
                    type="file"
                    className="cardAccount__input"
                    name="mainFile"
                    onChange={onChangeMainFile}
                    validations={[requireField]} />
                </div>
                <div className="cardAccount__formGroup">
                  <label htmlFor="files" className="cardAccount__label">Imágenes</label>
                  {images
                    && images.length > 0 && images.map((image, index) =>
                      <img key={index} className="cardAccount_img" src={BASE_URL_IMG + image.image} alt={name} />
                  ) }
                  <Input id="files"
                    type="file"
                    className="cardAccount__input"
                    name="files"
                    multiple
                    onChange={onChangeFiles} />
                </div>
              </React.Fragment>
              }
              <div className="cardAccount__formGroup cardAccount__formGroup--right">
                <Link to="/dashboard-admin" className="cardAccount__button cardAccount__button--cancel" title='Cancelar'>
                  Cancelar
                </Link>
                {!id &&
                  <button type="submit" className="cardAccount__button" title='Crear producto'>
                    {loading && (<span className="spinner-border"></span>)}
                    Crear
                  </button>
                }
                {id &&
                  <button type="submit" className="cardAccount__button" title='Actualizar producto'>
                    {loading && (<span className="spinner-border"></span>)}
                    Editar
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
        <CheckButton style={{ display: "none" }}
          ref={c => {
            setCheckButton(c);
          }}
        />
      </Form>
    </React.Fragment>
  );
}