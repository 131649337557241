import React from 'react'
import PolicyHero from './../../components/Texts/PolicyHero'

function GarantiaVentaBienesPage() {
  return (
    <React.Fragment>
      <PolicyHero title="Información sobre las garantías en la venta de bienes de consumo" />
      <section className="section">
        <div className="wrapper">
          <h4 className="policy__title">Bienes regulados por la ley</h4>
          <p className="policy__text">La Ley de Garantías se aplica sobre los bienes muebles de consumo privado, es decir, los bienes de consumo: desde un electrodoméstico hasta un vehículo, pasando por muebles, objetos de todo tipo, incluso obras de arte. Por su propio concepto quedan eliminados los servicios y los bienes inmuebles.</p>
          <p className="policy__text">La Ley excluye las compra-ventas entre particulares.</p>
          <p className="policy__text">Para los bienes de consumo nuevos la garantía será de dos años, mientras que para los productos de segunda mano, la garantía será de un año. Durante los primeros seis meses de garantía de un producto nuevo se presupone que el daño viene de fábrica y el vendedor debe asumir todos los gastos de la reparación, tanto piezas, traslado, como horas de trabajo. El tiempo de la garantía queda en suspenso durante el tiempo que el producto u objeto esté en reparación.</p>
          <h4 className="policy__title">Producto en buen estado</h4>
          <p className="policy__text">La ley considera que un consumidor debe sentirse satisfecho con el producto adquirido si cumple los siguientes requisitos: que el producto se ajuste a la descripción dada por el vendedor y que tenga las cualidades manifestadas a través de una demostración o modelo. También debe servir para lo que está indicado tanto en el libro de instrucciones, como en las indicaciones verbales que haya podido hacer el vendedor o en un vídeo demostrativo. También sirve como uso habitual la publicidad, las indicaciones que aparezcan reflejadas en una etiqueta, o un uso que se desprenda de las propias características del producto. Incluso, si el consumidor ha solicitado un uso especial y el vendedor le ha asegurado que el bien adquirido se lo ofrecerá, así ha de ser. Además, el producto que se compra debe presentar la calidad y el comportamiento adecuado. Así, una olla a presión debe cocinar más deprisa que una marmita tradicional.</p>
          <h4 className="policy__title">Aplicación de la Ley</h4>
          <p className="policy__text">La Ley obliga a los vendedores de bienes de consumo, por una parte, y a los consumidores como destinatarios finales, por otra. Es decir, quedan excluidos los contratos que se realizan entre particulares, ya que la misma solo prevé la compra-venta entre un vendedor profesional y un consumidor.</p>
          <p className="policy__text">Se aplicará siempre que se compre un bien de consumo, esto es, cualquier objeto o producto de consumo privado. Quedan excluidos los bienes adquiridos en una venta judicial (subasta de bienes confiscados). Tampoco están sometidos a esta ley la distribución de agua o gas no envasados para su venta.</p>
          <h4 className="policy__title">Reclamación en caso de fallo en el producto</h4>
          <p className="policy__text">El primer responsable del producto es el vendedor. Sin embargo, el consumidor puede acudir directamente al fabricante o al importador, si acudir al vendedor le supone una carga. Por ejemplo, si durante unas vacaciones lejos de casa se ha adquirido una cámara de fotos digital que no responde a lo ofertado en la tienda, para el consumidor resulta más fácil acudir al fabricante o importador que al establecimiento en el que lo compró.</p>
          <p className="policy__text">En caso de que el producto no responda a las características anunciadas, el consumidor puede optar entre la reparación del bien o su sustitución, salvo que esto resulte imposible o desproporcionado. Si la reparación o sustitución no son posibles, o resultan desproporcionadas, el consumidor puede optar por una rebaja adecuada en el precio o por la resolución del contrato, es decir, la devolución del precio.</p>
          <p className="policy__text">El consumidor no podrá exigir la sustitución cuando se trate de bienes de segunda mano o bienes de imposible sustitución. Por ejemplo, no se puede exigir sustitución si el bien ya no se fabrica o no quedan existencias, si se adquiere un vehículo de segunda mano ni, por la imposibilidad que conlleva, se puede sustituir una obra de arte, una antigüedad o un diseño de ropa exclusivo. La sustitución será desproporcionada cuando se trate de un pequeño defecto de fácil o sencilla reparación. La reparación será desproporcionada cuando sea antieconómica, es decir, más cara la reparación que el valor del bien.</p>
          <h4 className="policy__title">Períodos de reclamación</h4>
          <p className="policy__text">El consumidor debe acudir a denunciar el fallo en un plazo de dos meses desde que lo detectó. En este sentido, si el problema ha aparecido en los seis meses después de la compra del producto, el vendedor debe hacer efectiva la garantía, ya que en ese período de tiempo se da por supuesto que el problema viene de fábrica. Sin embargo, si han pasado esos seis meses, es el consumidor quien debe demostrar que el fallo viene de origen y que no ha sido provocado por un mal uso del producto.</p>
          <p className="policy__text">La Ley establece que durante los seis meses posteriores a la entrega del bien reparado el vendedor responderá de las faltas que motivaron la reparación, presumiéndose que se trata de la misma falta cuando se reproduzcan defectos del mismo origen que los inicialmente reparados. Para poder hacer efectiva esta garantía de la reparación el consumidor debe guardar el comprobante de la reparación y del servicio técnico que, en su día, reparó el producto.</p>
          <h4 className="policy__title">Fallo después de la reparación o sustitución</h4>
          <p className="policy__text">La Ley recoge estas posibilidades: Si el consumidor eligió la sustitución de un producto con fallo, por otro igual, puede solicitar al vendedor la reparación, siempre que no sea desproporcionada, la rebaja en el precio o la devolución del dinero. Por otro lado, si ante un fallo en un producto se eligió la reparación, el consumidor puede exigir un cambio, una rebaja en el precio o la devolución de todo el dinero desembolsado.</p>
          <p className="policy__text">Pero la Ley no especifica ni la cuantía ni el tipo de rebaja en el precio que el vendedor debe hacer al consumidor en caso de que esa sea la opción elegida. Así las dos partes que intervienen en la compra-venta están obligadas a llegar a acuerdos que satisfagan a ambas.</p>
          <h4 className="policy__title">Negación a reparar, rebajar precio o devolver el dinero</h4>
          <p className="policy__text">Si estamos dentro de los seis primeros meses, hay que exigir la reparación y solicitar una Hoja de Reclamaciones e insistir incluso hasta llegar a juicio. Se presume que la falta existía. Pero si los seis primeros meses ya han transcurrido, estamos a la inversa. Es el consumidor quien debe probar que el producto se adquirió con la falta.</p>
          <p className="policy__text">En cualquier caso el consumidor tendrá que negociar y si no está de acuerdo con la rebaja que le ofrece el vendedor, puede acudir a un tasador para determinar el precio del producto tras la reparación y solicitar una rebaja de precio en ese sentido.</p>
          <h4 className="policy__title">Libro de instrucciones y mala instalación</h4>
          <p className="policy__text">Si un consumidor realiza un mal uso de un producto, porque el libro de instrucciones es incorrecto, la ley de garantías protege al consumidor y puede exigir la reparación o la sustitución. Del mismo modo, si el fallo se produce por una mala instalación provocada tanto por las erróneas instrucciones del manual, como por los técnicos enviados por el vendedor, también este es responsable.</p>
          <h4 className="policy__title">En caso de reparación o de traslado a un servicio técnico, ¿qué debe pagar el consumidor?</h4>
          <p className="policy__text">Nada. Durante el período en que es efectiva la garantía el vendedor o productor debe hacerse cargo del coste de los desplazamientos, de las piezas y del tiempo de la reparación. Además, mientras el producto permanezca en el servicio técnico se suspende el tiempo de garantía. Es decir, no corre el reloj. Por otro lado, al margen de exigir la aplicación de la garantía (la reparación, cambio, rebaja de precio o devolución de dinero), el consumidor puede exigir una indemnización por los daños o perjuicios derivados de la avería y el tiempo de reparación, por ejemplo, si un usuario adquiere una nevera y se estropea a la semana de compra, además de solicitar que se haga efectiva la garantía, dicho usuario puede exigir una indemnización por los alimentos que se han estropeado ante el mal funcionamiento del electrodoméstico.</p>
          <h4 className="policy__title">Garantía comercial</h4>
          <p className="policy__text">La garantía comercial es aquella que el fabricante, distribuidor o vendedor dan, y que siempre debe superar a la que ofrece la ley, ya que esta se entiende como un mínimo exigible por el consumidor. También es una herramienta de marketing de las empresas. No obstante, esta garantía debe cumplir ciertos requisitos, como dejar claro a qué se aplica, el objeto o producto que tiene dicha garantía y el nombre y la dirección de quien la ofrece.</p>
        </div>
      </section>
    </React.Fragment>
  )
}

export default GarantiaVentaBienesPage