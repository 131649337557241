import React, {useState} from 'react'
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Link } from 'react-router-dom';
import ImageProductService from "../../services/Product/ImageProductService";

const BASE_URL_IMG = process.env.REACT_APP_BASE_URL_IMAGE;

const requireField = value => {
  if (!value) {
    return (
      <span className="cardAccount__invalid">Campo obligatorio.</span>
    );
  }
};

export default function ProductMainImage(props) {

  const [mainFile, setMainFile] = useState(null);
  const [image, setImage] = useState(BASE_URL_IMG + props.mainImage);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");
  const [form, setForm] = useState(null);
  const [checkButton, setCheckButton] = useState(null);

  const onChangeMainFile = e => {
    setMainFile(e.target.files[0]);
  }

  const handleMainImage = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);
    form.validateAll();

    if (checkButton.context._errors.length === 0) {
      setMessage("");
      ImageProductService.update({
        image: mainFile,
        main_image: 1
      }, props.id).then(
        (response) => {
          setImage(BASE_URL_IMG + response.content.image.image);
          setMainFile(null);
          setMessage(response.message);
          setLoading(false);
          setAlert("success");
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(resMessage);
          setLoading(false);
          setAlert("danger");
        }
      );
    }
    else {
      setLoading(false);
    }
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleMainImage}
        ref={c => {
          setForm(c);
        }}>
        <div className="account">
          <div className="cardAccount">
            <div className="cardAccount__header">
              <h4 className="cardAccount__title">Modificar Imagen Principal</h4>
            </div>
            <div className="cardAccount__body">
              {message && (
                <div className="cardAccount__formGroup">
                  {alert && alert === "danger" && (
                    <div className="cardAccount__alert cardAccount__alert--danger" role="alert">
                      {message}
                    </div>)}
                  {alert && alert === "success" && (
                    <div className="cardAccount__alert cardAccount__alert--success" role="alert">
                      {message}
                    </div>)}
                </div>
              )}
              <div className="cardAccount__formGroup">
                <label htmlFor="mainFile" className="cardAccount__label">Imagen principal</label>
                <img className="cardAccount__img" src={image} alt={props.name} />
                <Input id="mainFile"
                  type="file"
                  className="cardAccount__input"
                  name="mainFile"
                  onChange={onChangeMainFile}
                  validations={[requireField]} />
              </div>

              <div className="cardAccount__formGroup cardAccount__formGroup--right">
                <Link to="/dashboard-admin" className="cardAccount__button cardAccount__button--cancel" title='Cancelar'>
                  Cancelar
                </Link>
                <button type="submit" className="cardAccount__button" title='Cambiar imagen principal'>
                  {loading && (<span className="spinner-border"></span>)}
                  Cambiar imagen principal
                </button>
              </div>
            </div>
          </div>
        </div>
        <CheckButton style={{ display: "none" }}
          ref={c => {
            setCheckButton(c);
          }}
        />
      </Form>
    </React.Fragment>
  )
}