import React, {useEffect, useState} from 'react'
import './../../components/Account/Account.css'
import Product from '../../components/AdminAccount/Product'
import { Link, useParams } from 'react-router-dom'
import ProductService from "../../services/Product/ProductService";
import ProductMainImage from '../../components/AdminAccount/ProductMainImage';
import ProductImages from '../../components/AdminAccount/ProductImages';

function ProductAdminPage() {

  const { id } = useParams();

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");
  const [product, setProduct] = useState(null);

  useEffect(() => {
    if (!id)
      return;

    ProductService.get(id).then(response => {
      let productResponse = response.content.product;

      setMessage("");
      setProduct(productResponse);
    },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setLoading(false);
        setAlert("danger");
      });
  }, [])

  return (
    <React.Fragment>
      <section className="sectionAdmin">
        <div className="wrapper">
          <div className="headerAccount headerAccount--mini">
            <div className="headerAccount__wrapper headerAccount__wrapper--mini">
              <div className="headerAccount__body headerAccount__body--mini">
                <div className="headerAccount__row">
                  <div className="headerAccount__col">
                    <h6 className="headerAccount__subtitle">{id !== undefined ? "Ver/Editar" : "Nuevo"}</h6>
                    <h2 className="headerAccount__title">Producto</h2>
                  </div>
                  <div className="headerAccount__colButtons">
                    <Link to="/dashboard-admin" title='Volver' className="headerAccount__button">Volver</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className='lineAccount' />
          {message && (
            <div className="cardAccount__formGroup">
              {alert && alert === "danger" && (
                <div className="cardAccount__alert cardAccount__alert--danger" role="alert">
                  {message}
                </div>)}
              {alert && alert === "success" && (
                <div className="cardAccount__alert cardAccount__alert--success" role="alert">
                  {message}
                </div>)}
            </div>
          )}
          <Product />
          {product !== null &&
            <React.Fragment>
              <hr className='lineAccount' />
              <ProductMainImage mainImage={product.mainImage.image} id={product.mainImage.id}/>
              <hr className='lineAccount' />
              <ProductImages idProduct={product.id} images={product.images}/>
            </React.Fragment>
          }
        </div>
      </section>
    </React.Fragment>
  )
}

export default ProductAdminPage