import React, {useState, useContext} from 'react'
import CartAddressList from './CartAddressList'
import CartAddressForm from './CartAddressForm'
import CartAddressBillingForm from './CartAddressBillingForm'
import CartInfoUserForm from './CartInfoUserForm'
import UserContext from './../../context/UserContext'
import { FiCheck } from "react-icons/fi";
import { useEffect } from 'react';

export default function CartAddress({ showSectionAccount, showSectionAddress, setShowSectionAddress, userCart, addressCart, setAddressCart, addressBillingCart, setAddressBillingCart, setUserCart, addressIndependent, setAddressIndependent, setPayMethodCart }) {

  const { currentUser } = useContext(UserContext)
  const [listAddress, setListAddress] = useState([]);
  const [anotherAddress, setAnotherAddress] = useState(false);
  const [checkSameBillingAddress, setCheckSameBillingAddress] = useState(true);
  const [handleValidAddressForm, setHandleValidAddressForm] = useState(0);
  const [handleValidAddressBillingForm, setHandleValidAddressBillingForm] = useState(0);
  const [handleValidUserForm, setHandleValidUserForm] = useState(0)

  useEffect(() => {
    if (!showSectionAccount)
      setShowSectionAddress(addressCart === "" || addressBillingCart === "" || !userCart.isValid);
  }, [addressCart, addressBillingCart, userCart])

  useEffect(() => {
    let address = getSelectAddressList();
    if (address) {
      setAnotherAddress(false);
    }
  }, [listAddress])

  const changeAddress = () => {
    setAddressCart("")
    setAddressBillingCart("")
    setPayMethodCart("");
  }

  // Another Address

  const handleAnotherAddress = () => {
    restartListAddress();
    setAnotherAddress(true);
  }

  // List Address

  const restartListAddress = () => {
    let addresses = [...listAddress];
    addresses.map((a, keyAd) => {
      return a.selected = false;
    });
    setListAddress(addresses);
  }

  const getSelectAddressList = () => {
    return listAddress.find(a => a.selected === true);
  }

  const showBillingAddress = () => {
    setCheckSameBillingAddress(!checkSameBillingAddress);
  }

  // Handle Select Address

  const selectAddress = () => {
    if (!currentUser) {
      setHandleValidUserForm(current => current + 1);
    }

    if (!checkSameBillingAddress) {
      setHandleValidAddressBillingForm(current => current + 1)
    }

    let address = getSelectAddressList();
    if (address !== null && address !== undefined) {
      if (checkSameBillingAddress) {
        setAddressBillingCart(address.text)
      }
      setAddressCart(address.text);
      return;
    }

    if (anotherAddress || !currentUser) {
      setHandleValidAddressForm(current => current + 1);
    }
  }

  return (
    <section data-name="section-address" className={showSectionAddress ? "checkoutStep checkoutStep__open" : "checkoutStep"}>
      <div className="checkoutStep__main">
        <div className="checkStep__content">
          <div className="checkoutStep__col">
            <h2 className="checkoutStep__title">2. Envío</h2>
          </div>
          <div className="checkoutStep__col--right">
            {!showSectionAddress && <span data-name="edit-address" className="checkoutStep__action" onClick={changeAddress}>Cambiar dirección</span>}
          </div>
        </div>
        {addressCart && addressBillingCart && <div className="checkoutStep__description"><FiCheck /> {addressCart}</div>}
      </div>
      <div className="checkoutStep__content">
        {currentUser && <CartAddressList listAddress={listAddress} setListAddress={setListAddress}
                              anotherAddress={anotherAddress} setAnotherAddress={setAnotherAddress}
                              addressIndependent={addressIndependent} setAddressIndependent={setAddressIndependent} />}
        <div className="checkoutStepTarget__form">
          {!currentUser && <CartInfoUserForm handleValidUserForm={handleValidUserForm} userCart={userCart} setUserCart={setUserCart}/>}
        </div>
        {currentUser &&
          <div className="checkoutStepTarget__formColumn">
            {!anotherAddress && <a href="#link" onClick={handleAnotherAddress} className="checkoutStep__action checkoutStep__action--active">Indicar otra dirección</a>}
          </div>
        }
        {(anotherAddress || !currentUser) && <CartAddressForm handleValidAddressForm={handleValidAddressForm} setAddressCart={setAddressCart} checkSameBillingAddress={checkSameBillingAddress} setAddressBillingCart={setAddressBillingCart} addressIndependent={addressIndependent} setAddressIndependent={setAddressIndependent} />}
        <input type="checkbox" name="sameBillingAddress" onChange={() => showBillingAddress()} checked={checkSameBillingAddress} /><label for="sameBillingAddress">Utilizar como dirección de facturación</label>
        {!checkSameBillingAddress && <CartAddressBillingForm handleValidAddressBillingForm={handleValidAddressBillingForm} setAddressBillingCart={setAddressBillingCart}/>}
        <div className="checkoutStepTarget__formColumn">
          <button className="checkoutStepTarget__formSubmit" onClick={() => selectAddress()}>Proceder a pago</button>
        </div>
      </div>
    </section>
  )
}