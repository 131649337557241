import React, {useContext, useEffect, useState} from 'react'
import TitleSection from '../../components/Texts/TitleSection'
import CardsScroll from '../../components/Cards/CardsScroll'
import CardProductDetail from '../../components/Cards/CardProductDetail'
import StoreContext from './../../context/StoreContext'
import { useNavigate, useParams } from 'react-router-dom'

function ShopDetailPage() {

  const { storeStatus, products } = useContext(StoreContext);
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  let navigate = useNavigate();
  const { slug } = useParams();

  useEffect(() => {
    if (!storeStatus || storeStatus === 'Close' || !slug)
      navigate("/", { replace: true })

    let productFilter = products.filter(p => p.slug === slug);

    if (productFilter && productFilter.length === 1)
      setProduct(productFilter[0])
    else
      navigate("/", { replace: true })
  }, [products]);

  useEffect(() => {
    if (product)
      setIsLoading(false);
    //console.log(product)
  }, [product]);

  if (isLoading)
    return '';

  return (
    <React.Fragment>
      <CardProductDetail product={product}/>
      {/* <section className="section">
        <div className="wrapper">
          <TitleSection title="Sigue comprando"/>
          <CardsScroll />
        </div>
      </section> */}
    </React.Fragment>
  )
}

export default ShopDetailPage