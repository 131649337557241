import React, {useContext} from 'react'
import './Footer.css'
import { RiTruckFill, RiFacebookCircleFill, RiInstagramFill } from 'react-icons/ri'
import { Link } from 'react-router-dom';
import StoreContext from './../../context/StoreContext'

function Footer() {

  const { storeStatus, isLoading } = useContext(StoreContext);

  let year = new Date().getFullYear();

  return (
    <React.Fragment>
      <section className="section">
        <div className="wrapper">
          <div className="bannerFooter">
            <div className="bannerFooter__wrapper">
              <div className="bannerFooter__figure">
                <img className="bannerFooter__img" src="/images/colaboraciones/envio-12.png" alt="Envíos en 12 horas" />
              </div>
              <div className="bannerFooter__figure">
                <img className="bannerFooter__img" src="/images/colaboraciones/natural.png" alt="100% Natural" />
              </div>
              <div className="bannerFooter__figure">
                <img className="bannerFooter__img" src="/images/reyraf/corona-reyraf.jpg" alt="Corona Rey Raf" />
              </div>
              <div className="bannerFooter__figure">
                <img className="bannerFooter__img" src="/images/reyraf/moneda.jpeg" alt="Moneda Rey Raf" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className='footer'>
        <div className="wrapper">
          <div className='footer__main'>
            <div className="footer__mainContent">
              <div className="footer__column">
                <div className="footer__groupText">
                  <img src="/images/reyraf/quinta-corona-footer.jpg" alt="Ángeles Gimenez Arias, el alma de Rey Raf." />
                  <h6 className="footer__title">5º Corona<br /> El alma de Rey Raf</h6>
                </div>
                <p className="footer__text">Gracias a nuestros padres y abuelos... sin ellos nada de esto hubiera sido posible. Rey Raf y sus cinco ingredientes para lograr un tomate con sabor a tomate.</p>
              </div>
              <div className="footer__column">
                <h6 className="footer__title">Rey Raf</h6>
                <ul className="footer__menu">
                  <li className="footer__list">
                    {(!storeStatus || storeStatus === 'Close') ?
                      <Link to="#tiendacerrada" title="Tienda cerrada" className="footer__link footer__link--close">Tienda cerrada</Link> :
                      <Link to="/tienda" title="Disfrute de todos nuestros productos" className="footer__link">Tienda</Link>
                    }
                  </li>
                  <li className="footer__list">
                    <Link to="/empresa" title="Conoce más a Rey Raf" className="footer__link">Empresa</Link>
                  </li>
                  <li className="footer__list">
                    <Link to="/productos" title="Saborea el mejor tomate Raf" className="footer__link">Productos</Link>
                  </li>
                  <li className="footer__list">
                    <Link to="/envases" title="Descubre nuestros formatos de envases" className="footer__link">Envases</Link>
                  </li>
                  <li className="footer__list">
                    <Link to="/entorno" title="Explora nuestro entorno" className="footer__link">Entorno</Link>
                  </li>
                </ul>
              </div>
              <div className="footer__column">
                <h6 className="footer__title">Contacto</h6>
                <ul className="footer__menu">
                  <li className="footer__list">
                    <a href="http://goo.gl/maps/N2Af5" target="_blank" rel="noreferrer" className="footer__link">El Hornillo de Los Martínez - Níjar <br /> 04116 - Almería - España </a>
                  </li>
                  <li className="footer__list">
                    <a href="tel:950600354" className="footer__link">950 600 354</a> - <a href="tel:850995052" className="footer__link">850 995 052</a>
                  </li>
                  <li className="footer__list">
                    <a href="mailto:info@reyraf.es" className="footer__link">info@reyraf.es</a>
                  </li>
                </ul>
                <h6 className="footer__title">Social</h6>
                <ul className="footer__menu">
                  <li className="footer__list">
                    <a href="https://www.facebook.com/reyrafsl/" target="_blank" title="Visite nuestro facebook" className="footer__link" rel="noreferrer"><RiFacebookCircleFill size="1.75rem" /></a>
                    <a href="https://www.instagram.com/reyraf5coronas/?hl=es" target="_blank" title="Visite nuestro instagram" className="footer__link" rel="noreferrer"><RiInstagramFill size="1.75rem" /></a>
                  </li>
                </ul>
              </div>
              <div className="footer__column">
                <h6 className="footer__title">Legal</h6>
                <ul className="footer__menu">
                  <li className="footer__list">
                    <Link to="/aviso-legal" className="footer__link" title="Leer el aviso legal">Aviso Legal</Link>
                  </li>
                  <li className="footer__list">
                    <Link to="/politica-privacidad" className="footer__link" title="Leer la política de privacidad">Política de Privacidad</Link>
                  </li>
                  <li className="footer__list">
                    <Link to="/politica-cookies" title="Leer las políticas de cookies" className="footer__link">Política de Cookies</Link>
                  </li>
                  <li className="footer__list">
                    <Link to="/condiciones-generales-contrato" title="Leer las condiciones generales de contratación" className="footer__link">Condiciones generales de contratación</Link>
                  </li>
                  <li className="footer__list">
                    <Link to="/garantia-venta-bienes" title="Leer las garantías en la venta de bienes" className="footer__link">Información sobre las garantías en la venta de bienes de consumo</Link>
                  </li>
                  <li className="footer__list">
                    <Link to="/derecho-desistimiento" title="Leer el derecho de desistimiento" className="footer__link">Derecho de desistimiento</Link>
                  </li>
                  <li className="footer__list">
                    <p className="footer__text">REY RAF Cinco Coronas, S.L.U. <br /> CIF.: B-04768123<br />Nº Registro Sanitario: 21027394 AL</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer__line"></div>
          <div className='footer__legal'>
            <div className="footer__legalContent">
              <div className="footer__legalText">
                <RiTruckFill size='1.25rem' /> De la huerta a su mesa. Envíos en 12 horas.
              </div>
              <div className='footer__legalCopy'>
                {year} © Rey Raf - Todos los derechos reservados
              </div>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default Footer