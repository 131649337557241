import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import './HeaderAccount.css'
import AuthService from '../../services/Authentication/AuthService'
import UserContext from './../../context/UserContext'
import Login from './../../pages/Authentication/LoginPage'

export default function HeaderAccount() {

  const { currentUser, Logout } = useContext(UserContext);

  const logout = () => {
    Logout();
  }

  return (
    <React.Fragment>
      <div className="headerAccount">
        <div className="headerAccount__wrapper">
          <div className="headerAccount__body">
            <div className="headerAccount__row">
              <div className="headerAccount__col">
                <h6 className="headerAccount__subtitle">Bienvenido</h6>
                <h2 className="headerAccount__title">{currentUser !== null ? currentUser.name : ''}</h2>
              </div>
              <div className="headerAccount__colButtons">
                <Link to="/login" title='Cerrar Sesión' className="headerAccount__button" onClick={logout}>Cerrar Sesión</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}