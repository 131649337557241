import React from 'react'
import './Partners.css'

function Partners() {
  return (
    <React.Fragment >
    <div className="partners">
      <div className="partners__wrapper">
        <div className="partners__figure">
          <img className="partners__img" src="./images/colaboraciones/marca_andalucia.JPG" alt="Parque Natural - Marca Andalucía" />
        </div>
        <div className="partners__figure">
          <img className="partners__img" src="./images/colaboraciones/fitur.jpg" alt="Fitur" />
        </div>
        <div className="partners__figure">
          <img className="partners__img" src="./images/colaboraciones/expo-agro.jpg" alt="Expo Agro Almería" />
        </div>
        <div className="partners__figure">
          <img className="partners__img" src="./images/colaboraciones/ferial.jpg" alt="Ferial" />
        </div>
        <div className="partners__figure">
          <img className="partners__img" src="./images/colaboraciones/gourmet.jpg" alt="Salón Gourmet" />
        </div>
        <div className="partners__figure">
          <img className="partners__img" src="./images/colaboraciones/uda.jpg" alt="Unión Deportiva Almería" />
        </div>
        <div className="partners__figure">
          <img className="partners__img" src="./images/colaboraciones/almeria-voley.jpg" alt="Almería Voley" />
        </div>
        <div className="partners__figure">
          <img className="partners__img" src="./images/colaboraciones/cdnijar.jpg" alt="Club Deportivo Níjar" />
        </div>
        <div className="partners__figure">
          <img className="partners__img" src="./images/colaboraciones/asisa.jpg" alt="Asisa" />
        </div>
        <div className="partners__figure">
          <img className="partners__img" src="./images/colaboraciones/frutas-cinco.jpg" alt="5 frutas al día" />
        </div>
        <div className="partners__figure">
          <img className="partners__img" src="./images/colaboraciones/ruta-etnografica.jpg" alt="Ruta Etnográfica" />
        </div>
        <div className="partners__figure">
          <img className="partners__img--little" src="./images/colaboraciones/indalo.png" alt="Indalo" />
        </div>
      </div>
    </div>
    </React.Fragment>
  )
}

export default Partners