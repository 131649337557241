import React, { useRef, useEffect } from 'react'
import './MainHero.css'
import { gsap, Power2 } from 'gsap'
//import CSSRulePlugin from 'gsap/CSSRulePlugin';

function MainHero() {

  //gsap.registerPlugin(CSSRulePlugin)

  let mainImage = useRef(null);
  let mainContainer = useRef(null);
  //let mainImageContent = CSSRulePlugin.getRule(".mainHero__container:after");

  const timelineHeroImage = gsap.timeline();

  useEffect(() => {
    //console.log(mainImageContent);
    if (!mainImage || !mainContainer) // || !mainImageContent)
      return;

    timelineHeroImage.to(mainContainer, 0, { css: { visibility: "visible" } });
    //timelineHeroImage.to(mainImageContent, 1.4, { width: "0%", ease: Power2.easeInOut });
    timelineHeroImage.from(mainImage, 1.4, {
      scale: 1.6,
      ease: Power2.easeInOut,
      delay: -1.4
    });
  });

  const timeline = gsap.timeline();

  useEffect(() => {
    const subtitle = document.querySelectorAll('.mainHero__text');
    const title = document.querySelectorAll('.mainHero__title');
    timeline
      .from(subtitle, { opacity: 0, y: 50, duration: 1, stagger: .2 })
      .from(title, { opacity: 0, y: 50, duration: 1.2, stagger: .2 }, "-=.75")
  });

  return (
    <section className="mainHero">
      <span className="mainHero__dark"></span>
      <div className="mainHero__container" ref={el => (mainContainer = el)}>
        <div className="mainHero__content">
          <div className="mainHero__wrapper">
            <p className="mainHero__text">El rey de los tomates</p>
            <h1 className="mainHero__title">Rey Raf</h1>
          </div>
        </div>
      </div>
      <picture className="mainHero__picture">
        <img className="mainHero__image" src="./images/home/hero-home.jpg" alt="Rey Raf, el rey de los tomates" ref={el => { mainImage = el }} />
      </picture>
    </section>
  )
}

export default MainHero