import React from 'react'
import './Blockquote.css'
import { FaPlayCircle } from 'react-icons/fa'

function Blockquote() {
  return (
  <section className="blockquote">
    <div className="blockquote__wrapper">
      <div className="blockquote__link">
        <div className="blockquoteLink__banner">
          <img src="./images/product/tomate_blockquote.jpg" alt="Fotografía Tomates Raf" className="blockquote__image" />
          {/* <div className="blockquoteBanner__caption">
            <div className="blockquoteBanner__icon">
              <span className="blockquoteIcon">
                <FaPlayCircle />
              </span>
            </div>
            <div className="blockquoteBanner__title">Ver programa Aquí La Tierra</div>
          </div> */}
        </div>
      </div>
      <div className="blockquote__text">
        <div className="blockquoteText__wrapper">
          <figure className="blockquoteText__cite">
              <blockquote className="blockquoteCite">“La magia de una agricultura que el sol ilumina día tras día para reflejar, bajo plástico, el rojo de su apreciado tomate Raf, motor económico nijareño. Recurso que se convierte en placer una vez en la cesta, o ya aliñado en alguna de sus concurridas tabernas del casco antiguo.”</blockquote>
              <blockquote className="blockquoteCite__footer">
                <span className="blockquoteCiteFooter__source">Ayuntamiento de Níjar</span>
            </blockquote>
            <img src="./images/product/nijar.png" alt="Logo Ayuntamiento Níjar" className="blockquoteText__avatar" />
          </figure>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Blockquote