import axios from "axios";
import AuthHeader, { AuthHeaderFile } from "../Authentication/AuthHeader";
const API_URL = process.env.REACT_APP_BASE_URL_API + "admin/image-product";

const create = async (image) => {
  const { data } = await axios.post(API_URL, image, { headers: AuthHeaderFile() })
  return data;
}

const update = async (image, id) => {
  const { data } = await axios.post(API_URL + "/update/" + id, image, { headers: AuthHeaderFile() })
  return data;
}

const destroy = async (id) => {
  const { data } = await axios.delete(API_URL + "/" + id, { headers: AuthHeader() })
  return data;
}

export default { create, destroy, update}