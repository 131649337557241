import React, { useState, useEffect } from 'react'
import AddressService from "../../services/User/AddressService";

export default function CartAddressBillingForm({ handleValidAddressBillingForm, setAddressBillingCart }) {

  const [streetBillingAddress, setStreetBillingAddress] = useState("")
  const [flatAndGateBillingAddress, setFlatAndGateBillingAddress] = useState("")
  const [zipBillingAddress, setZipBillingAddress] = useState("")
  const [townBillingAddress, setTownBillingAddress] = useState("")
  const [cityBillingAddress, setCityBillingAddress] = useState("")

  useEffect(() => {
    handleValidForm()
  }, [handleValidAddressBillingForm])

  const onChangeStreetBillingAddress = (e) => {
    let input = document.querySelector(`input[name="streetBillingAddress"]`);
    if (e.target.value === "") {
      input.style.border = "1px solid red";
    }
    else {
      input.removeAttribute("style");
    }

    setStreetBillingAddress(e.target.value);
  }
  const onChangeFlatAndGateBillingAddress = (e) => {
    setFlatAndGateBillingAddress(e.target.value);
  }
  const onChangeZipBillingAddress = (e) => {
    let input = document.querySelector(`input[name="zipBillingAddress"]`);
    if (e.target.value === "") {
      input.style.border = "1px solid red";
    }
    else {
      input.removeAttribute("style");
    }

    setZipBillingAddress(e.target.value);
  }
  const onChangeTownBillingAddress = (e) => {
    let input = document.querySelector(`input[name="townBillingAddress"]`);
    if (e.target.value === "") {
      input.style.border = "1px solid red";
    }
    else {
      input.removeAttribute("style");
    }

    setTownBillingAddress(e.target.value);
  }
  const onChangeCityBillingAddress = (e) => {
    let input = document.querySelector(`select[name="cityBillingAddress"]`);
    if (e.target.value === "") {
      input.style.border = "1px solid red";
    }
    else {
      input.removeAttribute("style");
    }

    setCityBillingAddress(e.target.value);
  }

  const handleValidForm = () => {
    let isValid = true;

    if (streetBillingAddress === "") {
      let inputStreetBillingAddress = document.querySelector(`input[name="streetBillingAddress"]`);
      inputStreetBillingAddress.style.border = "1px solid red";
      isValid = false;
    }

    if (zipBillingAddress === "") {
      let inputZipBillingAddress = document.querySelector(`input[name="zipBillingAddress"]`);
      inputZipBillingAddress.style.border = "1px solid red";
      isValid = false;
    }

    if (townBillingAddress === "") {
      let inputTownBillingAddress = document.querySelector(`input[name="townBillingAddress"]`);
      inputTownBillingAddress.style.border = "1px solid red";
      isValid = false;
    }

    if (cityBillingAddress === "") {
      let inputCityBillingAddress = document.querySelector(`select[name="cityBillingAddress"]`);
      inputCityBillingAddress.style.border = "1px solid red";
      isValid = false;
    }

    if (isValid) {
      let address = flatAndGateBillingAddress ? streetBillingAddress + " " + flatAndGateBillingAddress + " " + zipBillingAddress + " " + townBillingAddress + " " + cityBillingAddress : streetBillingAddress + " " + zipBillingAddress + " " + townBillingAddress + " " + cityBillingAddress;
      setAddressBillingCart(address);
    }
  }

  return (
    <React.Fragment>
      <div className="cardAccount__header">
        <h4 className="cardAccount__title">Dirección de facturación</h4>
      </div>
      <div className="cardAccount__body">
        <div className="cardAccount__formGroup">
          <label htmlFor="streetBillingAddress" className="cardAccount__label">Dirección</label>
          <input id="streetBillingAddress"
            type="text"
            className="cardAccount__input"
            name="streetBillingAddress"
            placeholder="Indique la dirección (calle, avenida...)"
            value={streetBillingAddress}
            onChange={onChangeStreetBillingAddress}
          />
        </div>
        <div className="cardAccount__formGroup">
          <label htmlFor="flatAndGateBillingAddress" className="cardAccount__label">Piso, puerta u otro</label>
          <input id="flatAndGateBillingAddress"
            type="text"
            className="cardAccount__input"
            name="flatAndGateBillingAddress"
            placeholder="Indique el piso, portal, puerta u otro"
            value={flatAndGateBillingAddress}
            onChange={onChangeFlatAndGateBillingAddress} />
        </div>
        <div className="cardAccount__formGroup">
          <label htmlFor="zipBillingAddress" className="cardAccount__label">Código postal</label>
          <input id="zipBillingAddress"
            type="text"
            className="cardAccount__input"
            name="zipBillingAddress"
            placeholder="Indique el código postal"
            value={zipBillingAddress}
            onChange={onChangeZipBillingAddress} />
        </div>
        <div className="cardAccount__formGroup">
          <label htmlFor="townBillingAddress" className="cardAccount__label">Localidad</label>
          <input id="townBillingAddress"
            type="text"
            className="cardAccount__input"
            name="townBillingAddress"
            placeholder="Indique la localidad o ciudad"
            value={townBillingAddress}
            onChange={onChangeTownBillingAddress}
          />
        </div>
        <div className="cardAccount__formGroup">
          <label htmlFor="cityBillingAddress" className="cardAccount__label">Provincia</label>
          <select id="cityBillingAddress" className="cardAccount__input" name="cityBillingAddress" onChange={onChangeCityBillingAddress} value={cityBillingAddress}>
            <option value="">Elija una provincia</option>
            {AddressService.cities() && AddressService.cities().map((key, value) => <option value={key} key={value}>{key}</option>)}
          </select>
        </div>
      </div>
    </React.Fragment>
  )
}