import React, {useState} from 'react'
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { Link, useNavigate } from 'react-router-dom';
import './../../components/Account/Login.css'
import AuthService from '../../services/Authentication/AuthService';

const emailValid = value => {
  if (!value || !isEmail(value)) {
    return (
      <span className="cardLogin__invalid">Por favor, introduzca un email válido.</span>
    );
  }
}

const passwordValid = value => {
  if (!value) {
    return (
      <span className="cardLogin__invalid">Su password es inválido. Por favor, pruebe de nuevo.</span>
    );
  }
};

const requiredValid = value => {
  if (!value) {
    return (
      <span className="cardLogin__invalid">Campo obligatorio. Por favor, introduzca un valor.</span>
    )
  }
}

export default function RegisterPage() {

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [cif, setCif] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [form, setForm] = useState("")
  const [checkButton, setCheckButton] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [alert, setAlert] = useState("danger");
  const [hideForm, setHideForm] = useState(false);

  const onChangeName = (e) => {
    setName(e.target.value);
  }

  const onChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const onChangePhone = (e) => {
    setPhone(e.target.value)
  }

  const onChangeCif = (e) => {
    setCif(e.target.value)
  }

  const onChangePassword = (e) => {
    setPassword(e.target.value)
  }

  const onChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value)
  }

  const handleRegister = (e) => {
    e.preventDefault();
    setMessage("")
    setIsLoading(true)
    form.validateAll();
    if (checkButton.context._errors.length === 0) {
      if (password !== confirmPassword) {
        setMessage("Deben coincidir las contraseñas.")
        setIsLoading(false)
        setAlert("danger")
        return;
      }
      setMessage("")
      AuthService.register(name, email, phone, cif, password, confirmPassword).then(
        response => {
          setAlert("success")
          setIsLoading(false)
          setMessage("Revise su email y confirme su cuenta.")
          setHideForm(true);
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setIsLoading(false)
          setMessage(resMessage)
          setAlert("danger")
        }
      )
    }
    else {
      setIsLoading(false)
    }
  }

  return (
    <React.Fragment>
      <section className="section__top section--green">
        <div className="wrapper__form">
          {/* {this.state.user && (<Navigate to="/dashboard" replace={false} />)} */}
          <div className="login">
            <div className="cardLogin">
              <div className="cardLogin__header">
                <h4 className="cardLogin__title">Registro de usuario</h4>
                <p className="cardLogin__text">¿Ya tienes cuenta? <Link to="/login" className="cardLogin__link" title="Iniciar sesión">Inicia sesión aquí</Link></p>
                {message && (
                  <div className="cardLogin__formGroup" style={{ marginTop: '2rem' }}>
                    {alert && alert === "danger" &&
                      <div className="cardLogin__invalid cardLogin__invalidAlert" role="alert">
                        {message}
                      </div>}
                    {alert && alert === "success" &&
                      <div className="cardLogin__invalid cardLogin__invalidSuccess" role="alert">
                        {message}
                      </div>}
                  </div>
                )}
              </div>
              {!hideForm && <Form onSubmit={handleRegister}
                ref={c => {
                  setForm(c);
                }}>
                <div className="cardLogin__body">
                  <div className="cardLogin__formGroup">
                    <label htmlFor="name" className="cardLogin__label">Nombre</label>
                    <Input id="name"
                      type="text"
                      className="cardLogin__input"
                      name="name"
                      placeholder="Indique su nombre y apellidos"
                      value={name}
                      onChange={onChangeName}
                      validations={[requiredValid]} />
                  </div>
                  <div className="cardLogin__formGroup">
                    <label htmlFor="email" className="cardLogin__label">Email</label>
                    <Input id="email"
                      type="email"
                      className="cardLogin__input"
                      name="email"
                      placeholder="Indique su email"
                      value={email}
                      onChange={onChangeEmail}
                      validations={[emailValid]} />
                  </div>
                  <div className="cardLogin__formGroup">
                    <label htmlFor="phone" className="cardLogin__label">Teléfono</label>
                    <Input id="phone"
                      type="text"
                      className="cardLogin__input"
                      name="phone"
                      placeholder="Indique su teléfono"
                      value={phone}
                      onChange={onChangePhone}
                      validations={[requiredValid]} />
                  </div>
                  <div className="cardLogin__formGroup">
                    <label htmlFor="cif" className="cardLogin__label">Documento de identificación</label>
                    <Input id="cif"
                      type="text"
                      className="cardLogin__input"
                      name="cif"
                      placeholder="Indique su documento de identificación (cif, dni...)"
                      value={cif}
                      onChange={onChangeCif}
                      validations={[requiredValid]} />
                  </div>
                  <div className="cardLogin__formGroup">
                    <label htmlFor="password" className="cardLogin__label">Contraseña</label>
                    <Input id="password"
                      type="password"
                      className="cardLogin__input"
                      name="password"
                      placeholder="Indique su contraseña"
                      value={password}
                      onChange={onChangePassword}
                      validations={[passwordValid, requiredValid]} />
                  </div>
                  <div className="cardLogin__formGroup">
                    <label htmlFor="confirmPassword" className="cardLogin__label">Confirmar contraseña</label>
                    <Input id="confirmPassword"
                      type="password"
                      className="cardLogin__input"
                      name="confirmPassword"
                      placeholder="Indique su contraseña"
                      value={confirmPassword}
                      onChange={onChangeConfirmPassword}
                      validations={[passwordValid, requiredValid]} />
                  </div>
                  <div className="cardLogin__formGroup">
                    <button type="submit" className="cardLogin__button">
                      {isLoading && <span className="spinner-border"></span>}
                      Registrar usuario
                    </button>
                  </div>
                </div>
                <CheckButton style={{ display: "none" }}
                  ref={c => {
                    setCheckButton(c);
                  }}
                />
              </Form>}
            </div>
          </div>
        </div>
      </section>
      <div className="cardLogin__shape">
        <div className="cardLogin__shapeBotton">
          <svg viewBox="0 0 3000 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 1000V583.723L3000 0V1000H0Z" fill="#fff"></path>
          </svg>
        </div>
      </div>
    </React.Fragment>
  )
}
