import React, {useContext, useEffect} from 'react'
import TitleSection from '../../components/Texts/TitleSection'
import CardsList from '../../components/Cards/CardsList'
import StoreContext from './../../context/StoreContext'
import { useNavigate } from 'react-router-dom'

function ShopPage() {

  const { storeStatus } = useContext(StoreContext);
  let navigate = useNavigate();

  useEffect(() => {
    if (!storeStatus || storeStatus === 'Close')
      navigate("/", {replace: true})
  }, [])

  return (
    <section className="section">
      <div className="wrapper">
        <TitleSection title="¿Quieres conocernos mejor?" description={["Explora y descubre porque en Rey Raf cultivamos el mejor tomate Raf."]} />
        <CardsList />
      </div>
    </section>
  )
}

export default ShopPage