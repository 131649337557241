import React from 'react'
import { Link } from 'react-router-dom'
import AuthService from "../../services/Authentication/AuthService";
import { RiUserSettingsLine } from "react-icons/ri"
import './CardInline.css'

export default function ProfileInline() {
  return (
    <React.Fragment>
      <div className="cardAccountInline">
        <div className="cardAccountInline__wrapper">
          <div className="cardAccountInline__header">
            <div className="cardAccountInline__col">
              <div className="cardAccountInline__icon">
                <RiUserSettingsLine />
              </div>
              <div className="cardAccountInline__info">
                <h5 className="cardAccountInline__title">Perfil</h5>
                <span className="cardAccountInline__text">Actualizar perfil, modificar contraseña...</span>
              </div>
            </div>
            <div className="cardAccountInline__colActions">
              <Link to="/dashboard/perfil" title='Ver perfil' className="cardAccountInline__button">Ver</Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}