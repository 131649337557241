import React, { useEffect, useRef } from 'react'
import './Feature.css'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Link } from 'react-router-dom'

function Feature(props) {

  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  useEffect(() => {
    const feature = ref.current;
    gsap.fromTo(
      feature.querySelector(".feature__icon"),
      {
        opacity: 0,
        y: -40,
        duration: 1.5
      },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: feature.querySelector(".feature__icon"),
          //markers: true,
          start: "top 85%",
          end: "bottom 80px",
          scrub: false
        }
      }
    );
  }, [])

  useEffect(() => {
    const feature = ref.current;
    gsap.fromTo(
      feature.querySelector(".feature__title"),
      {
        opacity: 0,
        y: -40,
        duration: 1.5
      },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: feature.querySelector(".feature__icon"),
          //markers: true,
          start: "top 85%",
          end: "bottom 80px",
          scrub: false
        }
      }
    );
  }, [])

  useEffect(() => {
    const feature = ref.current;
    gsap.from(
      feature.querySelectorAll(".feature__description"),
      {
        opacity: 0,
        y: -30,
        duration: 1.2,
        stagger: .2,
        scrollTrigger: {
          trigger: feature.querySelector(".feature__icon"),
          //markers: true,
          start: "top 85%",
          end: "bottom 80px",
          scrub: false
        }
      }
    );
  }, [])

  return (
    <div className='feature' ref={ref}>
      {
        props.icon !== undefined &&
        <span className="feature__icon">
          {props.icon}
        </span>
      }
      {
        props.image !== undefined &&
        <span className="feature__image">
            {props.image}
        </span>
      }
      <div className="feature__text">
        <h4 className="feature__title">
          {props.title}
        </h4>
        <p className="feature__description">
          {props.description}
        </p>
        {
          props.link !== undefined &&
          <Link className="feature__link" to={props.link} title={props.linkTitle}>{props.linkText}</Link>
        }
      </div>
    </div>
  )
}

export default Feature