import React from 'react'
import Headline from '../../components/Texts/Headline'
import HeroTextImage from '../../components/Galleries/HeroTextImage'
import Brief from '../../components/Texts/Brief'
import InfoSection from '../../components/Texts/InfoSection'
import { FiChevronRight } from "react-icons/fi";
import TitleSection from '../../components/Texts/TitleSection'
import Feature from '../../components/Texts/Feature'
import { GiWaspSting } from 'react-icons/gi'
import { IoIosBug, IoIosSnow } from 'react-icons/io'
import { RiHandHeartLine, RiPlaneLine } from 'react-icons/ri'
import HeroMosaic from '../../components/Galleries/HeroMosaic'
import CallToActionSection from '../../components/Cards/CallToActionSection'
import { RiSeedlingLine, RiRoadMapLine } from "react-icons/ri";
import { GiFarmer } from "react-icons/gi";
import CardBanner from '../../components/Cards/CardBanner'
import { RiShoppingBag3Fill } from "react-icons/ri";
import { Link } from 'react-router-dom'
import { BsBoxSeam } from "react-icons/bs";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './../../components/Galleries/AliceCarousel.css';
import './../../components/Galleries/AliceCarouselBig.css';
import ParallaxComposition from '../../components/Galleries/ParallaxComposition'

function CompanyPage() {

  const infoSectionFeatures = [
    { title: <React.Fragment>Sembraban en la calle <FiChevronRight /></React.Fragment>, description: "Hace cuatro décadas, los padres y abuelos de Andrés Piedra, trabajaban la agricultura al aire libre; dependiendo del tiempo, de las heladas y de la lluvia. Sembraban en calle, con cañas y al aire libre. Fue una época muy dura, donde no había infraestructuras.", figure: <RiHandHeartLine /> }
  ]

  const callToActions = [
    { title: "Tomate Raf", description: "Historia, características, variedades, temporada y todo lo que necesitas saber sobre el tomate Raf.", label: "Nuestro Producto", textLink: "Descúbrelo", link: "/productos", figure: <RiSeedlingLine /> },
    { title: "Envases", description: "Descubre nuestra caja ecológica, más confortable y resistente, además de ser bonita y personalizada, es positiva para el medio ambiente.", label: "Packaging", textLink: "Innovamos", link: "/envases", figure: <BsBoxSeam /> },
    { title: "Entorno", description: "Conoce nuestro entorno y descubre porque se dan las condiciones ideales para la crianza de este excepcional producto.", label: "Parque Natural y ruta etnográfica", textLink: "Explora", link: "/entorno", figure: <RiRoadMapLine /> }
  ]

  const handleDragStart = (e) => e.preventDefault();

  const carouselItems = [
    { img: <img src="./images/company/company1.jpg" alt="Primera generación Rey Raf recolectando el producto" onDragStart={handleDragStart} role="presentation" />, width: 'auto'},
    { img: <img src="./images/company/company2.jpg" alt="Familia Rey Raf" onDragStart={handleDragStart} role="presentation" />, width: 'auto' },
    { img: <img src="./images/company/company3.jpg" alt="Fotos de Andrés y Josefa" onDragStart={handleDragStart} role="presentation" />, width: 'auto' },
    { img: <img src="./images/company/company4.jpg" alt="Foto de Ángeles Giménez Arias" onDragStart={handleDragStart} role="presentation" />, width: 'auto' },
    { img: <img src="./images/company/company5.jpg" alt="Foto de Francisco Piedra Giménez" onDragStart={handleDragStart} role="presentation" />, width: 'auto' },
    { img: <img src="./images/company/company6.jpg" alt="Foto de la futura generación de Rey Raf" onDragStart={handleDragStart} role="presentation" />, width: 'auto' },
    { img: <img src="./images/company/manos-abuelo.jpg" alt="Foto de las manos del abuelo de Andrés" onDragStart={handleDragStart} role="presentation" />, width: 'auto' },
    { img: <img src="./images/company/img3.jpg" alt="Foto con Unicaja" onDragStart={handleDragStart} role="presentation" />, width: 'auto' },
  ];

  const items = carouselItems.map((item, index) => {
    let style = { width: item.width };
    return (<div className="item" style={style}>{item.img}</div>);
  })

  return (
    <React.Fragment>
      <Headline title="Rey Raf 5 Coronas" subtitle="Empresa" />
      <HeroTextImage title="Toda la experiencia y consejos de nuestros abuelos" titleStrong="trasladado al raf del Siglo XXI." subtitle="Tradición e innovación" imgSrc="./images/company/hero-empresa.jpg" imgAlt="Plato de tomate Raf cortado" />
      <Brief
        title="Tradición y trabajo"
        description={
          ["Rey Raf es una empresa con una gran tradición en el cultivo del tomate Raf, somos la tercera generación de una familia de agricultores.",
           "Lo que caracteriza a nuestra empresa, es que nos gusta cuidar mucho los detalles. Cada tomate pasa por nuestras manos, mediante un proceso artesanal y ecológico."]} />
      <section className="section">
        <div className="wrapper">
          <InfoSection
            subtitle="Tradición"
            title="Un poco de historia"
            description={["Una fórmula única y tradicional llevada hasta nuestros días por una familia trabajadora, que recogió la experiencia y sabiduría de sus padres y abuelos para otorgar valor añadido al oficio \"que vieron hacer en su casa\".", "Su abuela aún se muestra orgullosa al ver cómo ha cambiado lo que fue, a mucha menor escala, una forma de supervivencia."]}
            infoSectionFeatures={infoSectionFeatures}
            image="./images/environment/img8.png"
            imageAlt="Antiguas generaciones de rey raf cultivando" />
        </div>
      </section>
      <section className="section">
        <AliceCarousel
          autoWidth
          // autoPlay
          // autoPlayStrategy="none"
          // autoPlayInterval={1000}
          disableDotsControls
          disableButtonsControls
          mouseTracking
          items={items}
          infinite
        //responsive={responsive}
        />
      </section>
      {/* <HeroMosaic
        image1="./images/company/company1.jpg" altImage1="Primera generación Rey Raf recolectando el producto"
        image2="./images/company/company2.jpg" altImage2="Familia Rey Raf"
        image3="./images/company/company3.jpg" altImage3="Fotos de Andrés y Josefa"
        image4="./images/company/company4.jpg" altImag4="Foto de Ángeles Giménez Arias"
        image5="./images/company/company5.jpg" altImage5="Foto de Francisco Piedra Giménez"
        image6="./images/company/company6.jpg" altImage6="Foto de la futura generación de Rey Raf"
      /> */}
      <section className="section">
        <div className="wrapper">
          <TitleSection title="El Raf del Siglo XXI" description={["Nuestro objetivo como compañía es seguir mejorando y ser competitivos apostando por un proceso totalmente ecológico para ofrecer a nuestros clientes el mejor producto. Por eso invertimos un gran esfuerzo en innovación y mejoras en las infraestructuras, la lluvia y climatología puede afectar de forma negativa a este tipo de tomate."]} />
          <div className="section__content section--start">
            <Feature icon={< GiWaspSting />} title="Polinización" description="La polinización de la flor del tomate mediante abejorros sueltos por los invernaderos polinizan la flor, añadiendo estiércol de ganado." />
            <Feature icon={<IoIosBug />} title="Protección" description="Para proteger nuestras plantas ecológicas utilizamos la lucha biológica con insectos que las protegen de forma natural." />
            <Feature icon={<IoIosSnow />} title="Heladas" description="Para evitar las heladas en invierno, usamos calefacción en nuestros invernaderos para mimar nuestro producto y protegerlo de todos los factores meteorológicos que pudieran dañarlo." />
          </div>
          <div className="section__content section--start">
            <Feature icon={<RiHandHeartLine />} title="Recolecta" description="Cuando llega el momento de la recolecta, seleccionamos cada tomate a diario, uno a uno, para hacerles llegar la mejor calidad de tomate raf en su casa. Y así conseguir la calidad del pata negra de los tomates." />
            <Feature icon={<RiPlaneLine />} title="Envíos" description="La recolecta y envío se realizan a diario, de forma que el tomate llega fresco de la huerta a su mesa, con envío urgente y cuidadoso. Realizamos envíos nacionales." />
            <Feature icon={<img src="./images/logo/tomate-raf.svg" alt="Tomate Raf" />} title="Tomate Raf" description="El tomate Rey raf es como un fruto, como un postre, dulce y sabroso que se puede tomar a cualquier hora, un tentempié energético y sano." />
          </div>
        </div>
      </section>
      <ParallaxComposition />
      {/* <section className="section">
        <div className="wrapper">
          <CardBanner figure={<RiShoppingBag3Fill />} text="Visite nuestra tienda y disfrute de todos nuestros productos." button={<React.Fragment><Link to="/tienda" title="Ver todos los productos">Ir a tienda</Link></React.Fragment>} />
        </div>
      </section> */}
      <section className="section">
        <div className="wrapper">
          <TitleSection title="¿Quieres conocernos mejor?" description={["Explora y descubre porque en Rey Raf cultivamos el mejor tomate Raf."]} />
          <CallToActionSection callToActions={callToActions} />
        </div>
      </section>
    </React.Fragment>
  )
}

export default CompanyPage