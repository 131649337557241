import axios from "axios";
import AuthHeader from "../Authentication/AuthHeader";
const API_URL = process.env.REACT_APP_BASE_URL_API + "user";

const update = async (name, email, phone, cif, userId) => {
  const {data} = await axios.put(API_URL + "/" + userId, {
    name: name,
    email: email,
    phone: phone,
    cif: cif
  }, { headers: AuthHeader() });

  return data;
}

export default {update};