import React, {Component} from 'react'
import './ParallaxComposition.css'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const images = [
  './images/composition/composicion5.jpeg',
  './images/composition/composicion4.jpeg',
  './images/composition/composicion3.jpeg',
  './images/composition/composicion1.jpeg',
  './images/composition/composicion2.jpeg',
  './images/composition/composicion6.jpeg',
  './images/composition/composicion7.jpeg',
  './images/composition/composicion8.jpeg',
  './images/composition/composicion9.jpeg',
  './images/composition/composicion10.jpeg',
  './images/composition/composicion11.jpeg',
  './images/composition/composicion12.jpeg',
  './images/composition/composicion13.jpeg',
  './images/composition/composicion14.jpeg',
  './images/composition/composicion15.jpeg',
  './images/composition/composicion16.jpeg',
  './images/composition/composicion17.jpeg',
  './images/composition/composicion18.jpeg',
  './images/composition/composicion19.jpeg',
  './images/composition/composicion20.jpeg',
  './images/composition/composicion21.jpeg',
  './images/composition/composicion26.jpeg',
  './images/composition/composicion22.jpeg',
  './images/composition/composicion23.jpeg',
  './images/composition/composicion25.jpeg',
  './images/composition/composicion24.jpeg'
];

export default class ParallaxComposition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }

  render(){
    const { photoIndex, isOpen } = this.state;

    return (
      <React.Fragment>
        <section className="parallax" style={{ backgroundImage: "url(/images/reyraf/parallax.jpg)" }}>
          <h2 className="parallax__title">Nuestra historia en fotos</h2>
          <button type="button" title="Ver galería" className="parallax__link" onClick={() => this.setState({isOpen:true})}>Ver galería</button>
        </section>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </React.Fragment>
    )
  }

}

