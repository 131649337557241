import React, { useEffect, useRef, useLayoutEffect } from 'react'
import './TitleSection.css'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

function TitleSection(props) {

  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  useEffect(() => {
    ScrollTrigger.refresh();
  }, []);

  useEffect(() => {
    const headSection = ref.current;

    if (!headSection || !headSection.querySelector(".section__title") || !headSection.querySelector(".section__description"))
      return;

    gsap.fromTo(
      headSection.querySelector(".section__title"),
      {
        opacity: 0,
        y: -40,
        duration: 1.5
      },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: headSection.querySelector(".section__title"),
          //markers: true,
          start: "top 85%",
          end: "bottom 80px",
          scrub: false
        }
      }
    );

    gsap.from(
      headSection.querySelectorAll(".section__description"),
      {
        opacity: 0,
        y: -30,
        duration: 1.2,
        stagger: .2,
        scrollTrigger: {
          trigger: headSection.querySelector(".section__description"),
          //markers: true,
          start: "top 85%",
          end: "bottom 80px",
          scrub: false
        }
      }
    );
  }, [])

  return (
    <div className='section__head' ref={ref}>
      <h3 className={props.important == false || props.important == undefined ? 'section__title' : 'section__title section__title--green'}>{props.title}</h3>
      {
        props.description !== undefined && props.description.length > 0 &&
          props.description.map((descp, i) =>
            <p className={props.important == false || props.important == undefined ? 'section__description' : 'section__description section__description--important'} key={i}>{descp}</p>
          )
      }
    </div>
  )
}

export default TitleSection