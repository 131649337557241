import React, { useState } from 'react'
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Link } from 'react-router-dom';
import ImageProductService from "../../services/Product/ImageProductService";

const BASE_URL_IMG = process.env.REACT_APP_BASE_URL_IMAGE;

const requireField = value => {
  if (!value) {
    return (
      <span className="cardAccount__invalid">Campo obligatorio.</span>
    );
  }
};

export default function ProductImages(props) {

  const [images, setImages] = useState(props.images);
  const [files, setFiles] = useState(null);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");
  const [form, setForm] = useState(null);
  const [checkButton, setCheckButton] = useState(null);

  const onChangeFiles = e => {
    setFiles(e.target.files);
  }

  const onHandleRemoveImage = (id) => {
    setMessage("");
    setLoading(true);

    ImageProductService.destroy(id).then(
      response => {
        setImages(response.content.images);
        setMessage(response.message);
        setLoading(false);
        setAlert("success");
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setLoading(false);
        setAlert("danger");
      }
    )
  }

  const handleAddImages = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);
    form.validateAll();

    let images = [];
    for (let i = 0; i < files.length; i++) {
      images.push(files[i]);
    }

    if (checkButton.context._errors.length === 0) {
      setMessage("");
      ImageProductService.create({
        id_product: props.idProduct,
        files: images
      }).then(
        (response) => {
          setImages(response.content.images);
          setMessage(response.message);
          setLoading(false);
          setAlert("success");
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(resMessage);
          setLoading(false);
          setAlert("danger");
        }
      );
    }
    else {
      setLoading(false);
    }
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleAddImages}
        ref={c => {
          setForm(c);
        }}>
        <div className="account">
          <div className="cardAccount">
            <div className="cardAccount__header">
              <h4 className="cardAccount__title">Listado Imágenes</h4>
            </div>
            <div className="cardAccount__body">
              {message && (
                <div className="cardAccount__formGroup">
                  {alert && alert === "danger" && (
                    <div className="cardAccount__alert cardAccount__alert--danger" role="alert">
                      {message}
                    </div>)}
                  {alert && alert === "success" && (
                    <div className="cardAccount__alert cardAccount__alert--success" role="alert">
                      {message}
                    </div>)}
                </div>
              )}
              <div className="cardAccount__formGroup">
                <label htmlFor="files" className="cardAccount__label">Imágenes producto</label>
                <div className="cardAccount__listImages">
                {images
                  && images.length > 0 && images.map((image, index) =>
                    <div className="cardAccount__itemImage">
                      <img key={index} className="cardAccount__img" src={BASE_URL_IMG + image.image} />
                      <button onClick={() => onHandleRemoveImage(image.id)} className="cardAccount__button cardAccount__button--alert">Eliminar</button>
                    </div>
                  )}
                </div>
                <Input id="files"
                  type="file"
                  className="cardAccount__input"
                  name="files"
                  multiple
                  onChange={onChangeFiles}
                  validations={[requireField]}/>
              </div>

              <div className="cardAccount__formGroup cardAccount__formGroup--right">
                <Link to="/dashboard-admin" className="cardAccount__button cardAccount__button--cancel" title='Cancelar'>
                  Cancelar
                </Link>
                <button type="submit" className="cardAccount__button" title='Cambiar imagen principal'>
                  {loading && (<span className="spinner-border"></span>)}
                  Añadir imágenes
                </button>
              </div>
            </div>
          </div>
        </div>
        <CheckButton style={{ display: "none" }}
          ref={c => {
            setCheckButton(c);
          }}
        />
      </Form>
    </React.Fragment>
  )
}