import React, {useState, useContext} from 'react'
import './Header.css'
import Hamburguer from './Hamburguer'
import Sidebar from './Sidebar'
import { NavLink } from 'react-router-dom'
import { RiPhoneLine, RiUserFollowLine, RiShoppingBasketLine, RiUserSharedLine } from 'react-icons/ri'
import { Link } from 'react-router-dom';
import StoreContext from './../../context/StoreContext'
import UserContext from './../../context/UserContext'
import CartContext from './../../context/CartContext'

export default function Header(props) {

  const { storeStatus, isLoading } = useContext(StoreContext);
  const { currentUser, isAdmin } = useContext(UserContext);
  const [isActive, setIsActive] = useState(false);
  const { cart, amountCart } = useContext(CartContext);

  const openHamburguer = () => {
    setIsActive(!isActive);
  }

  return (
    <React.Fragment>
      <div className={isActive ? 'sidebarBurguer sidebarBurguer--open' : 'sidebarBurguer sidebarBurguer--close'}>
        <nav className='sidebarBurguer__menu'>
          <ul className='menu__list'>
            <li className='list__item' onClick={openHamburguer}>
              <Link to="/" title="Volver a la home" className="list__link">Inicio</Link>
            </li>
            <li className='list__item' onClick={openHamburguer}>
              {(!storeStatus || storeStatus === 'Close') ?
                <Link to="#tiendacerrada" title="Tienda cerrada" className="list__link list__link--close">Tienda cerrada</Link> :
                <Link to="/tienda" title="Disfrute de todos nuestros productos" className="list__link">Tienda</Link>
              }
            </li>
            <li className='list__item' onClick={openHamburguer}>
              <Link to="/empresa" title="Conoce más a Rey Raf" className="list__link">Empresa</Link>
            </li>
            <li className='list__item' onClick={openHamburguer}>
              <Link to="/productos" title="Saborea el mejor tomate Raf" className="list__link">Productos</Link>
            </li>
            <li className='list__item' onClick={openHamburguer}>
              <Link to="/envases" title="Descubre nuestros formatos de envases" className="list__link">Envases</Link>
            </li>
            <li className='list__item' onClick={openHamburguer}>
              <Link to="/entorno" title="Explora nuestro entorno" className="list__link">Entorno</Link>
            </li>
            <li className='list__item' onClick={openHamburguer}>
              <Link to="/contacto" title="¿Tiene alguna duda? Contacte con nosotros" className="list__link">Contacto</Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className='backdrop'></div>
      <header className='header'>
        <div className='header__wrapper'>
          <nav className='header__nav'>
            <ul className='header__list'>
              <li className='header__item' onClick={openHamburguer}>
                <button className={isActive ? 'hamburguer hamburguer--open' : 'hamburguer'} aria-label="menu" title="Menú">
                  <span className="hamburguer__line"></span>
                  <span className="hamburguer__line"></span>
                </button>
              </li>
              <li className='header__item'>
                <a
                  href='tel:950600354'
                  title='¿Necesita ayuda? Llame al 950 60 03 54'
                  className='header__link'><RiPhoneLine size='1.75rem' /><span className='headerLink__phone'>950 60 03 54</span></a>
              </li>
            </ul>
          </nav>
          <div className='header__logo'>
            <NavLink to='/'
              title='Volver a inicio'
              className='logo'>
              <img src='/images/logo/logo.png' alt='Logotipo Rey Raf - Selección Gourmet' />
            </NavLink>
          </div>
          <nav className='header__nav'>
            <ul className='header__list header__list--end'>
              <li className='header__item'>
                {!currentUser ? <NavLink to='/login'
                  title='Iniciar sesión'
                  className='header__link'
                >
                  <RiUserSharedLine size='1.75rem' />
                </NavLink> :
                <NavLink to={isAdmin() ? '/dashboard-admin' : '/dashboard'}
                  title='Ir a perfil'//'Próximamente'
                  className='header__link' //header__link--close'
                >
                  <RiUserFollowLine size='1.75rem' />
                </NavLink> }
              </li>
              <li className='header__item'>
                {(!storeStatus || storeStatus === 'Close') ?
                  <NavLink to='#tiendacerrada' title='Tienda cerrada' className='header__link header__link--close'>
                    <RiShoppingBasketLine size='1.75rem' />
                  </NavLink> :
                  <NavLink to='/carrito' title='Ver cesta' className='header__link'>
                    <RiShoppingBasketLine size='1.75rem' />
                    {cart && <span className="header__quantity">{amountCart}</span>}
                  </NavLink>
                }
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </React.Fragment>
  )
}