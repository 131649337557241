import React from 'react'
import PolicyHero from './../../components/Texts/PolicyHero'

function CondicionesGeneralesCompraPage() {
  return (
    <React.Fragment>
      <PolicyHero title="Condiciones generales de contratación" />
      <section className="section">
        <div className="wrapper">
          <h4 className="policy__title">Introducción</h4>
          <p className="policy__text">Este documento contractual regirá las Condiciones Generales de contratación en la compra de tomate raf (en adelante, «Condiciones») a través del sitio web reyraf.com, propiedad de REY RAF 5 CORONAS SLU bajo la marca comercial de REY RAF 5 CORONAS, en adelante, PRESTADOR, cuyos datos de contacto figuran también en el Aviso Legal de esta Web.</p>
          <p className="policy__text">Estas Condiciones permanecerán publicadas en el sitio web a disposición del USUARIO para reproducirlas y guardarlas como confirmación del contrato, pudiendo ser modificadas en cualquier momento por el PRESTADOR. Es responsabilidad del USUARIO leerlas periódicamente, ya que resultarán aplicables aquellas que se encuentren vigentes en el momento de realización de pedidos.</p>
          <p className="policy__text">Los contratos no estarán sujetos a formalidad alguna con excepción de los supuestos expresamente señalados en los Códigos Civil y de Comercio y en esta o en otras leyes especiales.</p>
          <p className="policy__text">La aceptación de este documento conlleva que el USUARIO:</p>
          <ul>
            <li>Ha leído, entiende y comprende lo aquí expuesto</li>
            <li>Es una persona con capacidad suficiente para contratar.</li>
            <li>Asume todas las obligaciones aquí dispuestas.</li>
          </ul>
          <p className="policy__text">Estas condiciones tendrán un período de validez indefinido y serán aplicables a todas las contrataciones realizadas a través del sitio web del PRESTADOR.</p>
          <p className="policy__text">El PRESTADOR informa de que el comercio es responsable y conoce la legislación vigente de los países a los que envía los productos, y se reserva el derecho de modificar unilateralmente las condiciones, sin que ello pueda afectar a los bienes o promociones que fueron adquiridos previamente a la modificación.</p>
          <h4 className="policy__title">Identidad de las partes contratantes</h4>
          <p className="policy__text">Por un lado, el PRESTADOR de los tomates Raf contratados por el USUARIO es REY RAF 5 CORONAS SLU, con domicilio social en PJE. LOS MARTINEZ S/N, 04116 LOS MARTINEZ, (NIJAR) (ALMERIA), NIF B04768123 y con teléfono de atención al cliente/USUARIO 950600354.</p>
          <p className="policy__text">Y de otro, el USUARIO, registrado en el sitio web mediante un nombre de usuario y contraseña, sobre los que tiene responsabilidad plena de uso y custodia, y es responsable de la veracidad de los datos personales facilitados al PRESTADOR.</p>
          <h4 className="policy__title">Objeto del contrato</h4>
          <p className="policy__text">El presente contrato tiene por objeto regular la relación contractual de compraventa nacida entre el PRESTADOR y el USUARIO en el momento en que este acepta durante el proceso de contratación en línea la casilla correspondiente.</p>
          <p className="policy__text">La relación contractual de compraventa conlleva la entrega, a cambio de un precio determinado y públicamente expuesto a través del sitio web, de tomates raf.</p>
          <h4 className="policy__title">Procedimiento de contratación</h4>
          <p className="policy__text">El USUARIO, para poder acceder a los productos que ofrece el PRESTADOR, deberá ser mayor de edad y darse de alta a través del sitio web mediante la creación de una cuenta de usuario. Por ello, el USUARIO deberá proporcionar de manera libre y voluntaria los datos personales que se le requerirán, los cuales se tratarán de conformidad con lo dispuesto en el Reglamento (UE) 2016/679, de 27 de abril de 2016 (GDPR), relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y la Ley Orgánica 3/2018, de 5 de diciembre (LOPDGDD), relativa a la protección de datos de carácter personal y detallada en el Aviso legal y en la Política de privacidad de este sitio web.</p>
          <p className="policy__text">El USUARIO seleccionará un nombre de usuario y una contraseña, comprometiéndose a hacer un uso diligente de los mismos y a no ponerlos a disposición de terceros, así como a comunicar al PRESTADOR la pérdida o robo de los mismos o el posible acceso por un tercero no autorizado, de manera que este proceda al bloqueo inmediato.</p>
          <p className="policy__text">Una vez ha sido creada la cuenta de usuario, se informa de que conforme a lo que exige el artículo 27 de la Ley 34/2002, de Servicios de la Sociedad de la Información y del Comercio Electrónico (LSSICE), el procedimiento de contratación seguirá los siguientes pasos:</p>
          <ol>
            <li>Cláusulas generales de contratación.</li>
            <li>Envío y entrega de pedidos.</li>
            <li>Derecho de desistimiento.</li>
            <li>Reclamaciones y resolución de litigios en línea.</li>
            <li>Fuerza mayor.</li>
            <li>Competencia.</li>
            <li>Generalidades de la oferta.</li>
            <li>Precio y plazo de validez de la oferta.</li>
            <li>Gastos de transporte.</li>
            <li>Forma de pago, gastos y descuentos.</li>
            <li>Proceso de compra.</li>
            <li>Disociación y suspensión o rescisión del contrato.</li>
            <li>Ley aplicable y jurisdicción.</li>
          </ol>
          <h4 className="policy__title">1. CLÁUSULAS GENERALES DE CONTRATACIÓN</h4>
          <p className="policy__text">Salvo estipulación particular por escrito, la realización de un pedido al PRESTADOR supondrá la aceptación por parte del USUARIO de estas condiciones legales. Ninguna estipulación hecha por el USUARIO podrá diferir de las del PRESTADOR si no ha sido expresamente aceptada por adelantado y por escrito por el PRESTADOR.</p>
          <h4 className="policy__title">2. ENVÍO Y ENTREGA DE PEDIDOS</h4>
          <p className="policy__text">El plazo de entrega estará comprendido entre 2 y 6 días laborables, según la población de destino y la forma de pago elegida. Se entiende este término siempre que se haya confirmado la disponibilidad de la mercancía y comprobado el pago completo del pedido.</p>
          <p className="policy__text">En caso de que el PRESTADOR no hubiera realizado la entrega de la mercancía, transcurridos 30 días naturales desde la fecha de entrega pactada, por falta de disponibilidad del producto o servicio, el USUARIO será informado y quedará legitimado para cancelar el pedido y recibir la devolución del importe total pagado sin ningún coste, y sin que por ello se derive ninguna responsabilidad por daños y perjuicios imputable al PRESTADOR.</p>
          <p className="policy__text">En caso de retraso injustificado por parte del PRESTADOR respecto a la devolución del importe total, el USUARIO podrá reclamar que se le pague el doble del importe adeudado, sin perjuicio a su derecho de ser indemnizado por los daños y perjuicios sufridos en lo que excedan de dicha cantidad.</p>
          <p className="policy__text">El PRESTADOR no asumirá ninguna responsabilidad cuando la entrega del producto o servicio no llegue a realizarse, por ser los datos facilitados por el USUARIO, falsos, inexactos o incompletos.</p>
          <p className="policy__text">La entrega se considerará realizada en el momento en que el transportista haya puesto los productos a disposición del USUARIO y este, o el delegado de este, haya firmado el documento de recepción de la entrega.</p>
          <p className="policy__text">Corresponde al USUARIO verificar los productos a la recepción y exponer todas las salvedades y reclamaciones que puedan estar justificadas en el documento de recepción de la entrega.</p>
          <h4 className="policy__title">3. DERECHO DE DESISTIMIENTO</h4>
          <p className="policy__text">Los alimentos que se ofertan son perecederos, es decir, que pueden caducar o deteriorarse en un corto período de tiempo. Este tipo de suministro de bienes queda exento de devolución, según el inciso d) del artículo 103 de la Ley 3/2014, de 27 de marzo.</p>
          <p className="policy__text">De todos modos, se ofrece al USUARIO la posibilidad de reclamar los posibles vicios o defectos que presente el pedido en un plazo de 24 horas desde la entrega, tanto en modo en línea como fuera de línea, y, si se considera, realizar la devolución.</p>
          <p className="policy__text">Toda devolución deberá comunicarse al PRESTADOR, por correo electrónico a info@reyraf.es, indicando el número de factura o pedido correspondiente. Una vez el USUARIO haya recibido el número de devolución, hará llegar el producto al PRESTADOR, indicando este número en la carta de envío, con los gastos de transporte a su cargo, en el domicilio de REY RAF 5 CORONAS SLU, PJE. LOS MARTINEZ S/N , 04116 LOS MARTINEZ, (NIJAR) (ALMERIA)</p>
          <p className="policy__text">Analizado el caso, si el PRESTADOR considera que el producto no cumplía las condiciones de venta, en un plazo de 48 horas informará al USUARIO del importe y condiciones de su reembolso.</p>
          <h4 className="policy__title">4. RECLAMACIONES Y RESOLUCIÓN DE LITIGIOS EN LÍNEA</h4>
          <p className="policy__text">Cualquier reclamación que el USUARIO considere oportuna será atendida a la mayor brevedad posible, pudiéndose realizar en las siguientes direcciones de contacto:
            <br/>Postal: REY RAF 5 CORONAS SLU, PJE. LOS MARTINEZ S/N , 04116 LOS MARTINEZ, (NIJAR) (ALMERIA)
            <br/>Teléfono: 950600354
            <br />E-mail: info@reyraf.es
          </p>
          <p className="policy__text"><strong>Resolución de litigios en línea (Online Dispute Resolution)</strong></p>
          <p className="policy__text">Conforme al Art. 14.1 del Reglamento (UE) 524/2013, la Comisión Europea facilita una plataforma de acceso gratuito para la resolución de conflictos online entre el USUARIO y el PRESTADOR, sin necesidad de recurrir a los tribunales de justicia, mediante la intervención de un tercero, llamado Organismo de resolución de litigios, que actúa de intermediario entre ambos. Este organismo es neutral y dialogará con ambas partes para lograr un acuerdo, pudiendo finalmente sugerir y/o imponer una solución al conflicto.
            <br/>Enlace a la plataforma ODR: http://ec.europa.eu/consumers/odr/
          </p>
          <h4 className="policy__title">5. FUERZA MAYOR</h4>
          <p className="policy__text">Las partes no incurrirán en responsabilidad ante cualquier falta debida a causa mayor. El cumplimiento de la obligación se demorará hasta el cese del caso de fuerza mayor.</p>
          <h4 className="policy__title">6. COMPETENCIA</h4>
          <p className="policy__text">El USUARIO no podrá ceder, transferir o transmitir los derechos, responsabilidades y obligaciones contratados en la venta.</p>
          <p className="policy__text"> Si alguna estipulación de estas condiciones fuera considerada nula o de imposible cumplimiento, la validez, legalidad y cumplimiento del resto no se verán afectados de ninguna manera, ni sufrirán modificación de ningún modo.</p>
          <p className="policy__text">El USUARIO declara haber leído, conocer y aceptar las presentes Condiciones en toda su extensión.</p>
          <h4 className="policy__title">7. GENERALIDADES DE LA OFERTA</h4>
          <p className="policy__text">Todas las ventas y entregas efectuadas por el PRESTADOR se entenderán sometidas a las presentes Condiciones.</p>
          <p className="policy__text">Ninguna modificación, alteración o pacto contrario a la Propuesta Comercial de REY RAF 5 CORONAS SLU o a lo aquí estipulado, tendrá efecto, salvo pacto expreso por escrito firmado por el PRESTADOR, en este caso, estos pactos particulares prevalecerán.</p>
          <p className="policy__text">El PRESTADOR se reserva la facultad de modificar sus especificaciones respecto de la información facilitada en su publicidad, hasta que no afecte el valor de los productos ofrecidos. Estas modificaciones tendrán asimismo validez en caso de que, por cualquier causa, se viera afectada la posibilidad de suministro de los productos ofrecidos.</p>
          <h4 className="policy__title">8. PRECIO Y PLAZO DE VALIDEZ DE LA OFERTA</h4>
          <p className="policy__text">Los precios que se indican respecto de cada producto no incluyen el Impuesto sobre el Valor Añadido (IVA) u otros impuestos que pudieran ser aplicables. Estos precios, a menos que se indique expresamente lo contrario, no incluyen los gastos de envío, manipulación, envoltorio, seguro de envíos o cualesquiera otros servicios adicionales y anexos al producto adquirido.</p>
          <p className="policy__text">Los precios aplicables a cada producto son los publicados en el sitio web y se expresarán en la moneda EURO. El USUARIO asume que la valoración económica de algunos de los productos podrá variar en tiempo real.</p>
          <p className="policy__text">Antes de realizar la compra, el USUARIO podrá comprobar en línea todos los detalles del presupuesto: artículos, cantidades, precio, disponibilidad, gastos de transporte, cargos, descuentos, impuestos y el total de la compra. Los precios pueden cambiar diariamente mientras no se realice el pedido.</p>
          <p className="policy__text">Una vez realizado el pedido, los precios se mantendrán tanto si hay disponibilidad de productos como si no.</p>
          <p className="policy__text">Todo pago realizado al PRESTADOR conlleva la emisión de un albarán a nombre del USUARIO registrado o de la razón social que este haya informado en el momento de realizar el pedido. En caso de querer recibir factura mediante correo electrónico, deberá solicitarlo por cualquiera de los medios que el PRESTADOR pone a su disposición, informándole de que en cualquier momento podrá revocar dicha decisión.</p>
          <p className="policy__text">Para cualquier información sobre el pedido, el USUARIO podrá contactar a través del teléfono de atención al cliente del PRESTADOR 950600354 o vía correo electrónico a la dirección info@reyraf.es.</p>
          <h4 className="policy__title">9. GASTOS DE TRANSPORTE</h4>
          <p className="policy__text">Los precios incluyen gastos de envío o prestaciones complementarias, salvo pacto expreso por escrito en contrario.
            <br />La tarifa máxima de transporte aplicada es la siguiente:

          <br/>Península > 2 Kg hasta 10 Kg: 5,58  €

            <br />Fuera península (Islas Baleares e Islas Canarias) > 2 Kg hasta 10 Kg: Precio según destino
          </p>
          <h4 className="policy__title">10. FORMAS DE PAGO, CARGOS Y DESCUENTOS</h4>
          <p className="policy__text">El PRESTADOR es el responsable de las transacciones económicas y posibilita las siguientes formas para efectuar el pago de un pedido:</p>
          <ul>
            <li>Paypal.</li>
            <li>Contra reembolso.</li>
            <li>Tarjeta de crédito.</li>
          </ul>
          <p className="policy__text"><strong>Medidas de seguridad</strong></p>
          <p className="policy__text">El sitio web utiliza técnicas de seguridad de la información generalmente aceptadas en la industria, tales como SSL, datos introducidos en página segura, firewalls, procedimientos de control de acceso y mecanismos criptográficos, todo ello con el objeto de evitar el acceso no autorizado a los datos. Para lograr estos fines, el usuario/cliente acepta que el prestador obtenga datos para efecto de la correspondiente autenticación de los controles de acceso.</p>
          <p className="policy__text">  El PRESTADOR se compromete a no permitir ninguna transacción que sea o sea considerada ilegal por las marcas de tarjetas de crédito o el banco adquiriente, que pueda o tenga el potencial de dañar la buena voluntad de los mismos o influir de manera negativa en ellos.</p>
          <p className="policy__text">  Las siguientes actividades están prohibidas en virtud de los programas de las marcas de tarjetas: la venta u oferta de un producto o servicio que no cumpla con todas las leyes aplicables al Comprador, Banco Emisor, Comerciante o Titular de la tarjeta o tarjetas.</p>
          <h4 className="policy__title">11. PROCESO DE COMPRA</h4>
          <p className="policy__text">Cualquier producto del catálogo se puede añadir a la cesta. En esta, solo se observarán los artículos, la cantidad, el precio y el importe total.</p>
          <p className="policy__text">Las cestas no tienen ninguna vinculación administrativa, solo es un apartado donde se puede simular un presupuesto sin ningún compromiso por ambas partes.</p>
          <p className="policy__text">Desde la cesta se puede hacer un pedido siguiendo los pasos siguientes para su correcta formalización:</p>
          <ol>
            <li>Comprobación de los datos de facturación.</li>
            <li>Comprobación de la dirección de envío.</li>
            <li>Selección de la forma de pago.</li>
            <li>Realizar el pedido (comprar).</li>
          </ol>
          <p className="policy__text">Una vez procesado el pedido, el sistema envía instantáneamente un correo electrónico al departamento de gestión del PRESTADOR y otro al correo del USUARIO confirmando la realización del pedido.</p>
          <h4 className="policy__title">12. DISOCIACIÓN Y SUSPENSIÓN O RESCISIÓN DEL CONTRATO</h4>
          <p className="policy__text">Si cualquiera de estos términos y condiciones se considerara ilegal, nula o por cualquier razón inaplicable, esta condición se considerará separable y no afectará la validez y aplicabilidad de ninguna de las condiciones restantes.</p>
          <p className="policy__text">REY RAF 5 CORONAS podrá, sin previo aviso, suspender o terminar el acceso del USUARIO a sus servicios de e-commerce, en su totalidad o en parte, por cualquier razón válida, incluyendo, sin limitaciones, cuando el USUARIO no cumpla o siga cualquiera de las obligaciones establecidas en este documento o cualquier disposición legal, licencia, reglamento, directiva, código de prácticas o políticas de uso aplicables.</p>
          <p className="policy__text">Cuando REY RAF 5 CORONAS ejerza cualquiera de sus derechos o facultades bajo esta Cláusula, tal ejercicio no perjudicará ni afectará el ejercicio de cualquier otro derecho, facultad o recurso que pueda estar a disposición de REY RAF 5 CORONAS.</p>
          <h4 className="policy__title">13. LEY APLICABLE Y JURISDICCIÓN</h4>
          <p className="policy__text">Estas condiciones se regirán o interpretarán conforme a la legislación española en aquello que no esté expresamente establecido. El PRESTADOR y el USUARIO acuerdan someter a los juzgados y tribunales del domicilio del USUARIO cualquier controversia que pudiera suscitarse de la prestación de los productos o servicios objeto de estas Condiciones.</p>
        </div>
      </section>
    </React.Fragment>
  )
}

export default CondicionesGeneralesCompraPage