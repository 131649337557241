import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom'
import { RiStore3Line } from "react-icons/ri"
import '../Account/CardInline.css'
import StoreService from "../../services/Store/StoreService"
import StoreContext from './../../context/StoreContext'

export default function StoreInline() {

  const { storeStatus, updateStoreStatus, message, alert, isLoading } = useContext(StoreContext);
  const [loading, setLoading] = useState(false);



  return (
    <React.Fragment>
      <div className="cardAccountInline">
        <div className="cardAccountInline__wrapper">
          <div className="cardAccountInline__header">
            <div className="cardAccountInline__col">
              <div className="cardAccountInline__icon">
                <RiStore3Line />
              </div>
              <div className="cardAccountInline__info">
                <h5 className="cardAccountInline__title">Tienda</h5>
                <span className="cardAccountInline__text">Estado:
                  {isLoading && (<span className="spinner-border"></span>)}
                  {!isLoading && storeStatus === "Not_Stock" && <span className='cardAccountInline__badge cardAccountInline__badge--warning'>Sin stock</span>}
                  {!isLoading && storeStatus === "Open" && <span className='cardAccountInline__badge'>Abierta</span>}
                  {!isLoading && storeStatus === "Close" && <span className='cardAccountInline__badge cardAccountInline__badge--danger'>Cerrada</span>}
                </span>
              </div>
            </div>
            <div className="cardAccountInline__colActions">
              {!isLoading && (storeStatus === "Close" || storeStatus === "Open") && <button
                onClick={() => updateStoreStatus("Not_Stock")}
                title='No hay stock'
                className='cardAccountInline__button cardAccountInline__button--mini cardAccountInline__button--warning'>Abrir sin stock</button>}
              {!isLoading && (storeStatus === "Close" || storeStatus === "Not_Stock") && <button
                onClick={() => updateStoreStatus("Open")}
                title='Abrir tienda'
                className="cardAccountInline__button cardAccountInline__button--mini cardAccountInline__button--success">Abrir tienda</button>}
              {!isLoading && (storeStatus === "Open" || storeStatus === "Not_Stock") && <button
                onClick={() => updateStoreStatus("Close")}
                title='Cerrar tienda'
                className="cardAccountInline__button cardAccountInline__button--mini cardAccountInline__button--danger">Cerrar tienda</button>}
            </div>
          </div>
          {message && (
            <React.Fragment>
              <hr className="cardAccountInline__hr" />
              <div className="cardAccount__formGroup">
                {alert && alert === "danger" && (
                  <div className="cardAccount__alert cardAccount__alert--danger" role="alert">
                    {message}
                  </div>)}
                {alert && alert === "success" && (
                  <div className="cardAccount__alert cardAccount__alert--success" role="alert">
                    {message}
                  </div>)}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}