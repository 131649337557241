import React, { useState, useEffect, createContext } from 'react'
import AuthService from './../services/Authentication/AuthService'
import UserService from './../services/User/UserService'
import AddressService from './../services/User/AddressService'

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(null);
  const [messageLogin, setMessageLogin] = useState("");
  const [alertLogin, setAlertLogin] = useState(null);

  useEffect(() => {
    checkAuthenticatedIn();
  }, []);

  useEffect(() => {
    // console.log(alert)
    // console.log(message)
    // console.log(currentUser)
  }, [currentUser, alert, message]);

  const checkAuthenticatedIn = () => {
    if (AuthService.isAuthenticated() && currentUser == null) {
      getCurrentUser();
      return;
    }

    setCurrentUser(null);
  };

  const getCurrentUser = async () => {
    try {
      const response = await AuthService.me();
      setCurrentUser(response.data);
    }
    catch (error) {
      setCurrentUser(null);
      console.error(error);
    }
  }

  const Login = async (email, password) => {
    setIsLoading(true);
    try {
      const response = await AuthService.login(email, password);
      if (response.data.status !== "Success") {
        setCurrentUser(null);
        setMessageLogin("No se ha podido iniciar sesión.");
        setIsLoading(false);
        return;
      }

      localStorage.setItem("token", JSON.stringify(response.data.content.token));
      setCurrentUser(response.data.content.user);
      setMessageLogin("");
      setIsLoading(false);
    }
    catch(error) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setCurrentUser(null);
      setMessageLogin(resMessage);
      setIsLoading(false);
      console.error(error);
    }
  }

  const Logout = async () => {
    await AuthService.logout();
    setCurrentUser(null);
  }

  const isAdmin = () => {
    if (currentUser === null)
      return null;
    return (currentUser.role === "ROLE_ADMIN");
  }

  const updateUser = async (name, email, phone, cif) => {
    setIsLoading(true);
    try {
      const response = await UserService.update(name, email, phone, cif, currentUser.id);
      setCurrentUser(response.content.user);
      setAlert("success");
      setMessage(response.message);
      setIsLoading(false);
    }
    catch(error) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setAlert("danger");
      setMessage(resMessage);
      setIsLoading(false);
      console.error(error);
    }
  }

  return (
    <UserContext.Provider value={{ currentUser, isAdmin, isLoading, messageLogin, message, Login, Logout, updateUser, alert}}>
      {children}
    </UserContext.Provider>
  );

}

export default UserContext;