import React from 'react'
import PolicyHero from './../../components/Texts/PolicyHero'

function DerechoDesistimientoPage() {
  return (
    <React.Fragment>
      <PolicyHero title="Derecho de desistimiento" />
      <section className="section">
        <div className="wrapper">
          <p className="policy__text"><strong>A la atención de:</strong>
            <br/>Nombre: REY RAF 5 CORONAS SLU
            <br />NIF: B04768123
            <br />Dirección: PJE. LOS MARTINEZ S/N , 04116 LOS MARTINEZ, (NIJAR) (ALMERIA)
            <br />Población: LOS MARTINEZ, (NIJAR)
            <br />Teléfono: 950600354
            <br />Correo electrónico: info@reyraf.es
            <br />
            <strong>Datos del bien/prestación a desistir:</strong>
            <br />N.º contrato/pedido/factura:
            <br />Fecha contrato/pedido/factura:
            <br />Fecha de recepción del producto/servicio:
            <br />Descripción del producto/servicio:
            <br />
            <strong>Datos del consumidor/usuario:</strong>
            <br />Nombre:
            <br />Domicilio:
            <br />Población:
            <br />* Teléfono:
            <br />* E-mail:
            <br />(* datos no obligatorios)

            <br />
            <strong>Derecho de desistimiento:</strong>
            <br />Podrá ejercer el derecho de desistimiento dentro del período de 14 días establecidos por ley a partir del día siguiente de la fecha de un contrato de servicios o del día de la recepción de un producto.

            <br />De conformidad con el artículo 102 y siguientes del Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias, le comunico que desisto del contrato de venta del bien/prestación descrito anteriormente dentro del término establecido, por lo que agradecería se pusiese en contacto conmigo mediante los datos de contacto facilitados, para notificarme que se ha procedido a esta solicitud.

            <br />Fecha de la solicitud:

            <br />Firma del consumidor/usuario:
</p>
        </div>
      </section>
    </React.Fragment>
  )
}

export default DerechoDesistimientoPage