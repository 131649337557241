import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom';
import AuthService from "../../services/Authentication/AuthService";
import UserContext from './../../context/UserContext'

export const ProtectedRouteLogin = ({ route }) => {

  const { isAdmin } = useContext(UserContext);

  if (AuthService.isAuthenticated()) {
    return isAdmin() ? <Navigate to="/dashboard-admin" /> : <Navigate to="/dashboard" />
  }

  return route;
}
