import React from 'react'
import './../../components/Account/Account.css'
import Address from '../../components/Account/Address'
import { Link } from 'react-router-dom'

function AddressPage() {
  return (
    <React.Fragment>
      <section className="sectionAdmin">
        <div className="wrapper">
          <div className="headerAccount headerAccount--mini">
            <div className="headerAccount__wrapper headerAccount__wrapper--mini">
              <div className="headerAccount__body headerAccount__body--mini">
                <div className="headerAccount__row">
                  <div className="headerAccount__col">
                    <h6 className="headerAccount__subtitle">Nueva</h6>
                    <h2 className="headerAccount__title">Dirección</h2>
                  </div>
                  <div className="headerAccount__colButtons">
                    <Link to="/dashboard" title='Volver' className="headerAccount__button">Volver</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className='lineAccount' />
          <Address />
        </div>
      </section>
    </React.Fragment>
  )
}

export default AddressPage