import React, { useEffect, useRef } from 'react'
import './Brief.css'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

function Brief(props) {

  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  useEffect(() => {
    const brief = ref.current;
    gsap.fromTo(
      brief.querySelector(".brief__title"),
      {
        opacity: 0,
        y: -40,
        duration: 1.5
      },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: brief.querySelector(".brief__content"),
          //markers: true,
          start: "top 85%",
          end: "bottom 80px",
          scrub: false
        }
      }
    );
  }, [])

  useEffect(() => {
    const brief = ref.current;
    gsap.from(
      brief.querySelectorAll(".brief__description"),
      {
        opacity: 0,
        y: -30,
        duration: 1.2,
        stagger: .2,
        scrollTrigger: {
          trigger: brief.querySelector(".brief__content"),
          //markers: true,
          start: "top 85%",
          end: "bottom 80px",
          scrub: false
        }
      }
    );
  }, [])

  return (
    <div className="brief" ref={ref}>
      <div className="wrapper">
        <div className="brief__content">
          <div className="brief__header">
            <h2 className="brief__title">{props.title}</h2>
          </div>
          <div className="brief__text">
            {props.description.map((descp, i) =>
              <p className="brief__description" key={i}>{descp}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}


export default Brief