import React, { useState, useEffect } from 'react'
import AddressService from "../../services/User/AddressService";

export default function CartAddressForm({ handleValidAddressForm, setAddressCart, checkSameBillingAddress, setAddressBillingCart, addressIndependent, setAddressIndependent }) {

  const [streetShippingAddress, setStreetShippingAddress] = useState("")
  const [flatAndGateShippingAddress, setFlatAndGateShippingAddress] = useState("")
  const [zipShippingAddress, setZipShippingAddress] = useState("")
  const [townShippingAddress, setTownShippingAddress] = useState("")
  const [cityShippingAddress, setCityShippingAddress] = useState("")
  const addressIndependentInitial = { street: "", zip: "", town: "", city: "" }

  useEffect(() => {
    handleValidForm()
  }, [handleValidAddressForm])

  const onChangeStreetShippingAddress = (e) => {
    let input = document.querySelector(`input[name="streetShippingAddress"]`);
    if (e.target.value === "") {
      input.style.border = "1px solid red";
    }
    else {
      input.removeAttribute("style");
    }

    setStreetShippingAddress(e.target.value);
  }

  const onChangeFlatAndGateShippingAddress = (e) => {
    setFlatAndGateShippingAddress(e.target.value);
  }

  const onChangeZipShippingAddress = (e) => {
    let input = document.querySelector(`input[name="zipShippingAddress"]`);
    if (e.target.value === "") {
      input.style.border = "1px solid red";
    }
    else {
      input.removeAttribute("style");
    }

    setZipShippingAddress(e.target.value);
  }

  const onChangeTownShippingAddress = (e) => {
    let input = document.querySelector(`input[name="townShippingAddress"]`);
    if (e.target.value === "") {
      input.style.border = "1px solid red";
    }
    else {
      input.removeAttribute("style");
    }

    setTownShippingAddress(e.target.value);
  }

  const onChangeCityShippingAddress = (e) => {
    let input = document.querySelector(`select[name="cityShippingAddress"]`);
    if (e.target.value === "") {
      input.style.border = "1px solid red";
    }
    else {
      input.removeAttribute("style");
    }

    setCityShippingAddress(e.target.value);
  }

  const handleValidForm = () => {
    let isValid = true;

    if (streetShippingAddress === "") {
      let inputStreetShippingAddress = document.querySelector(`input[name="streetShippingAddress"]`);
      inputStreetShippingAddress.style.border = "1px solid red";
      isValid = false;
    }

    if (zipShippingAddress === "") {
      let inputZipShippingAddress = document.querySelector(`input[name="zipShippingAddress"]`);
      inputZipShippingAddress.style.border = "1px solid red";
      isValid = false;
    }

    if (townShippingAddress === "") {
      let inputTownShippingAddress = document.querySelector(`input[name="townShippingAddress"]`);
      inputTownShippingAddress.style.border = "1px solid red";
      isValid = false;
    }

    if (cityShippingAddress === "") {
      let inputCityShippingAddress = document.querySelector(`select[name="cityShippingAddress"]`);
      inputCityShippingAddress.style.border = "1px solid red";
      isValid = false;
    }

    if (isValid) {
      let address = flatAndGateShippingAddress ? streetShippingAddress + " " + flatAndGateShippingAddress + " " + zipShippingAddress + " " + townShippingAddress + " " + cityShippingAddress : streetShippingAddress + " " + zipShippingAddress + " " + townShippingAddress + " " + cityShippingAddress;
      if (checkSameBillingAddress) {
        setAddressBillingCart(address);
      }
      setAddressIndependent({ street: streetShippingAddress, zip: zipShippingAddress, town: townShippingAddress, city: cityShippingAddress })
      setAddressCart(address);
    }
  }

  return (
    <React.Fragment>
      <div className="cardAccount__header">
        <h4 className="cardAccount__title">Dirección de envío</h4>
      </div>
      <div className="cardAccount__body">
        <div className="cardAccount__formGroup">
          <label htmlFor="streetShippingAddress" className="cardAccount__label">Dirección</label>
          <input id="streetShippingAddress"
            type="text"
            className="cardAccount__input"
            name="streetShippingAddress"
            placeholder="Indique la dirección (calle, avenida...)"
            value={streetShippingAddress}
            onChange={onChangeStreetShippingAddress}
          />
        </div>
        <div className="cardAccount__formGroup">
          <label htmlFor="flatAndGateShippingAddress" className="cardAccount__label">Piso, puerta u otro</label>
          <input id="flatAndGateShippingAddress"
            type="text"
            className="cardAccount__input"
            name="flatAndGateShippingAddress"
            placeholder="Indique el piso, portal, puerta u otro"
            value={flatAndGateShippingAddress}
            onChange={onChangeFlatAndGateShippingAddress} />
        </div>
        <div className="cardAccount__formGroup">
          <label htmlFor="zipShippingAddress" className="cardAccount__label">Código postal</label>
          <input id="zipShippingAddress"
            type="text"
            className="cardAccount__input"
            name="zipShippingAddress"
            placeholder="Indique el código postal"
            value={zipShippingAddress}
            onChange={onChangeZipShippingAddress} />
        </div>
        <div className="cardAccount__formGroup">
          <label htmlFor="townShippingAddress" className="cardAccount__label">Localidad</label>
          <input id="townShippingAddress"
            type="text"
            className="cardAccount__input"
            name="townShippingAddress"
            placeholder="Indique la localidad o ciudad"
            value={townShippingAddress}
            onChange={onChangeTownShippingAddress}
          />
        </div>
        <div className="cardAccount__formGroup">
          <label htmlFor="cityShippingAddress" className="cardAccount__label">Provincia</label>
          <select id="cityShippingAddress" className="cardAccount__input" name="cityShippingAddress" onChange={onChangeCityShippingAddress} value={cityShippingAddress}>
            <option value="">Elija una provincia</option>
            {AddressService.cities() && AddressService.cities().map((key, value) => <option value={key} key={value}>{key}</option>)}
          </select>
        </div>
      </div>
    </React.Fragment>
  )
}