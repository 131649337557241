import React, { useRef, useEffect } from 'react'
import './HeroTextImage.css'
import { gsap, Power2 } from 'gsap'
import CSSRulePlugin from 'gsap/CSSRulePlugin';

function HeroTextImage(props) {

  gsap.registerPlugin(CSSRulePlugin)

  let image = useRef(null);
  let container = useRef(null);
  let imageContent = CSSRulePlugin.getRule(".heroImage__content:after");

  const timelineHeroImage = gsap.timeline();

  useEffect(() => {
    timelineHeroImage.to(container, 0, { css: { visibility: "visible" } });
    timelineHeroImage.to(imageContent, 1.4, { width: "0%", ease: Power2.easeInOut });
    timelineHeroImage.from(image, 1.4, {
      scale: 1.6,
      ease: Power2.easeInOut,
      delay: -1.4
    });
  });

  const timeline = gsap.timeline();

  useEffect(() => {
    const subtitle = document.querySelectorAll('.heroTextImage__subtitle');
    const title = document.querySelectorAll('.heroTextImage__title');
    timeline
      .from(subtitle, { opacity: 0, y: 50, duration: 1, stagger: .2 })
      .from(title, { opacity: 0, y: 50, duration: 1.2, stagger: .2 }, "-=.75")
  }, []);

  return (
    <section className="heroTextImage">
      <div className="heroTextImage__image" ref={el => (container = el)}>
        <div className="heroTextImage__cover">
          <img src={props.imgSrc} alt={props.imgAlt} ref={el => { image = el }}/>
        </div>
      </div>
      <div className="wrapper">
        <div className="heroTextImage__content">
          <div className="heroTextImage__head">
            <p className="heroTextImage__subtitle">{props.subtitle}</p>
            <h2 className="heroTextImage__title">{props.title} <span className="heroTextImage__strong">{props.titleStrong}</span></h2>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroTextImage