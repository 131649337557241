import axios from "axios";
import AuthHeader, { AuthHeaderFile } from "../Authentication/AuthHeader";
const API_URL = process.env.REACT_APP_BASE_URL_API + "store";
const API_URL_ADMIN = process.env.REACT_APP_BASE_URL_API + "admin/store";

const get = async () => {
  const { data } = await axios.get(API_URL)
  return data;
}

const update = async (status) => {
  const { data } = await axios.put(API_URL_ADMIN, status, { headers: AuthHeader() })
  return data;
}

export default { get, update }