import React from 'react'
import PropTypes from 'prop-types'
import './Sidebar.css'
import { Link } from 'react-router-dom';

function Sidebar({isActive}) {

  return (
    <div className={isActive ? 'sidebarBurguer sidebarBurguer--open' : 'sidebarBurguer sidebarBurguer--close'}>
      <nav className='sidebarBurguer__menu'>
        <ul className='menu__list'>
          <li className='list__item'>
            <Link to="/" title="Volver a la home" className="list__link">Inicio</Link>
          </li>
          <li className='list__item'>
            <Link to="/tienda" title="Disfrute de todos nuestros productos" className="list__link">Tienda</Link>
          </li>
          <li className='list__item'>
            <Link to="/empresa" title="Conoce más a Rey Raf" className="list__link">Empresa</Link>
          </li>
          <li className='list__item'>
            <Link to="/productos" title="Saborea el mejor tomate Raf" className="list__link">Productos</Link>
          </li>
          <li className='list__item'>
            <Link to="/envases" title="Descubre nuestros formatos de envases" className="list__link">Envases</Link>
          </li>
          <li className='list__item'>
            <Link to="/entorno" title="Explora nuestro entorno" className="list__link">Entorno</Link>
          </li>
          <li className='list__item'>
            <Link to="/contacto" title="¿Tiene alguna duda? Contacte con nosotros" className="list__link">Contacto</Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

Sidebar.propTypes = {
  isActive: PropTypes.bool
}

export default Sidebar