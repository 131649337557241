import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { RiFileListLine } from "react-icons/ri"
import OrderService from '../../services/Order/OrderService';
import { Link } from 'react-router-dom'

const BASE_URL_IMG = process.env.REACT_APP_BASE_URL_IMAGE;

export default function SummaryOrderPage() {

  const { locator } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState("");

  useEffect(() => {
    OrderService.get(0, locator).then(
      response => {
        setOrder(response.content.order);
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setIsLoading(false);
        setAlert("danger");
      }
    );
  }, [])

  useEffect(() => {
    setIsLoading(false);
  }, [order])

  return (
    <React.Fragment>
      <section className="section">
        <div className="wrapper">
          <div className="cardAccountInline">
            <div className="cardAccountInline__wrapper">
              <div className="cardAccountInline__header">
                <div className="cardAccountInline__col">
                  <div className="cardAccountInline__icon">
                    <RiFileListLine />
                  </div>
                  <div className="cardAccountInline__info">
                    <h5 className="cardAccountInline__title">Pedido</h5>
                    <span className="cardAccountInline__text">Localizador: {locator}</span>
                  </div>
                </div>
                <div className="cardAccountInline__colActions">
                  {!isLoading && order && <strong>Total: {order.total_price} €</strong>}
                </div>
              </div>
              <hr className="cardAccountInline__hr" />
              {message && (
                <div className="cardAccount__formGroup">
                  {alert && alert === "danger" && (
                    <div className="cardAccount__alert cardAccount__alert--danger" role="alert">
                      {message}
                    </div>)}
                </div>
              )}
              <div className="cardAccountInline__body">
                {isLoading && <span className="spinner-border"></span>}
                {!isLoading && order && order.lines.map((line, key) =>
                  <div key={key} className="cardAccountInline__row">
                    <div className="cardAccountInline__col">
                      <div className="cardAccountInline__img">
                        <img src={BASE_URL_IMG + line.product_image} alt={line.product_name} />
                        { }
                      </div>
                      <div className="cardAccountInline__info">
                        <h5 className="cardAccountInline__title">{line.product_name}</h5>
                        <span className="cardAccountInline__text">{line.product_description}</span>
                        <span className="cardAccountInline__text">{line.product_price} € x {line.amount_line}</span>
                      </div>
                    </div>
                    <div className="cardAccountInline__colActions">

                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Link to="/tienda" title="Continuar comprando" className="cartTotal__button">Continuar Comprando</Link>
        </div>
      </section>
    </React.Fragment>
  )
}