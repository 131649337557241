import React, { Component } from 'react'
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { Link, Navigate } from 'react-router-dom';
import './Account.css'
import AuthService from "../../services/Authentication/AuthService";

const emailValid = value => {
  if (!value || !isEmail(value)) {
    return (
      <span className="cardAccount__invalid">Por favor, introduzca un email válido.</span>
    );
  }
}

const passwordValid = value => {
  if (!value) {
    return (
      <span className="cardAccount__invalid">Su password es inválido. Por favor, pruebe de nuevo.</span>
    );
  }
};

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.onChangeCurrentPassword = this.onChangeCurrentPassword.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    this.state = {
      message: "",
      loading: false,
      currentPassword: "",
      password: "",
      confirmPassword: "",
      alert: "danger"
    };
  }
  onChangeCurrentPassword(e) {
    this.setState({
      currentPassword: e.target.value
    });
  }
  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }
  onChangeConfirmPassword(e) {
    this.setState({
      confirmPassword: e.target.value
    });
  }
  handleChangePassword(e) {
    e.preventDefault();
    this.setState({
      message: "",
      loading: true
    });
    this.form.validateAll();
    if (this.checkBtn.context._errors.length === 0) {
      if (this.state.password !== this.state.confirmPassword) {
        this.setState({
          message: "Deben coincidir las contraseñas.",
          loading: false,
          alert: "danger"
        });
        return;
      }
      this.setState({
        message: ""
      });
      AuthService.changePassword(this.state.currentPassword, this.state.password, this.state.confirmPassword).then(
        (response) => {
          this.setState({
            user: response.data.content.user,
            message: response.data.message,
            alert: "success",
            loading: false,
            currentPassword: "",
            password: "",
            confirmPassword: "",
          })
          this.form.hideError(this.currentPasswordInput)
          this.form.hideError(this.passwordInput)
          this.form.hideError(this.confirmPasswordInput)
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.setState({
            loading: false,
            message: resMessage,
            alert: "danger"
          });
        }
      );
    }
    else {
      this.setState({
        loading: false
      });
    }
  }
  render() {
    return (
      <React.Fragment>
        <Form onSubmit={this.handleChangePassword}
          ref={c => {
            this.form = c;
          }}>
          <div className="account">
            <div className="cardAccount">
              <div className="cardAccount__header">
                <h4 className="cardAccount__title">Cambiar contraseña</h4>
              </div>
              <div className="cardAccount__body">
                {this.state.message && (
                  <div className="cardAccount__formGroup">
                    {this.state.alert && this.state.alert === "danger" && (
                    <div className="cardAccount__alert cardAccount__alert--danger" role="alert">
                      {this.state.message}
                    </div>)}
                    {this.state.alert && this.state.alert === "success" && (
                      <div className="cardAccount__alert cardAccount__alert--success" role="alert">
                        {this.state.message}
                      </div>)}
                  </div>
                )}
                <div className="cardAccount__formGroup">
                  <label htmlFor="currentPassword" className="cardAccount__label">Contraseña actual</label>
                  <Input id="currentPassword"
                    type="password"
                    className="cardAccount__input"
                    name="currentPassword"
                    ref={c => { this.currentPasswordInput = c }}
                    placeholder="Indique su contraseña actual"
                    value={this.state.currentPassword}
                    onChange={this.onChangeCurrentPassword}
                    validations={[passwordValid]}/>
                </div>
                <div className="cardAccount__formGroup">
                  <label htmlFor="password" className="cardAccount__label">Nueva contraseña</label>
                  <Input id="password"
                    type="password"
                    className="cardAccount__input"
                    name="password"
                    ref={c => { this.passwordInput = c }}
                    placeholder="Indique su nueva contraseña"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                    validations={[passwordValid]}/>
                </div>
                <div className="cardAccount__formGroup">
                  <label htmlFor="confirmPassword" className="cardAccount__label">Confirmar nueva contraseña</label>
                  <Input id="confirmPassword"
                    type="password"
                    className="cardAccount__input"
                    name="confirmPassword"
                    ref={c => { this.confirmPasswordInput = c }}
                    placeholder="Confirme su nueva contraseña"
                    value={this.state.confirmPassword}
                    onChange={this.onChangeConfirmPassword}
                    validations={[passwordValid]}/>
                </div>
                <div className="cardAccount__formGroup cardAccount__formGroup--right">
                  <Link to="/dashboard" className="cardAccount__button cardAccount__button--cancel" title='Cancelar'>
                    Cancelar
                  </Link>
                  <button type="submit" className="cardAccount__button" title='Cambiar contraseña'>
                    {this.state.loading && (<span className="spinner-border"></span>)}
                    Cambiar contraseña
                  </button>
                </div>
              </div>
            </div>
          </div>
          <CheckButton style={{ display: "none" }}
            ref={c => {
              this.checkBtn = c;
            }}
          />
        </Form>
      </React.Fragment>
    );
  }
}