import axios from "axios";
import AuthHeader from "../Authentication/AuthHeader";
const API_URL = process.env.REACT_APP_BASE_URL_API +  "address";

const cities = () => {
  return [
    'Álava',
    'Albacete',
    'Alicante',
    'Almería',
    'Asturias',
    'Ávila',
    'Badajoz',
    'Baleares',
    'Barcelona',
    'Burgos',
    'Cáceres',
    'Cádiz',
    'Cantabria',
    'Castellón',
    'Ceuta',
    'Ciudad Real',
    'Córdoba',
    'La Coruña',
    'Cuenca',
    'Gerona',
    'Granada',
    'Guadalajara',
    'Gipúzcoa',
    'Huelva',
    'Huesca',
    'Jaén',
    'León',
    'Lérida',
    'Lugo',
    'Madrid',
    'Málaga',
    'Melilla',
    'Murcia',
    'Navarra',
    'Orense',
    'Palencia',
    'Las Palmas',
    'Pontevedra',
    'La Rioja',
    'Salamanca',
    'Segovia',
    'Sevilla',
    'Soria',
    'Tarragona',
    'Santa Cruz de Tenerife',
    'Teruel',
    'Toledo',
    'Valencia',
    'Valladolid',
    'Vizcaya',
    'Zamora',
    'Zaragoza'
  ]
}

const get = async id => {
  const { data } = await axios.get(API_URL + "/" + id, { headers: AuthHeader() })
  return data;
}

const getAll = async () => {
  const { data } = await axios.get(API_URL, { headers: AuthHeader() })
  return data;
}

const create = async (address) => {
  const { data } = await axios.post(API_URL, address, {headers: AuthHeader()})
  return data;
}

const update = async (address, id) => {
  const { data } = await axios.put(API_URL + "/" + id, address, { headers: AuthHeader() })
  return data;
}

const destroy = async (id) => {
  const { data } = await axios.delete(API_URL + "/" + id, { headers: AuthHeader() })
  return data;
}

export default { cities, create, get, getAll, destroy, update }