import React, {useState, useEffect} from 'react'
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import { isNumeric } from "validator";
import { Link, Navigate, useParams } from 'react-router-dom';
import './Account.css'
import AuthService from "../../services/Authentication/AuthService";
import AddressService from "../../services/User/AddressService";

const requiredValue = value => {
  if (!value) {
    return (
      <span className="cardAccount__invalid">Campo obligatorio.</span>
    );
  }
}

const numericValue = value => {
  if (!isNumeric(value)) {
    <span className="cardAccount__invalid">Debe indicar un valor numérico.</span>
  }
}

export default function Address(props) {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [alias, setAlias] = useState("");
  const [street, setStreet] = useState("");
  const [flatAndGate, setFlatAndGate] = useState("");
  const [zip, setZip] = useState("");
  const [town, setTown] = useState("");
  const [city, setCity] = useState("");
  const [alert, setAlert] = useState("");
  const [form, setForm] = useState(null);
  const [checkButton, setCheckButton] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    if (!id)
      return;
    AddressService.get(id).then(response => {
      let address = response.content.address;
      setMessage("");
      setAlias(address.alias);
      setStreet(address.street);
      setFlatAndGate(address.flat_and_gate === null ? "" : address.flat_and_gate);
      setZip(address.zip);
      setTown(address.town);
      setCity(address.city);
    },
    error => {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setMessage(resMessage);
      setLoading(false);
      setAlert("danger");
    });
  }, []);

  const onChangeAlias = (e) => {
    setAlias(e.target.value);
  }
  const onChangeStreet = (e) => {
    setStreet(e.target.value);
  }
  const onChangeFlatAndGate = (e) => {
    setFlatAndGate(e.target.value);
  }
  const onChangeZip = (e) => {
    setZip(e.target.value);
  }
  const onChangeTown = (e) => {
    setTown(e.target.value);
  }
  const onChangeCity = (e) => {
    setCity(e.target.value);
  }
  const handleAddress = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    form.validateAll();
    if (checkButton.context._errors.length === 0) {
      setMessage("");
      AddressService.create({
        alias: alias,
        street: street,
        flat_and_gate: flatAndGate,
        zip: zip,
        town: town,
        city: city
      }).then(
        (response) => {
          setMessage(response.message);
          setLoading(false);
          setAlert("success");
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(resMessage);
          setLoading(false);
          setAlert("danger");
        }
      );
    }
    else {
      setLoading(false);
    }
  }
  const handleUpdateAddress = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    form.validateAll();
    if (checkButton.context._errors.length === 0) {
      setMessage("");
      AddressService.update({
        alias: alias,
        street: street,
        flat_and_gate: flatAndGate,
        zip: zip,
        town: town,
        city: city
      }, id).then(
        (response) => {
          setMessage(response.message);
          setLoading(false);
          setAlert("success");
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(resMessage);
          setLoading(false);
          setAlert("danger");
        }
      );
    }
    else {
      setLoading(false);
    }
  }
  return (
    <React.Fragment>
      <Form onSubmit={id ? handleUpdateAddress : handleAddress}
        ref={c => {
          setForm(c);
        }}>
        <div className="account">
          <div className="cardAccount">
            <div className="cardAccount__header">
              <h4 className="cardAccount__title">Dirección</h4>
            </div>
            <div className="cardAccount__body">
              {message && (
                <div className="cardAccount__formGroup">
                  {alert && alert === "danger" && (
                    <div className="cardAccount__alert cardAccount__alert--danger" role="alert">
                      {message}
                    </div>)}
                  {alert && alert === "success" && (
                    <div className="cardAccount__alert cardAccount__alert--success" role="alert">
                      {message}
                    </div>)}
                </div>
              )}
              <div className="cardAccount__formGroup">
                <label htmlFor="alias" className="cardAccount__label">Alias</label>
                <Input id="alias"
                  type="text"
                  className="cardAccount__input"
                  name="alias"
                  placeholder="Indique el alias de la dirección. Ej: Casa, trabajo, casa playa..."
                  value={alias}
                  onChange={onChangeAlias}
                  validations={[requiredValue]} />
              </div>
              <div className="cardAccount__formGroup">
                <label htmlFor="street" className="cardAccount__label">Dirección</label>
                <Input id="street"
                  type="text"
                  className="cardAccount__input"
                  name="street"
                  placeholder="Indique la dirección (calle, avenida...)"
                  value={street}
                  onChange={onChangeStreet}
                  validations={[requiredValue]}
                  />
              </div>
              <div className="cardAccount__formGroup">
                <label htmlFor="flatAndGate" className="cardAccount__label">Piso, puerta u otro</label>
                <Input id="flatAndGate"
                  type="text"
                  className="cardAccount__input"
                  name="flatAndGate"
                  placeholder="Indique el piso, portal, puerta u otro"
                  value={flatAndGate}
                  onChange={onChangeFlatAndGate} />
              </div>
              <div className="cardAccount__formGroup">
                <label htmlFor="zip" className="cardAccount__label">Código postal</label>
                <Input id="zip"
                  type="text"
                  className="cardAccount__input"
                  name="zip"
                  placeholder="Indique el código postal"
                  value={zip}
                  onChange={onChangeZip}
                  validations={[requiredValue, numericValue]}/>
              </div>
              <div className="cardAccount__formGroup">
                <label htmlFor="town" className="cardAccount__label">Localidad</label>
                <Input id="town"
                  type="text"
                  className="cardAccount__input"
                  name="town"
                  placeholder="Indique la localidad o ciudad"
                  value={town}
                  onChange={onChangeTown}
                  validations={[requiredValue]}
                  />
              </div>
              <div className="cardAccount__formGroup">
                <label htmlFor="city" className="cardAccount__label">Provincia</label>
                <Select id="city" className="cardAccount__input" name="city" onChange={onChangeCity} validations={[requiredValue]} value={city}>
                    <option value="">Elija una provincia</option>
                  {AddressService.cities() && AddressService.cities().map((key, value) => <option value={key} key={value}>{key}</option>)}
                </Select>
              </div>
              <div className="cardAccount__formGroup cardAccount__formGroup--right">
                <Link to="/dashboard" className="cardAccount__button cardAccount__button--cancel" title='Cancelar'>
                  Cancelar
                </Link>
                {!id &&
                <button type="submit" className="cardAccount__button" title='Crear dirección'>
                  {loading && (<span className="spinner-border"></span>)}
                  Crear
                </button>
                }
                {id &&
                  <button type="submit" className="cardAccount__button" title='Actualizar dirección'>
                    {loading && (<span className="spinner-border"></span>)}
                    Editar
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
        <CheckButton style={{ display: "none" }}
          ref={c => {
            setCheckButton(c);
          }}
        />
      </Form>
    </React.Fragment>
  );
}