import React, {useContext} from 'react'
import MainHero from '../components/Galleries/MainHero'
import TitleSection from '../components/Texts/TitleSection'
import CardsScroll from '../components/Cards/CardsScroll'
import CardBanner from '../components/Cards/CardBanner'
import { RiShoppingBag3Fill } from "react-icons/ri";
import { Link } from 'react-router-dom'
import Feature from '../components/Texts/Feature'
import InfoSection from '../components/Texts/InfoSection'
import { FiMapPin, FiMap, FiPhoneCall, FiMail, FiChevronRight } from "react-icons/fi";
import { GiFarmer } from "react-icons/gi";
import StepElements from '../components/Steps/StepElements'
import HeroTextImage from '../components/Galleries/HeroTextImage'
import VideoSection from '../components/Video/VideoSection'
import Partners from '../components/Galleries/Partners'
import Prize from '../components/Texts/Prize'
import ShopClose from '../components/Texts/ShopClose'
import ShopNotStock from '../components/Texts/ShopNotStock'
import StoreContext from './../context/StoreContext'

function HomePage() {

  const { storeStatus } = useContext(StoreContext);

  const infoSectionFeatures = [
    { title: <Link to="/reyraf" title="Conozca mejor los valores que hacen que Rey Raf produzca el mejor tomate raf">La familia Piedra <FiChevronRight /></Link>, description: "Somos la tercera generación de una familia de agricultores. Cada tomate pasa por nuestras manos: lo cultivamos, recolectamos, envasamos y enviamos para que en menos de 24 horas pueda disfrutar del auténtico y verdadero tomate raf.", figure: <FiMap /> }
  ]

  return (
    <React.Fragment>
      <MainHero src="./images/home/hero-home.jpg" alt="Rey Raf, el rey de los tomates" />
      <React.Fragment>
        {storeStatus === 'Not_Stock' && <ShopNotStock />}
        {/* <section className="section">
          <div className="wrapper">
            <TitleSection title="Durante La Semana Santa no se realizarán salidas por festivo nacional." />
          </div>
        </section> */}
      </React.Fragment>
      {(!storeStatus || storeStatus === 'Close') ?
        <ShopClose /> :
        <React.Fragment>
          {storeStatus === 'Not_Stock' && <ShopNotStock />}
          <section className="section">
            <div className="wrapper">
              <TitleSection title="Bienvenidos a nuestra tienda" description={["Elige entre alguno de nuestros productos destacados"]} />
              <CardsScroll />
              <CardBanner figure={<RiShoppingBag3Fill />} text="Visite nuestra tienda y disfrute de todos nuestros productos." button={<React.Fragment><Link to="/tienda" title="Ver todos los productos">Ir a tienda</Link></React.Fragment>} />
            </div>
          </section>
        </React.Fragment>
      }
      <section className="section">
        <div className="wrapper">
          <TitleSection title="Sabor a tomate, cómo el de toda la vida" description={["Somos productores, recolectores, envasadores y embajadores de nuestro producto"]} />
          <div className="section__content section--start">
            <Feature icon={<GiFarmer />} title="Familia de agricultores"
              description={<span>Somos una empresa con tradición en el cultivo del tomate Raf. Por ello, decidimos dar un aire nuevo a la que fue la forma de vida de nuestros abuelos.</span>}
              link="/empresa" linkTitle="Conoce mejor a la familia Rey Raf" linkText={<span>Conócenos <FiChevronRight /></span>} />
            <Feature
              icon={<img src="./images/logo/tomate-raf.svg" alt="Tomate Raf" />}
              title="Tomate Raf"
              description={
                <span>Conozca un producto único por su sabor y dulzor. Carácter y sabor propio, sabroso y andaluz.</span>
              } link="/productos" linkTitle="Descubre más acerca del tomate Raf" linkText={<span>Saboréalo <FiChevronRight /></span>} />
            <Feature icon={<FiMapPin />} title="La barriada de los Martínez" description={
              <span>Entre el mar y el desierto es la cuna del raf. En pleno Parque Natural Cabo de Gata-Níjar reúne unas condiciones de producción especiales: tierra, agua, aire y clima.</span>
            } link="/contacto" linkTitle="Descubre más acerca de nuestra finca" linkText={<span>Visite nuestra finca <FiChevronRight /></span>} />
          </div>
          <InfoSection
            subtitle="Rey Raf y la familia Piedra-Giménez"
            title="Recuperando un oficio de antaño"
            description={["Somos una empresa familiar con tradición en el cultivo del producto estrella de Almería: el tomate Raf.", "Originarios del Barrio de Los Martínez (Níjar), la familia Piedra se volcó en el cultivo de un tomate Raf que apareció en el mercado hace ya más de cuarenta años."]}
            infoSectionFeatures={infoSectionFeatures}
            image="./images/company/company2.jpg"
            imageAlt="Familia Rey Raf" />
        </div>
      </section>
      <section className="section">
        <div className="wrapper">
          <TitleSection title="El secreto: Rey Raf y sus cinco elementos" description={["Nuestro producto no sería posible si faltara uno solo de los elementos que hacen que este tomate tenga ese sabor tan característico e inigualable."]} />
          <div className="section__content">
            <StepElements />
          </div>
        </div>
      </section>
      <HeroTextImage title="Debía tener nombre propio, cómo su caracter." titleStrong="Sabor sabroso y andaluz." subtitle="Selección personalizada y manual del producto" imgSrc="./images/home/tomate-bandeja-index.jpg" imgAlt="Plato de tomate Raf cortado" />
      <section className="section">
        <VideoSection />
      </section>
      <section className="section">
        <div className="wrapper">
          <Prize/>
        </div>
      </section>
      <section className="section">
        <div className="wrapper">
          <TitleSection title="Colaboramos y recomendamos" description={["Rey Raf es una empresa que colabora con su entorno, con la sociedad y el deporte."]} />
          <Partners />
        </div>
      </section>
    </React.Fragment>
  )
}

export default HomePage