import React, {useState, useEffect, useContext} from 'react'
import './CardProductDetail.css'
import { BsBoxSeam } from "react-icons/bs";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { GiWeight } from "react-icons/gi";
import { RiPencilRuler2Fill } from "react-icons/ri";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from 'react-router-dom'
import CartContext from './../../context/CartContext'
import UserContext from './../../context/UserContext'
import StoreContext from './../../context/StoreContext'

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { FreeMode, Thumbs } from "swiper";

const BASE_URL_IMG = process.env.REACT_APP_BASE_URL_IMAGE;

function CardProductDetail(props) {

  const { storeStatus } = useContext(StoreContext);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [product, setProduct] = useState(null);
  const [count, setCount] = useState(1);
  let navigate = useNavigate();
  const { addCart, cart } = useContext(CartContext);
  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    if (!props.product)
      navigate("/", { replace: true })

    if (cart !== null) {
      let indexLine = cart.lines.findIndex(l => l.product_code === props.product.code);
      if (indexLine !== -1)
        setCount(cart.lines[indexLine].amount_line)
    }
    setProduct(props.product);
  }, []);

  useEffect(() => {
    //console.log(product)
  }, [product]);

  const handleAddCart = () => {
    let product = {
      "name": props.product.name,
      "code": props.product.code,
      "description": props.product.description,
      "price": props.product.price,
      "amount": props.product.amount,
      "measure": props.product.measure,
      "limit": props.product.limit,
      "image": props.product.mainImage.image
    }
    let order = {
      user: {
        id: currentUser ? currentUser.id : 0,
        name: currentUser ? currentUser.name : "",
        phone: currentUser ? currentUser.phone : "",
        email: currentUser ? currentUser.email : "",
        role: currentUser ? currentUser.role : "",
        new: false,
        password: "",
        passwordConfirm: ""
      },
      totalPrice: 0,
      address: "",
      addressBilling: "",
      payMethod: ""
    }
    addCart(order, product, count);
  }

  return (
    <section className="productDetail">
      <div className="productDetail__gallery">
        <div className="productDetail__galleryInner">
          <div className="productDetail__mainImage">
            {/* <span className="productDetail__dark"></span> */}
            <Swiper
              style={{
                // "--swiper-navigation-color": "#fff",
                // "--swiper-pagination-color": "#fff",
              }}
              loop={true}
              spaceBetween={0}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Thumbs]}
              className="mySwiper"
            >
              <SwiperSlide>
                <img src={BASE_URL_IMG + props.product.mainImage.image} className="productDetail__image"/>
              </SwiperSlide>
              {props.product.images && props.product.images.length > 0 && props.product.images.map((i, key) => {
                return <SwiperSlide key={key}>
                  <img src={BASE_URL_IMG + i.image} className="productDetail__image"/>
                </SwiperSlide>
              })}
            </Swiper>
          </div>
          <div className="productDetail__thumbnails">
            <div className="productDetail__thumbnailsInner">
              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={2}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Thumbs]}
                className="mySwiperThumb"
              >
                <div className="productDetail__thumbnailsItem">
                  <SwiperSlide>
                    <img src={BASE_URL_IMG + props.product.mainImage.image} className="productDetail__thumbnail"/>
                  </SwiperSlide>
                </div>
                {props.product.images && props.product.images.length > 0 && props.product.images.map((i, key) => {
                  return <div className="productDetail__thumbnailsItem" key={key}>
                    <SwiperSlide key={key}>
                      <img src={BASE_URL_IMG + i.image} className="productDetail__thumbnail" />
                    </SwiperSlide>
                  </div>
                })}

              </Swiper>
            </div>
          </div>
        </div>
      </div>
      <div className="productDetail__info">
        <div className="productDetail__infoInner">
          <h1 className="productDetail__title">{props.product.name}</h1>
          <span className="productDetail__price">{props.product.price} € <span className="productDetail__iva">(IVA incluido)</span></span>
          <div className="productDetail__infoSuccess">
            <BsBoxSeam />
            <div className="productDetail__successText">
              <p>Garantía 100% producto fresco.</p>
              <p>Envío 100% seguro en envase sostenible hecho a medida.</p>
            </div>
          </div>
          {(props.product.blocked == 0 && storeStatus !== 'Not_Stock') &&
          <React.Fragment>
            <div className="productDetail__buyButtons">
              <div className="productDetail__quantityButtons">
                <label htmlFor="quantity" className="productDetail__quantityTitle">Cantidad</label>
                <div className="productDetail__quantityWrapper">
                  <a href="#minus" onClick={() => { if (count > 1) setCount(count - 1) }} className="productDetail__quantityDownUp"><AiOutlineMinus/></a>
                  <input type="text" name="quantity" className="productDetail__quantityTotal" value={ count } />
                  <a href="#plus" onClick={() => { if (count < props.product.limit) setCount(count + 1)}} className="productDetail__quantityDownUp"><AiOutlinePlus/></a>
                </div>
              </div>
            </div>
            <button onClick={handleAddCart} className="productDetail__buttonCart">
              Agregar al carrito
            </button>
          </React.Fragment>
          }
          {
            (props.product.blocked == 1 || storeStatus === 'Not_Stock') && <p className="productDetail__notStock">Producto sin stock.</p>
          }
          <p className="productDetail__description">
            Límite de unidades por pedido: {props.product.limit}
          </p>
          <p className="productDetail__description">
            {props.product.description}
          </p>
          <div className="productDetail__features">
            {props.product.amount &&
              <div className="productDetail__feature">
                <div className="productDetail__featureTitle">
                  <GiWeight /> <span>Cantidad</span>
                </div>
                <span className="productDetail__featureDescription">{props.product.amount}</span>
              </div> }
            {props.product.measure && <div className="productDetail__feature">
                <div className="productDetail__featureTitle">
                  <RiPencilRuler2Fill /> <span>Medidas</span>
                </div>
              <span className="productDetail__featureDescription">{props.product.measure}</span>
              </div>}
          </div>
          <p className="productDetail__notice">
            * Gastos de envío incluidos para España y Portugal. <br/>
          </p>
        </div>
      </div>
    </section>
  )
}

export default CardProductDetail