import React from 'react'
import './CardFeature.css'

function CardFeature(props) {
  return (
    <div className="cardFeature">
      {/* <div className="cardFeature__head"> */}
        <img src={props.img} alt={props.imgAlt} className="cardFeature__image" />
      {/* </div>
      <div className="cardFeature__body"> */}
        <h5 className="cardFeature__title">{props.title}</h5>
        <p className="cardFeature__text">{props.description}</p>
      {/* </div> */}
    </div>
  )
}

export default CardFeature