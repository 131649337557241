import React, { useEffect, useRef } from 'react'
import './InfoSection.css'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

function InfoSection(props) {

  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  useEffect(() => {
    const infoSection = ref.current;
    gsap.from(
      infoSection.querySelector(".infoSection__subtitle"),
      {
        opacity: 0,
        y: -30,
        duration: 1.2,
        scrollTrigger: {
          trigger: infoSection.querySelector(".infoSection__content"),
          //markers: true,
          start: "top 85%",
          end: "bottom 80px",
          scrub: false
        }
      }
    );
  }, [])

  useEffect(() => {
    const infoSection = ref.current;
    gsap.from(
      infoSection.querySelectorAll(".infoSection__title"),
      {
        opacity: 0,
        y: -30,
        duration: 1.2,
        scrollTrigger: {
          trigger: infoSection.querySelector(".infoSection__content"),
          //markers: true,
          start: "top 85%",
          end: "bottom 80px",
          scrub: false
        }
      }
    );
  }, [])

  useEffect(() => {
    const infoSection = ref.current;
    gsap.from(
      infoSection.querySelectorAll(".infoSection__description"),
      {
        opacity: 0,
        y: -30,
        duration: 1.2,
        //stagger: .2,
        scrollTrigger: {
          trigger: infoSection.querySelector(".infoSection__content"),
          //markers: true,
          start: "top 85%",
          end: "bottom 80px",
          scrub: false
        }
      }
    );
  }, [])

  useEffect(() => {
    const infoSection = ref.current;
    gsap.from(
      infoSection.querySelectorAll(".infoSection__feature"),
      {
        opacity: 0,
        y: -30,
        duration: 1.2,
        scrollTrigger: {
          trigger: infoSection.querySelector(".infoSection__content"),
          //markers: true,
          start: "top 85%",
          end: "bottom 80px",
          scrub: false
        }
      }
    );
  }, [])

  useEffect(() => {
    const infoSection = ref.current;
    gsap.from(
      infoSection.querySelectorAll(".infoSection__image"),
      {
        opacity: 0,
        y: -30,
        duration: 1.2,
        scrollTrigger: {
          trigger: infoSection.querySelector(".infoSection__content"),
          //markers: true,
          start: "top 85%",
          end: "bottom 80px",
          scrub: false
        }
      }
    );
  }, [])

  return (
    <div className="infoSection" ref={ref}>
      <div className="infoSection__content">
        <div className="infoSection__head">
          <span className="infoSection__subtitle">{props.subtitle}</span>
          <h3 className="infoSection__title">{props.title}</h3>
          {
            props.description !== undefined && props.description.length > 0 &&
            props.description.map((descp, i) =>
              <p className="infoSection__description" key={i}>
                {descp}
              </p>
            )
          }
        </div>
        <div className="infoSection__body">
          {
            props.infoSectionFeatures !== undefined && props.infoSectionFeatures.length > 0 &&
            props.infoSectionFeatures.map((infoSectionFeature, i) =>
              <div className="infoSection__feature" key={i}>
                <div className="infoSection__featureIcon">
                  {infoSectionFeature.figure}
                </div>
                <div className="infoSection__featureContent">
                  <h5 className="infoSection__featureTitle">
                    {infoSectionFeature.title}
                  </h5>
                  <p className="infoSection__featureDescription">{infoSectionFeature.description}</p>
                </div>
              </div>
            )
          }
        </div>
      </div>
      <div className="infoSection__contentImage">
        <div className="infoSection__image">
          <img src={props.image} alt={props.imageAlt} />
        </div>
      </div>
    </div>
  )
}

export default InfoSection