import axios from "axios"
import AuthHeader from "../Authentication/AuthHeader";

const API_URL = process.env.REACT_APP_BASE_URL_API + "order";

const create = async (cart) => {
  const { data } = await axios.post(API_URL, cart);
  return data;
}

const pay = async (cart, id, locator) => {
  const { data } = await axios.put(API_URL + "/" + id + "/" + locator, cart);
  return data;
}

const send = async (ids) => {
  const { data } = await axios.put(API_URL + "/send", {
    ids: ids
  });
  return data;
}

const get = async (id, locator) => {
  const { data } = await axios.get(API_URL + "/" + id + "/" + locator);
  return data;
}

const getAll = async () => {
  const { data } = await axios.get(API_URL, { headers: AuthHeader() });
  return data;
}

export default {create, pay, get, getAll, send}