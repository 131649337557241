import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { RiArticleLine } from "react-icons/ri"
import '../Account/CardInline.css'
import ProductService from "../../services/Product/ProductService";

const BASE_URL_IMG = process.env.REACT_APP_BASE_URL_IMAGE;

export default function ProductInline(props) {

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [products, setProducts] = useState([]);
  const [alert, setAlert] = useState("");

  const getAllProducts = () => {
    setLoading(true);

    ProductService.getAll().then(
      response => {
        setLoading(false);
        setMessage("");
        setProducts(response.content.products);
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setMessage(resMessage);
        setAlert("danger");
      }
    );
  }

  const destroyProduct = id => {
    setLoading(true);
    ProductService.destroy(id).then(
      response => {
        setLoading(false);
        setMessage("");
        setProducts(response.content.products);
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setMessage(resMessage);
        setAlert("danger");
      }
    );
  }

  const blockProduct = (id, blocked) => {
    setLoading(true);
    ProductService.blocked(id, Number(blocked)).then(
      response => {
        setLoading(false);
        setMessage("");
        setProducts(response.content.products);
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setMessage(resMessage);
        setAlert("danger");
      }
    );
  }

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <React.Fragment>
      <div className="cardAccountInline">
        <div className="cardAccountInline__wrapper">
          <div className="cardAccountInline__header">
            <div className="cardAccountInline__col">
              <div className="cardAccountInline__icon">
                <RiArticleLine />
              </div>
              <div className="cardAccountInline__info">
                <h5 className="cardAccountInline__title">Productos</h5>
                <span className="cardAccountInline__text">Listado</span>
              </div>
            </div>
            <div className="cardAccountInline__colActions">
              <Link to="/dashboard-admin/producto" title='Dar de alta una nueva dirección' className="cardAccountInline__button">Nuevo</Link>
            </div>
          </div>
          <hr className="cardAccountInline__hr" />
          {message && (
            <div className="cardAccount__formGroup">
              {alert && alert === "danger" && (
                <div className="cardAccount__alert cardAccount__alert--danger" role="alert">
                  {message}
                </div>)}
              {alert && alert === "success" && (
                <div className="cardAccount__alert cardAccount__alert--success" role="alert">
                  {message}
                </div>)}
            </div>
          )}
          <div className="cardAccountInline__body">
            {loading && (<span className="spinner-border"></span>)}
            {!loading && ((products && products.length === 0) || !products) &&
              <div className="cardAccountInline__row">No dispone de productos</div>
            }
            {!loading && products && products.map((product, key) =>
              <div key={key} className="cardAccountInline__row">
                <div className="cardAccountInline__col">
                  <div className="cardAccountInline__img">
                    <img src={BASE_URL_IMG + product.mainImage.image} alt={product.name} />
                    {}
                  </div>
                  <div className="cardAccountInline__info">
                    <h5 className="cardAccountInline__title">{product.name}</h5>
                    <span className="cardAccountInline__text">{product.description}</span>
                  </div>
                </div>
                <div className="cardAccountInline__colActions">
                  <Link
                    to={"/dashboard-admin/producto/" + product.id}
                    title='Ver/Editar producto'
                    className="cardAccountInline__button cardAccountInline__button--mini cardAccountInline__button--info">Ver/Editar</Link>
                  <button
                      onClick={() => blockProduct(product.id, product.blocked == 1 ? 0 : 1)}
                      title={!product.blocked ? 'Bloquear producto' : 'Desbloquear producto'}
                      className="cardAccountInline__button cardAccountInline__button--mini cardAccountInline__button--warning">
                    {product.blocked == 1 ? <span>Desbloquear</span> : <span>Bloquear</span>}
                  </button>
                  <button
                    onClick={() => destroyProduct(product.id)}
                    title='Eliminar producto'
                    className="cardAccountInline__button cardAccountInline__button--mini cardAccountInline__button--danger">Eliminar</button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}