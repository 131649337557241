import React, {useRef, useEffect} from 'react'
import './HeroImage.css'
import {gsap,Power2} from 'gsap'
import CSSRulePlugin from 'gsap/CSSRulePlugin';

function HeroImage(props) {

  gsap.registerPlugin(CSSRulePlugin)

  let image = useRef(null);
  let container = useRef(null);
  let imageContent = CSSRulePlugin.getRule(".heroImage__content:after");

  const timelineHeroImage = gsap.timeline();

  useEffect(() => {
    timelineHeroImage.to(container, 0, { css: { visibility: "visible" } });
    timelineHeroImage.to(imageContent, 1.4, { width: "0%", ease: Power2.easeInOut });
    timelineHeroImage.from(image, 1.4, {
      scale: 1.6,
      ease: Power2.easeInOut,
      delay: -1.4
    });
  });

  return (
    <section className="heroImage" ref={el => (container = el)}>
      <div className="heroImage__content">
        <img
          src={props.src}
          alt={props.alt}
          className="heroImage__image"
          ref={el => {image = el}}/>
      </div>
    </section>
  )
}

export default HeroImage