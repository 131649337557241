import React, { useState, useContext } from 'react'
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { Link, useNavigate } from 'react-router-dom';
import './Login.css'
import UserContext from './../../context/UserContext'

const emailValid = value => {
  if (!value || !isEmail(value)) {
    return (
      <span className="cardLogin__invalid">Por favor, introduzca un email válido.</span>
    );
  }
}

const passwordValid = value => {
  if (!value) {
    return (
      <span className="cardLogin__invalid">Su password es inválido. Por favor, pruebe de nuevo.</span>
    );
  }
};

export default function Login() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [form, setForm] = useState(null);
  const [checkButton, setCheckButton] = useState(null);
  const { isLoading, messageLogin, Login, isAdmin, currentUser } = useContext(UserContext);
  let navigate = useNavigate();

  const onChangeEmail = (e) => {
    setEmail(e.target.value);

  }
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  }

  const handleLogin = (e) => {
    e.preventDefault();
    form.validateAll();
    if (checkButton.context._errors.length !== 0) {
      return;
    }

    Login(email, password);
  }

  return (
    <Form onSubmit={handleLogin}
      ref={c => {
        setForm(c);
      }}>
          <div className="cardLogin__body">
            {messageLogin && (
              <div className="cardLogin__formGroup">
                <div className="cardLogin__invalid cardLogin__invalidAlert" role="alert">
                  {messageLogin}
                </div>
              </div>
            )}
            <div className="cardLogin__formGroup">
              <label htmlFor="email" className="cardLogin__label">Email</label>
              <Input id="email"
                type="email"
                className="cardLogin__input"
                name="email"
                placeholder="Indique su email"
                value={email}
                onChange={onChangeEmail}
                validations={[emailValid]} />
            </div>
            <div className="cardLogin__formGroup">
              <div className="cardLogin__inline">
                <label htmlFor="password" className="cardLogin__label">Contraseña</label>
            <Link to="/recuperar-password" title="¿Ha olvidado su contraseña?" className="cardLogin__link">Recuperar contraseña</Link>
              </div>
              <Input id="password"
                type="password"
                className="cardLogin__input"
                name="password"
                placeholder="Indique su contraseña"
                value={password}
                onChange={onChangePassword}
                validations={[passwordValid]} />
            </div>
            <div className="cardLogin__formGroup">
              <button type="submit" className="cardLogin__button">
                {isLoading && (<span className="spinner-border"></span>)}
                Iniciar sesión
              </button>
            </div>
          </div>
      <CheckButton style={{ display: "none" }}
        ref={c => {
          setCheckButton(c);
        }}
      />
    </Form>
  )
}
