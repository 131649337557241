import React from 'react'
import Social from './Social'
import TitleSection from './TitleSection'

function ShopNotStock() {
  return (
    <section className="section section--inverse">
      <div className="wrapper">
        <TitleSection title="Agotado temporalmente" description={["¡Nos habéis dado tanto cariño que hemos tenido que cerrar temporalmente la tienda!", "Estamos trabajando para poder reponer más stock lo antes posible, os avisaremos cuando vuelva a estar disponible."]} important={true} />
      </div>
    </section>
  )
}

export default ShopNotStock