import React from 'react'
import './StepElements.css'

function StepElements() {
  return (
    <ul className="stepElements">
      <li className="element">
        <div className="element__wrapper">
          <span className="element__icon">
            <img src="./images/elements/elemento-agua.png" alt="Agua" />
          </span>
          <div className="element__content">
            <h5 className="element__title">Agua</h5>
            <p className="element__text">El primero de ellos es el agua salina del Parque Natural de Cabo de Gata - Níjar.</p>
          </div>
        </div>
      </li>
      <li className="element">
        <div className="element__wrapper">
          <span className="element__icon">
            <img src="./images/elements/elemento-tierra.png" alt="Tierra" />
          </span>
          <div className="element__content">
            <h5 className="element__title">Tierra</h5>
            <p className="element__text">Nuestra tierra mineralizada consigue dar al tomate Raf los minerales de los que se nutre.</p>
          </div>
        </div>
      </li>
      <li className="element">
        <div className="element__wrapper">
          <span className="element__icon">
            <img src="./images/elements/elemento-aire.png" alt="Aire" />
          </span>
          <div className="element__content">
            <h5 className="element__title">Aire</h5>
            <p className="element__text">El clima endémico: horas de sol, frío en invierno y temperatura constante.</p>
          </div>
        </div>
      </li>
      <li className="element">
        <div className="element__wrapper">
          <span className="element__icon">
            <img src="./images/elements/elemento-fuego.png" alt="Fuego" />
          </span>
          <div className="element__content">
            <h5 className="element__title">Fuego</h5>
            <p className="element__text">Representa el espíritu, el corazón con el que han trabajado en estas tierras nuestros mayores.</p>
          </div>
        </div>
      </li>
      <li className="element">
        <div className="element__wrapper">
          <span className="element__icon element__icon--last">
            <img src="./images/elements/elemento-quinta-corona.png" alt="5º Corona" />
          </span>
          <div className="element__content">
            <h5 className="element__title">5º Corona</h5>
            <p className="element__text">Nuestra familia, la unión que nos caracteriza y la ilusión por mantener esa tradición familiar.</p>
          </div>
        </div>
      </li>
    </ul>
  )
}

export default StepElements