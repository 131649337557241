import React from 'react'
import './Prize.css'
import { BsCheck } from 'react-icons/bs'
import { FaAward } from 'react-icons/fa'

function Prize() {
  return (
    <div className="prize">
      <div className="prize__row">
        <div className="prize__column prize__column--order">
          <figure className="prize__figure">
            <div className="prize__img">
              <img src="./images/reyraf/premio-proveedor.jpg" alt="Premio al mejor proveedor de productos mediterráneos" />
            </div>
            <div className="prize__icon">
              <FaAward />
            </div>
          </figure>
        </div>
        <div className="prize__column">
          <div className="prize__columnWrapper">
            <div className="prize__head">
              <h3 className="prize__title">Premio al mejor proveedor de productos mediterráneos</h3>
              <p className="prize__text">En el año 2006 esta empresa fue galardonada con el premio al mejor proveedor andaluz de productos mediterráneos. Durante el evento gastronómico el jurado se decantó por Rey Raf por presentar:</p>
            </div>
            <div className="prize__body">
              <ul className="prize__listCheck">
                <li className="prize__check"><BsCheck/><span>Un producto natural.</span></li>
                <li className="prize__check"><BsCheck /><span>Un producto único en su gama.</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Prize