import React from 'react'
import './CallToAction.css'
import { Link } from 'react-router-dom'
import { MdArrowForwardIos } from "react-icons/md";


function CallToAction(props) {
  return (
    <div className="callToAction">
      <Link to={props.link} title={props.title} className="callToAction__card">
        <div className="callToAction__body">
          <span className="callToAction__label">{props.label}</span>
          <h4 className="callToAction__title">{props.title}</h4>
          <p className="callToAction__description">{props.description}</p>
          <span className="callToActionLink">{props.textLink} <MdArrowForwardIos></MdArrowForwardIos></span>
          <div className="callToAction__figure">
            {props.figure}
          </div>
        </div>
      </Link>
    </div>
  )
}

export default CallToAction