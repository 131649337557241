import React from 'react'
import './CardBanner.css'

function CardBanner(props) {
  return (
    <div className="cardBanner">
      <div className="cardBanner__body">
        <div className="cardBanner__figure">{props.figure}</div>
        <div className="cardBanner__text">{props.text}</div>
        <div className="cardBanner__button">{props.button}</div>
      </div>
    </div>
  )
}

export default CardBanner