import React, {useState, useEffect} from 'react'
import DataTable from 'react-data-table-component';
import { RiFileListLine } from "react-icons/ri";
import OrderService from '../../services/Order/OrderService';
import styled from 'styled-components';
import ReactPDF, { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer/lib/react-pdf.browser.es.js'
import InvoicePDF from './../AdminAccount/InvoicePDF';

const BASE_URL_IMG = process.env.REACT_APP_BASE_URL_IMAGE;

//const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
const ExpandedComponent = ({ data }) => {
  return(
    <React.Fragment>
      <div>
        <div className="cardAccountInline__wrapper">
          <div className="cardAccountInline__header">
            <div className="cardAccountInline__col">
              <div className="cardAccountInline__info">
                <h5 style={{fontSize: '.825rem'}} className="cardAccountInline__title">Pedido: {data.order}</h5>
                <span className="cardAccountInline__text">Localizador: {data.locator}</span>
                <span className="cardAccountInline__text">Observaciones: {data.observations}</span>
              </div>
            </div>
            <div className="cardAccountInline__colActions">
              <strong>Total: {data.total}</strong>
            </div>
          </div>
          <hr className="cardAccountInline__hr" />
          <div style={{marginTop: '1.25rem'}} className="cardAccountInline__info">
            <strong style={{ fontSize: '.825rem' }} className="cardAccountInline__title">Dirección envío: </strong>
            <span  className="cardAccountInline__text">{data.address_send}</span>
          </div>
          <div className="cardAccountInline__info">
            <strong style={{ fontSize: '.825rem' }} className="cardAccountInline__title">Dirección facturación:</strong>
            <span className="cardAccountInline__text">{data.address_billing}</span>
          </div>
          <hr className="cardAccountInline__hr" />
          <div className="cardAccountInline__body">
            {data.lines.map((line, key) =>
              <div key={key} className="cardAccountInline__row">
                <div className="cardAccountInline__col">
                  <div className="cardAccountInline__img">
                    <img src={BASE_URL_IMG + line.product_image} alt={line.product_name} />
                  </div>
                  <div className="cardAccountInline__info">
                    <h5 className="cardAccountInline__title">{line.product_name}</h5>
                    <span className="cardAccountInline__text">{line.product_description}</span>
                    <span className="cardAccountInline__text">{line.product_price} € x {line.amount_line}</span>
                  </div>
                </div>
              </div>)}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const noDataComponent = "No hay compras para mostrar";

const paginationComponentOptions = {
  rowsPerPageText: 'Filas por página',
  rangeSeparatorText: 'de',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Todos',
};

const customStyles = {
  rows: {
    style: {
      '&:hover': {
        backgroundColor: 'rgba(0,189,142,.1) !important',
      },
    }
  }
}

const columns = [
  {
    name: 'Pedido',
    selector: row => row.order,
    sortable: true
  },
  {
    name: 'Localizador',
    selector: row => row.locator,
    omit: true
  },
  {
    name: 'Localizador',
    selector: row => row.locatorFormatted,
    sortable: true
  },
  {
    name: 'Fecha',
    selector: row => row.date,
    sortable: true
  },
  {
    name: 'Método pago',
    selector: row => row.pay_method,
    sortable: true
  },
  {
    name: 'Total',
    selector: row => row.total,
    sortable: true
  },
  {
    name: 'Acciones',
    selector: row => row.actions,
    sortable: false
  }
];

const TextField = styled.input`
	height: 32px;
	width: 200px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;

	&:hover {
		cursor: pointer;
	}
`;

const ClearButton = styled.button`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
	height: 34px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
  cursor: pointer;
  background-color: #008060;
  color: white;
  border: 0;
`;


const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <React.Fragment>
    <TextField
      id = "search"
			type = "text"
			placeholder = "Filtrar por localizador"
			aria-label="Search Input"
			value = { filterText }
			onChange = { onFilter } />
      <ClearButton ClearButton type = "button" onClick = { onClear } title="Limpiar filtro">
        X
      </ClearButton >
  </React.Fragment>
);

export default function OrderInline() {
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = data.filter(
    item => item.locator && item.locator.toLowerCase().includes(filterText.toLowerCase()),
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    getOrders();
  }, [])

  const getOrders = async () => {
    await OrderService.getAll().then(
      response => {
        let orders = [];
        response.content.orders.map((order, k) => {
          let orderObject = prepareOrder(order, k);
          orders.push(orderObject);
        });
        setData(orders);
        setTotalOrders(orders.length)
        setIsLoading(false);
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setIsLoading(false);
        setMessage(resMessage);
      }
    );
  }

  const prepareOrder = (order, k) => {
    if (order === null)
      return {};

    return {
      "id": k,
      "order": order.serie+order.number,
      "locator": order.locator,
      "observations": order.observations,
      "locatorFormatted": <strong>{order.locator}</strong>,
      "address_send": order.user_address,
      "address_billing": order.user_billing_address,
      "date": formatter.format(Date.parse(order.created_at)),
      "pay_method": translatePayMethod(order.pay_method),
      "total": <strong>{order.total_price + '€'}</strong>,
      "actions": getAction(order),
      "lines": order.lines
    }
  }

  const getAction = (order) => {
    return <PDFDownloadLink
      document={<InvoicePDF order={order} />}
      fileName={"Pedido " + order.serie + order.number + ".pdf"}
      className="cardAccountInline__button cardAccountInline__button--mini cardAccountInline__button--info"
    >
      {({ blob, url, loading, error }) =>
        loading ? "Cargando..." : "Generar pedido"
      }
    </PDFDownloadLink>;
  }

  const translatePayMethod = (pay_method) => {
    switch (pay_method) {
      case "PAYPAL":
        return "Paypal";
      case "CREDIT_CARD":
        return "Tarjeta de crédito";
      default:
        return "Contrareembolso"
    }
  }

  const formatter = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "numeric",
    day: "2-digit"
  });

  return (
    <React.Fragment>
      <div className="cardAccountInline">
        <div className="cardAccountInline__wrapper">
          <div className="cardAccountInline__header">
            <div className="cardAccountInline__col">
              <div className="cardAccountInline__icon">
                <RiFileListLine />
              </div>
              <div className="cardAccountInline__info">
                <h5 className="cardAccountInline__title">Pedidos</h5>
                <span className="cardAccountInline__text">Total: {totalOrders} compras</span>
              </div>
            </div>
            <div className="cardAccountInline__colActions">

            </div>
          </div>
          <hr className="cardAccountInline__hr" />
          {message && (
            <div className="cardAccount__formGroup">
              <div className="cardAccount__alert cardAccount__alert--danger" role="alert">
                {message}
              </div>
            </div>
          )}
          <div className="cardAccountInline__body">
            {isLoading && (<span className="spinner-border"></span>)}
            {!isLoading &&
              <DataTable
                columns={columns}
              data={filteredItems}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                pagination
                highlightOnHover
                striped
                paginationComponentOptions={paginationComponentOptions}
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                customStyles={customStyles}
              noDataComponent={noDataComponent}/>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}