import React, { useState, useContext } from 'react'
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { Link, Navigate } from 'react-router-dom';
import './Account.css'
import UserContext from "./../../context/UserContext";

const nameValid = value => {
  if (!value) {
    return (
      <span className="cadrLogin__invalid">Por favor, introduzca un nombre válido, es un campo obligatorio.</span>
    );
  }
}

const emailValid = value => {
  if (!value || !isEmail(value)) {
    return (
      <span className="cadrLogin__invalid">Por favor, introduzca un email válido.</span>
    );
  }
}

const phoneValid = value => {
  if (!value) {
    return (
      <span className="cadrLogin__invalid">Por favor, introduzca un teléfono válido, es un campo obligatorio.</span>
    );
  }
}

const cifValid = value => {
  if (!value) {
    return (
      <span className="cadrLogin__invalid">Por favor, introduzca un documento de identificación válido, es un campo obligatorio.</span>
    );
  }
}

export default function Profile() {

  const { currentUser, updateUser, alert, message, isLoading } = useContext(UserContext);
  const [name, setName] = useState(currentUser?.name);
  const [email, setEmail] = useState(currentUser?.email);
  const [phone, setPhone] = useState(currentUser?.phone);
  const [cif, setCif] = useState(currentUser?.cif);
  const [form, setForm] = useState(null);
  const [checkButton, setCheckButton] = useState(null);

  const onChangeName = (e) => {
    setName(e.target.value);
  }

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  }

  const onChangePhone = (e) => {
    setPhone(e.target.value);
  }

  const onChangeCif = (e) => {
    setCif(e.target.value);
  }

  const handleUserUpdate = (e) => {
    e.preventDefault();

    form.validateAll();
    if (checkButton.context._errors.length !== 0) {
      return;
    }

    updateUser(name, email, phone, cif)
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleUserUpdate}
        ref={c => {
          setForm(c);
        }}>
        <div className="account">
          <div className="cardAccount">
            <div className="cardAccount__header">
              <h4 className="cardAccount__title">Información de perfil</h4>
            </div>
            <div className="cardAccount__body">
              {message && (
                <div className="cardAccount__formGroup">
                  {alert && alert === "danger" && (
                    <div className="cardAccount__alert cardAccount__alert--danger" role="alert">
                      {message}
                    </div>)}
                  {alert && alert === "success" && (
                    <div className="cardAccount__alert cardAccount__alert--success" role="alert">
                      {message}
                    </div>)}
                </div>
              )}
              <div className="cardAccount__formGroup">
                <label htmlFor="name" className="cardAccount__label">Nombre y apellidos</label>
                <Input id="name"
                  type="text"
                  className="cardAccount__input"
                  name="name"
                  placeholder="Indique su nombre y apellidos"
                  value={name}
                  onChange={onChangeName}
                  validations={[nameValid]} />
              </div>
              <div className="cardAccount__formGroup">
                <label htmlFor="phone" className="cardAccount__label">Teléfono</label>
                <Input id="phone"
                  type="tel"
                  className="cardAccount__input"
                  name="phone"
                  placeholder="Indique su teléfono"
                  value={phone}
                  onChange={onChangePhone}
                  validations={[phoneValid]} />
              </div>
              <div className="cardAccount__formGroup">
                <label htmlFor="email" className="cardAccount__label">Email</label>
                <Input id="email"
                  type="email"
                  className="cardAccount__input"
                  name="email"
                  placeholder="Indique su email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[emailValid]} />
              </div>
              <div className="cardAccount__formGroup">
                <label htmlFor="cif" className="cardAccount__label">Documento de identificación</label>
                <Input id="cif"
                  type="text"
                  className="cardAccount__input"
                  name="cif"
                  placeholder="Indique su cif, dni..."
                  value={cif}
                  onChange={onChangeCif}
                  validations={[cifValid]}/>
              </div>
              <div className="cardAccount__formGroup cardAccount__formGroup--right">
                <Link to="/dashboard" className="cardAccount__button cardAccount__button--cancel" title='Cancelar'>
                  Cancelar
                </Link>
                <button type="submit" className="cardAccount__button" title='Guardar perfil'>
                  {isLoading && (<span className="spinner-border"></span>)}
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        <CheckButton style={{ display: "none" }}
          ref={c => {
            setCheckButton(c);
          }}
        />
      </Form>
    </React.Fragment>
  );
}