import React, { Component } from 'react'
import './Account.css'
import AuthService from "../../services/Authentication/AuthService";
import { Link, Navigate } from 'react-router-dom';

export default class DesactivateAccount extends Component {
  constructor(props) {
    super(props);
    this.handleDesactivateAccount = this.handleDesactivateAccount.bind(this);
    this.state = {
      desactivate: false,
      loading: false
    };
  }
  handleDesactivateAccount()  {
    this.setState({
      loading: true
    });
    AuthService.desactivateAccount().then(
      (response) => {
        AuthService.logout();
        this.setState({
          desactive: true,
          loading: false
        });
      }
    );
  }
  render() {
    return (
      <React.Fragment>
        {this.state.desactive && (<Navigate to="/login" replace={false} />)}
        <div className="account">
          <div className="cardAccount">
            <div className="cardAccount__header">
              <h4 className="cardAccount__title cardAccount__title--alert">Desactivar cuenta</h4>
              <p className="cardAccount__text">Eliminar permanentemente su cuenta y todo su contenido. Esta acción no es reversible, por favor, asegúrese.</p>
            </div>
            <div className="cardAccount__body">
              <div className="cardAccount__formGroup">
                <button type="submit" className="cardAccount__button cardAccount__button--alert" title="Eliminar cuenta de forma permanente" onClick={this.handleDesactivateAccount}>
                  {this.state.loading && (<span className="spinner-border"></span>)}
                  Eliminar mi cuenta
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}