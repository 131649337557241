import React from 'react'
import Social from './Social'
import TitleSection from './TitleSection'

function ShopClose() {
  return (
    <section className="section section--inverse">
      <div className="wrapper">
        <TitleSection title="Final de temporada, ¡Nos vemos en noviembre!" description={["Desde Rey Raf queremos agradecerles la confianza. Volveremos con novedades.", "Tu satisfacción es importante para nosotros. Gracias por formar parte de la familia Rey Raf."]} important={true}/>
        <Social />
      </div>
    </section>
  )
}

export default ShopClose