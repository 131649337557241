import React, {useContext, useState, useEffect} from 'react'
import { FiUserCheck, FiUserPlus, FiCheck } from "react-icons/fi";
import Login from './../../components/Account/Login'
import UserContext from './../../context/UserContext'

export default function CartProfile({ showSectionAccount, setShowSectionAccount, userCart, setUserCart, setAddressCart, setAddressBillingCart, setPayMethodCart }) {

  const { currentUser } = useContext(UserContext);
  const [toggleSectionAccount, setToggleSectionAccount] = useState(true);


  const changeUserAccount = () => {
    const userInitial = { id: 0, name: "", email: "", phone: "", role: "", newUser: false, password: "", passwordConfirm: "", cif: "", isValid: false }
    setUserCart(userInitial);
    setAddressCart("");
    setAddressBillingCart("");
    setShowSectionAccount(true);
    setPayMethodCart("");
  }

  useEffect(() => {
    if (!userCart.isValid)
      return;
    setShowSectionAccount(false);
  }, [userCart])

  useEffect(() => {
    if (!currentUser)
      return;
    let user = {
      id: currentUser.id,
      name: currentUser.name,
      email: currentUser.email,
      phone: currentUser.phone,
      role: currentUser.role,
      cif: currentUser.cif,
      newUser: false,
      password: "",
      passwordConfirm: "",
      isValid: true
    };
    setUserCart(user);
  }, [currentUser])

  return (
    <section className={showSectionAccount ? "checkoutStep checkoutStep__open" : "checkoutStep"}>
      <div className="checkoutStep__main">
        <div className="checkStep__content">
          <div className="checkoutStep__col">
            <h2 className="checkoutStep__title">1. Cuenta</h2>
          </div>
          <div className="checkoutStep__col--right">
            {!showSectionAccount && !currentUser && <span className="checkoutStep__action" title='Cambiar usuario' onClick={changeUserAccount}>Cambiar usuario</span>}
          </div>
        </div>
        {userCart.name !== "" && <div className="checkoutStep__description"><FiCheck /> {userCart.name}</div>}
      </div>
      <div className="checkoutStep__content">
        <div className="checkoutStep__keypad">
          <div className="checkoutStep__button">
            <button className={toggleSectionAccount ? "checkoutStepButton checkoutStepButton--selected" : "checkoutStepButton"} onClick={() => setToggleSectionAccount(true)}>
              <div className="checkoutStepButton__icon"><FiUserCheck /></div>
              <div className="checkoutStepButton__text">Ya soy cliente</div>
            </button>
          </div>
          <div className="checkoutStep__button">
            <button className={!toggleSectionAccount ? "checkoutStepButton checkoutStepButton--selected" : "checkoutStepButton"} onClick={() => setToggleSectionAccount(false)}>
              <div className="checkoutStepButton__icon"><FiUserPlus /></div>
              <div className="checkoutStepButton__text">Soy usuario nuevo</div>
            </button>
          </div>
        </div>
        <div className="checkoutStep__targets">
          {toggleSectionAccount &&
          <div className="checkoutStepTarget">
            <p className="checkoutStepTarget__text">Identifíquese con su e-mail y contraseña:</p>
            <div className="checkoutStepTarget__form">
              <Login />
            </div>
          </div>}
          {!toggleSectionAccount &&
          <div className="checkoutStepTarget">
            <p className="checkoutStepTarget__text">Puede continuar su compra como invitado: </p>
            <div className="checkoutStepTarget__form">
              <div className="checkoutStepTarget__formColumn">
                  <button type="submit" className="checkoutStepTarget__formSubmit" onClick={() => { setUserCart({ id: 0, name: "Invitado", email: "", phone: "", role: "", newUser: false, password: "", passwordConfirm: "", isValid: true });}}>Continuar como invitado</button>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </section>
  );
}