import React, {useContext} from 'react'
import TitleSection from '../../components/Texts/TitleSection'
import CartHead from '../../components/Shopping/CartHead'
import CartItem from '../../components/Shopping/CartItem'
import CartTotal from '../../components/Shopping/CartTotal'
import CartContext from './../../context/CartContext'
import { Link } from 'react-router-dom'

const BASE_URL_IMG = process.env.REACT_APP_BASE_URL_IMAGE;

export default function CartPage() {

  const {cart} = useContext(CartContext);

  return (
    <React.Fragment>
      <section className="section">
        <div className="wrapper">
          <TitleSection title="Carrito de compra" />
          {cart && cart.lines.map((l, key) => {
            return <CartItem key={key} image={BASE_URL_IMG + l.product_image} id={l.product_id} code={l.product_code} name={l.product_name} price={l.product_price} limit={l.product_limit} amount={l.amount_line} quantityFunction={true} />
          })}
          {
            (!cart || cart.lines.length === 0) &&
              <React.Fragment>
                <section className="section section_center">
                  {/* <div className="wrapper"> */}
                    <TitleSection title="" description={["Carrito vacío. Aún no se ha añadido ningún artículo."]} />
                    <Link to="/tienda" title="Ir a la tienda" className="cartTotal__button">Ir a la tienda</Link>
                  {/* </div> */}
                </section>
              </React.Fragment>
          }
          {
            cart && cart.lines.length > 0 && <CartTotal />
          }
        </div>
      </section>
    </React.Fragment>
  )
}