import {createContext, useState, useEffect} from 'react'
import ProductService from '../services/Product/ProductService';
import StoreService from './../services/Store/StoreService'

const StoreContext = createContext();

export const StoreProvider = ({children}) => {
  const [storeStatus, setStoreStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState("");
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getStoreStatus();
  }, []);

  useEffect(() => {
    //console.log(products);
  }, [products]);

  const getStoreStatus = async () => {
    try {
      const response = await StoreService.get();
      if (response.content.status === "Open" || response.content.status === "Not_Stock") {
        //console.log(response.content.products);
        setProducts(response.content.products);
      }
      setStoreStatus(response.content.status);
      setMessage("");
      setIsLoading(false);
    }
    catch (error) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setStoreStatus("Close");
      setIsLoading(false);
      setMessage(resMessage);
      setAlert("danger");
      console.error(error);
    }
  }

  const updateStoreStatus = (status) => {
    setIsLoading(true);
    try {
      StoreService.update({status: status});
      setStoreStatus(status);
      setMessage("");
      setIsLoading(false);
    }
    catch (error) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setStoreStatus("Close");
      setIsLoading(false);
      setMessage(resMessage);
      setAlert("danger");
      console.error(error);
    }
  }

  return (
    <StoreContext.Provider value={{ storeStatus, isLoading, updateStoreStatus, message, alert, products }}>
      {children}
    </StoreContext.Provider>
  );
}

export default StoreContext;

