import React, { useState, useContext, useEffect } from 'react'
import './CartItem.css'
import { AiOutlineMinus, AiOutlinePlus, AiOutlineClose } from "react-icons/ai"
import CartContext from './../../context/CartContext'

function CartItem(props) {

  const { removeItemCart, editAmountItemCart } = useContext(CartContext);
  const [amount, setAmount] = useState(props.amount);

  useEffect(() => {
    editAmountItemCart(props.code, amount)
  }, [amount]);

  const handleRemoveItemCart = () => {
    removeItemCart(props.id)
  }

  const handleMinusAmount = () => {
    if (amount < 1)
      return;

    let newAmount = amount - 1;
    setAmount(newAmount)

  }

  const handlePlusAmount = () => {
    if (amount >= props.limit)
      return;

    let newAmount = amount + 1;
    setAmount(newAmount);
  }

  return (
    <section className="cartItem">
      <div className="cartItem__columnImage">
        <a href="#product" title="Producto" className="cartItem__link">
          <img className="cartItem__image" src={props.image} alt="" />
        </a>
      </div>
      <div className="cartItem__column">
        <div className="cartItem__info">
          <div className="cartItem__text">
            <div className="cartItem__title">
              <a href="#product" className="cartItem__linkTitle">{props.name}</a>
            </div>
            <div className="cartItem__pice">
              <span>{props.price} € <span className="productDetail__iva">(IVA incluido)</span> {!props.quantityFunction && "x " + amount}</span>
            </div>
          </div>
          {props.quantityFunction && <div className="cartItem__quantity">
            <div className="cartItem__quantityWrapper">
              <a href="#minus" onClick={handleMinusAmount} className="cartItem__quantityDownUp"><AiOutlineMinus /></a>
              <input type="text" name="quantity" className="productDetail__quantityTotal" value={amount} />
              <a href="#plus" onClick={handlePlusAmount} className="cartItem__quantityDownUp"><AiOutlinePlus /></a>
            </div>
            <a href="#eliminar" onClick={handleRemoveItemCart} className="cartItem__remove">Eliminar</a>
          </div>}
        </div>
        <div className="cartItem__total">
          Total:
          <span>{Math.round(((amount * props.price) + Number.EPSILON) * 100) / 100} €</span>
        </div>
      </div>
    </section>
  )
}

export default CartItem