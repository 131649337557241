import React from 'react'
import TitleSection from '../../components/Texts/TitleSection'
import CardBanner from '../../components/Cards/CardBanner'
import { RiShoppingBag3Fill } from "react-icons/ri";
import { Link } from 'react-router-dom'
import CallToActionSection from '../../components/Cards/CallToActionSection'
import { RiSeedlingLine, RiRoadMapLine } from "react-icons/ri";
import { GiFarmer } from "react-icons/gi";
import { BsBoxSeam } from "react-icons/bs";
import { FaHome } from "react-icons/fa";

function NotFoundPage() {

  const callToActions = [
    { title: "Rey Raf", description: "Conoce como la tercera generación de una familia de agricultoras ha conseguido hacer del tomate Raf, el rey de los tomates.", label: "Empresa", textLink: "Conócenos", link: "/empresa", figure: <GiFarmer /> },
    { title: "Tomate Raf", description: "Historia, características, variedades, temporada y todo lo que necesitas saber sobre el tomate Raf.", label: "Nuestro Producto", textLink: "Descúbrelo", link: "/productos", figure: <RiSeedlingLine /> },
    { title: "Envases", description: "Descubre nuestra caja ecológica, más confortable y resistente, además de ser bonita y personalizada, es positiva para el medio ambiente.", label: "Packaging", textLink: "Innovamos", link: "/envases", figure: <BsBoxSeam /> }
  ]

  return (
    <React.Fragment>
      <section className="section">
        <div className="wrapper">
          <TitleSection title="Ups!! 404... Parece que la página que está buscando no existe" description={["Usted puede volver a la página principal o conocernos mejor visitando nuestras secciones"]} />
          <CardBanner figure={<FaHome />} text="Volver a la página principal." button={<React.Fragment><Link to="/" title="Volver a la home">Ir a inicio</Link></React.Fragment>} />
        </div>
      </section>
      <section className="section">
        <div className="wrapper">
          <CallToActionSection callToActions={callToActions} />
        </div>
      </section>
    </React.Fragment>
  )
}

export default NotFoundPage