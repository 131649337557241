import React, {useState} from 'react'
import './BackToTop.css'
import { RiArrowUpSLine } from "react-icons/ri";

function BackToTop() {

  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  window.addEventListener('scroll', toggleVisible)

  return (
    <button className="backToTop" title="Subir" style={{ display: visible ? 'flex' : 'none' }} onClick={scrollToTop}>
      <RiArrowUpSLine />
    </button>
  )
}

export default BackToTop