import React, {useContext} from 'react'
import CardProduct from './CardProduct'
import StoreContext from './../../context/StoreContext'

const BASE_URL_IMG = process.env.REACT_APP_BASE_URL_IMAGE;

function CardsList() {

  const { products } = useContext(StoreContext);

  return (
    <div className="cards">
      <div className="cards__inner">
        {products && products.length > 0 && products.map((p, key) => {
          return <CardProduct key={key} badge={p.blocked} primaryImg={BASE_URL_IMG + p.mainImage.image}
            secondaryImg={p.images.length > 0 ? BASE_URL_IMG + p.images[0].image : BASE_URL_IMG + p.mainImage.image}
            title={p.name} price={p.price} slug={p.slug} />
        })}
      </div>
    </div>
  )
}

export default CardsList