import React, { useEffect } from 'react'
import Headline from '../../components/Texts/Headline'
import Feature from '../../components/Texts/Feature'
import { FiMapPin, FiMap, FiPhoneCall, FiMail, FiChevronRight } from "react-icons/fi";
import InfoSection from '../../components/Texts/InfoSection';
import TitleSection from '../../components/Texts/TitleSection';
import CallToActionSection from '../../components/Cards/CallToActionSection';
import { RiSeedlingLine, RiRoadMapLine } from "react-icons/ri";
import { GiFarmer } from "react-icons/gi";
import HeroTextImage from '../../components/Galleries/HeroTextImage';
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Social from '../../components/Texts/Social'

function ContactPage() {

  useEffect(() => {
    ScrollTrigger.refresh();
  }, [ScrollTrigger.getAll()]);

  const callToActions = [
    { title: "Rey Raf", description: "Conoce como la tercera generación de una familia de agricultoras ha conseguido hacer del tomate Raf, el rey de los tomates.", label: "Empresa", textLink: "Conócenos", link: "/empresa", figure: <GiFarmer /> },
    { title: "Tomate Raf", description: "Historia, características, variedades, temporada y todo lo que necesitas saber sobre el tomate Raf.", label: "Nuestro Producto", textLink: "Descúbrelo", link: "/productos", figure: <RiSeedlingLine /> },
    { title: "Entorno", description: "Conoce nuestro entorno y descubre porque se dan las condiciones ideales para la crianza de este excepcional producto.", label: "Parque Natural y ruta etnográfica", textLink: "Explora", link: "/entorno", figure: <RiRoadMapLine /> }
  ]

  const infoSectionFeatures = [
    {title: <a href="http://goo.gl/maps/N2Af5" target="_blank" title="Ir a la ubicación de la finca" rel="noreferrer">Ubicación <FiChevronRight /></a>, description: "Situado en El Hornillo de los Martínez (Níjar), un lugar excepcional para el cultivo del tomate Raf.", figure: <FiMapPin /> },
    {title: <a href="./images/contact/mapa-ruta-etnografica.jpg" target="_blank" title="Visualizar mapa ruta etnográfica">Ruta Etnográfica <FiChevronRight /></a>, description: "Incluido en la Ruta Etnográfica del Ayuntamiento de Níjar cómo punto de interés turístico y cómo cuna de Europa del tomate Raf.", figure: <FiMap /> }
  ]

  return (
    <React.Fragment>
      <Headline title="Contacto" subtitle="Hablemos" />
      <HeroTextImage title="¿Tiene alguna duda?" titleStrong="Se la resolvemos" subtitle="No se quede sin disfrutar este manjar" imgSrc="./images/contact/hero-contact.jpg" imgAlt="Plato de tomate Raf cortado"/>
      <section className="section section--empty">
        <div className="wrapper">
          <div className="section__content">
            <Feature icon={<FiMap />} title="Ubicación" description={<span>Visítenos en El Hornillo de los Martínez<br />Níjar (04116)<br />Almería - España</span>} />
            <Feature
              icon={<FiPhoneCall />}
              title="Teléfono"
              description={
                <React.Fragment>
                  Llámenos y le resolveremos cualquier duda<br/>
                  <a className="link" href="tel:950 600 354" title="Llame al 950 600 354 y le resolveremos cualquier duda">950 600 354</a> - <a className="link" href="tel:850 995 052" title="Llame al 850 995 052 y le resolveremos cualquier duda">850 995 052</a>
                </React.Fragment>
              } />
            <Feature icon={<FiMail />} title="Email" description={
              <React.Fragment>
                Escríbenos a <a className="link" href="mailto:info@reyraf.es" title="Escríbenos y le resolveremos cualquier duda">info@reyraf.es</a> y le resolveremos cualquier duda.
              </React.Fragment>
            } />
          </div>

        </div>
      </section>
      <section className="section">
        <div className="wrapper">
          <InfoSection
            subtitle="Visítenos"
            title="Finca Rey Raf"
            description={["En la Finca Rey Raf, lugar que se ocupa del cultivo de este tomate de gran calidad, recomendamos hacer la visita guiada.", "Conoceremos los distintos rincones de la finca y recibiremos nociones básicas sobre su origen, cultivo, recolección, venta, así cómo las peculiaridades de este tomate único.","La visita incluye una degustación y la posibilidad de adquirir productos en distintos formatos."]}
            infoSectionFeatures={infoSectionFeatures}
            image="./images/contact/finca.jpeg"
            imageAlt="Instalaciones Rey Raf"/>
        </div>
      </section>
      <section className="section">
        <div className="wrapper">
          <Social />
        </div>
      </section>
      <section className="section">
        <div className="wrapper">
          <TitleSection title="¿Quieres conocernos mejor?" description={["Explora y descubre porque en Rey Raf cultivamos el mejor tomate Raf."]} />
          <CallToActionSection callToActions={callToActions} />
        </div>
      </section>
    </React.Fragment>
  )
}

export default ContactPage