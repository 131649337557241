import React, { useRef, useContext } from 'react'
import './CardProduct.css'
import { BsCartPlusFill } from "react-icons/bs";
import { TiStarFullOutline } from "react-icons/ti";
import {Link} from 'react-router-dom'
import StoreContext from './../../context/StoreContext'

function CardProduct(props) {

  const { storeStatus } = useContext(StoreContext);

  const ProductToggleOnHover = ({primaryImg, secondaryImg, altImg}) => {
    const imageRef = useRef(null);
    return (
      <Link
        onMouseOver={() => {
          imageRef.current.src = secondaryImg
        }}
        onMouseOut={() => {
          imageRef.current.src = primaryImg
        }}
        to={"/tienda/" + props.slug} className="card__link" title="Ver detalle">
        <span className="card__dark"></span>
        <div className="card__swapImage">
          <img
            src={primaryImg}
            className="card__picture"
            alt={altImg}
            ref={imageRef}
          />
        </div>
      </Link>
    )
  }

  return (
    <article className="card">
      <div className="card__inner">
        <div className="card__image">
          <div className="card__badge">
            {/* <span className="cardBadge__featured"><TiStarFullOutline /></span>  */}
            {(storeStatus === 'Not_Stock') ? <span className="cardBadge__blocked">Agotado</span> :
            <span className={props.badge == 1 ? "cardBadge__blocked" : "cardBadge__new"}>{props.badge == 1 ? "Agotado" : "Nuevo"}</span>}
          </div>
          {/* <div className="card__cart" title="Añadir al carrito"><BsCartPlusFill /></div> */}
          <ProductToggleOnHover
            primaryImg={props.primaryImg}
            secondaryImg={props.secondaryImg}
            altImg={props.title}
          />
        </div>
        <div className="card__info">
          <a href="#detail" className="card__link">
            <h6 className="card__title">{props.title}</h6>
            <span className="card__price">{props.price} €</span>
          </a>
        </div>
      </div>
    </article>
  )
}

export default CardProduct