import React, { useState, useContext } from 'react'
import './../../components/Shopping/CartSummary.css'
import CartItem from '../../components/Shopping/CartItem'
import { FiShoppingBag, FiChevronRight} from "react-icons/fi";
import CartContext from './../../context/CartContext'

const BASE_URL_IMG = process.env.REACT_APP_BASE_URL_IMAGE;

export default function CartSummary() {
  const { cart, totalPrice } = useContext(CartContext);
  const [isActive, setIsActive] = useState(false)

  const handleSummaryDetail = () => {
    setIsActive(!isActive)
  }

  return (
    <React.Fragment>
      <div className="checkout__summaryMobile" onClick={handleSummaryDetail} title="Ver resumen del pedido">
        <span className="checkout__summaryMobilIcon"><FiShoppingBag /></span>
        <span className="checkout__summaryMobileTitle">Resumen pedido</span>
        <span className="checkout__summaryMobilePrice">{totalPrice} €</span>
        <span className="checkout__summaryMobileShow"><FiChevronRight /></span>
      </div>
      <div className={isActive ? "checkout__summaryDetail checkout__summaryDetail--active" : "checkout__summaryDetail"}>
        <div className="checkout__summaryDetailInner">
          {cart && cart.lines.map((l, key) => {
            return <CartItem key={key} image={BASE_URL_IMG + l.product_image} id={l.product_id} name={l.product_name} price={l.product_price} amount={l.amount_line} quantityFunction={false} />
          })}
        </div>
      </div>
    </React.Fragment>
  );
}